import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter, OnChanges, AfterContentInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '../../../../../../services/translate.service';
import { ContactInt} from '../../../../../../models/data.model';
import { IhmOptions } from '../../../../models/wks-param.model';
import { ModalCommonComponent } from '../../../../../../job/common/components/modal-common/modal-common.component';

@Component({
  selector: 'mdi-contacts-card',
  templateUrl: './contacts-card.component.html',
  styleUrls: ['./contacts-card.component.css']
})
export class ContactsCardComponent implements OnInit, OnChanges, AfterContentInit {

  @Input() dataInput: any;
  @Input() config: any;
  @Input() nameParent: any;
  @Output() dataOut = new EventEmitter<any[]>();
  @Input() optionItem: string;

  @Input() ihmOptions: IhmOptions;

  private readonly onDestroy = new Subject<void>();
  isLoaded: boolean;
  listContacts: ContactInt[];
  listContactsInput: ContactInt[];
  contactTest: ContactInt;
  configContacts: any;

 
  dataCur: any;
  isDataIn: boolean;
  nRow: number;
  isReadOnly: boolean;


  constructor(private _dialog: MatDialog, private _translate: TranslateService) { }

  ngOnInit() {
    this.initData();
    this.isLoaded = true;
  }

  ngOnChanges(changes: SimpleChanges) {

    const listKey = Object.keys(changes);
    let failedArg = false;
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {

           // actionType
          case 'config': {
            this.config = changes['config'].currentValue;
 
            break;
          } // end case
          // dataInput
          case 'dataInput': {
            this.dataInput = changes['dataInput'].currentValue;
            if (this.dataInput !== undefined && this.dataInput[this.dataInput.length - 1].statut === 'new') {
              failedArg = true;
            }
            break;
          } // end case
          case 'optionItem': {
            this.optionItem = changes['optionItem'].currentValue;

            break;
            break;
          } // end case
          case 'ihmOptions': {
            this.ihmOptions = changes['ihmOptions'].currentValue;

            break;
          } // end case
          case 'nameParent': {
            this.nameParent = changes['nameParent'].currentValue;

            break;
          } // end case
        }  // end switch
      } // end if
    }// end loop
    if (!failedArg) {
      this.initData();
    }
    this.isLoaded = true;
  }
  ngAfterContentInit() {
    if ((this.listContacts === undefined) || (this.listContacts === null) || (this.listContacts.length === 0)) {
      this.addContact();
    }
  }
  initData() {

    this.configContacts = Object.assign('', this.config);
    this.configContacts.address = false;
    this.configContacts.telephone = true;
    this.configContacts.contact = true;
    this.configContacts.email = true;
    this.configContacts.isReadOnly = this.ihmOptions.readOnly;
    this.isReadOnly = this.ihmOptions.readOnly;

    const dataOrig = JSON.stringify(this.dataInput);
    this.listContactsInput = (dataOrig !== undefined && dataOrig !== '') ? JSON.parse(dataOrig) : undefined;
    if (this.listContactsInput === undefined) {
      this.listContacts = [];
      this.listContactsInput = [];
      this.isDataIn = false;
    } else {
      this.listContacts = JSON.parse(dataOrig);
    }
  }
  getJsonContact(contactCur: ContactInt, nRow: number) {
    this.nRow = nRow;
    return contactCur;
   // return JSON.stringify(contactCur);
  }
  addContact() {
    if (this.listContacts === undefined) {
      this.listContacts = [];
      this.listContactsInput = [];
    }
    const contact = {
      identity: {
        cntCivility: '',
        cntFirstname: '',
        cntLastname: '',
      },
      statut: 'new',
      selectApps: '',
      phones: [],
      emails: [],
      fonctionDept: '',
      selectDept: '',

    }; 
    this.listContacts.push(contact);
  }
  removeContact (iRow: number) {
    if (this.listContacts.length > 0) {
      if (this.listContacts[iRow].id !== undefined) {
        let titleBox: string;
        let messageBox: string;
        if (this.optionItem === 'wks-subcontractors') {
          titleBox = 'deleting contact subcontractor';
        } else if (this.optionItem === 'wks-suppliers') {
          titleBox = 'deleting contact supplier';
        } else if (this.optionItem === 'wks-anchorages') {
          titleBox = 'deleting contact anchorage';
        } else {
          titleBox = 'deleting contact customer';
        }
        titleBox = this._translate.getTranslate(titleBox);
        messageBox = this.listContacts[iRow].identity.cntFirstname + ' ' + this.listContacts[iRow].identity.cntLastname;
    
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'removeCnt', iRow );
      }
    }
  }
  removeContactOK (iRow: number) {
    if (this.listContacts.length > 0) {
      if (this.listContacts[iRow].id !== undefined) {
        for (const contactInput of this.listContactsInput) {
          if (contactInput.id === this.listContacts[iRow].id) {
            contactInput.statut = 'remove';
            break;
          }
        }
      }
      this.dataOut.emit(this.listContactsInput);
      this.listContacts.splice(iRow, 1);
      if (this.listContacts.length === 0) {
        this.addContact();
      }
    }
  }
  displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string, rowCur: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (( data === 'okAction') && (_actionCur === 'removeCnt')) {
          this.removeContactOK(rowCur);
        }
      });

  }
  dataOutContact(jsonData: any, iRow: number) {
    // const jsonData = JSON.parse($event);
    if (jsonData.formAdressValid) {
      this.listContacts[iRow].identity = jsonData.identity;
      this.listContacts[iRow].phones = jsonData.phones;
      this.listContacts[iRow].emails = jsonData.emails;
      this.listContacts[iRow].selectApps = jsonData.selectApps;

      if ((this.listContacts[iRow].statut === 'new') && ( this.listContactsInput[iRow] === undefined)) {
          this.listContactsInput.push(this.listContacts[iRow]);
      }
      if (this.listContacts[iRow].statut === 'new') {
        this.listContactsInput[iRow] = this.listContacts[iRow];
      } else {
        let idxItem = 0;
        for (let contactInput of this.listContactsInput) {
          let exists = false;
          if (contactInput.statut === 'exists') {
            for (const contact of this.listContacts) {
              if (contact.id === undefined) {
                continue;
              }
              if (contactInput.id === contact.id) {
                exists = true;
                contactInput = contact;
                this.listContactsInput[idxItem] = contact;
                break;
              }
              
            } // end loop listContacts
            if ((!exists) && (contactInput.statut !== 'new')) {
              contactInput.statut = 'remove';
            }
            
          } // end test exists
          idxItem++;
        } // end loop listContactsInput
      }
      this.dataOut.emit(this.listContactsInput);
    }
  }
}
