import { Component, OnInit, Input, SimpleChanges, TemplateRef, ViewChild, OnChanges, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatTabChangeEvent } from '@angular/material';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { AccountingBalance, WksBusinessModel, WksContactsModel } from '../../../../models/wks-business.model';

import { WksCentralService } from '../../../../services/wks-central.service';
import { EventBroadcastService } from '../../../../../common/services/event-broadcast.service';
import { ModalCommonComponent } from '../../../../../../job/common/components/modal-common/modal-common.component';
import { GlobalEventsManagerService } from '../../../../../../job/common/services/global-events-manager.service';
import { RessourcesService } from '../../../../../../job/common/services//ressources.service';

import { WksDocsModel,  } from '../../../../models/wks-equipment.model';
import { IhmOptions } from '../../../../models/wks-param.model';
import { ContactInt, PhoneInput, EmailInput, Identity } from '../../../../../../models/data.model';
import { WorkInputEvent} from '../../../../models/wks-works.model';



@Component({
  selector: 'mdi-bns-card-sheets',
  templateUrl: './bns-card-sheets.component.html',
  styleUrls: ['./bns-card-sheets.component.css']
})
export class BnsCardSheetsComponent implements OnInit, OnChanges {

  @Input() statutCall: any;
  @Input() wksBnsToUpdate: WksBusinessModel;
  @Input() optionItem: string;
  @Input() originCall: string;
  @Input() ihmOptions: IhmOptions;
  @Input() iBsnRow: number;

  @Output() dataOut = new EventEmitter<WorkInputEvent>();

  private readonly onDestroy = new Subject<void>();

  @ViewChild('pdfModalViewer', {read: TemplateRef, static: false}) pdfModalViewer: TemplateRef<any>;
  @ViewChild('imgModalViewer', {read: TemplateRef, static: false}) imgModalViewer: TemplateRef<any>;
  @ViewChild('docModal', {read: TemplateRef, static: false}) docModal: TemplateRef<any>;
  
  isCompany: boolean;
  /* address */
  addressCur: any;
  dataContacts: ContactInt[];
  configAddress: any;
  doInputAddress: boolean;
  cacheDatatemp: any;
  contactsList: WksContactsModel[];
  wksBnsCache: WksBusinessModel;

  modalRef: BsModalRef;
  configModal = {
    class: 'modal-dialog-centered modal-lg modal-dialog-std modal-body-std',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: false,
    
  };
  pseudoDoc: WksDocsModel;
  valueWidth = false;
  modalText: any[];
  dataList: WksDocsModel[];
  administrativeList: WksDocsModel[];
  contractsList: WksDocsModel[];
  guaranteesList: WksDocsModel[];
  quotationsList: WksDocsModel[];
  invoicesList: WksDocsModel[];
  otherDocsList: WksDocsModel[];
 
  nameParent: string;
  matTabCur: number;
  okToRegister: boolean; 
  accountingBalance: AccountingBalance;

  constructor(private _userService: UserService,
              private _wksCentralService: WksCentralService,
              private router: Router,
              private eventBroadcastService: EventBroadcastService,
              private _globalEventsManagerService: GlobalEventsManagerService,
              private _dialog: MatDialog,
              private _ressources: RessourcesService,
              private modalService: BsModalService,
              private _translate: TranslateService) { 
                this.matTabCur = -1;
              }

  ngOnInit() {   }
  ngOnChanges(changes: SimpleChanges) {
     
    const listKey = Object.keys(changes);

    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          } // end case

          case 'wksBnsToUpdate': {
            this.wksBnsToUpdate = changes['wksBnsToUpdate'].currentValue;
            if (this.wksBnsToUpdate !== undefined && this.wksBnsToUpdate.accountingBalance !== null && this.wksBnsToUpdate.accountingBalance !== undefined) {
              this.accountingBalance = JSON.parse(JSON.stringify(this.wksBnsToUpdate.accountingBalance));
            }
            break;
          } // end case
          case 'optionItem': {
            this.optionItem = changes['optionItem'].currentValue;
            break;
          } // end case
          case 'originCall': {
            this.originCall = changes['originCall'].currentValue;
            break;
          } // end case
          // ihmOptions
          case 'ihmOptions': {
            this.ihmOptions = changes['ihmOptions'].currentValue;
            break;
          } // end case
          // iBsnRow
          case 'iBsnRow': {
            this.iBsnRow = changes['iBsnRow'].currentValue;
            break;
          } // end case
        }  // end switch
      } // end if
    }// end loop
    this.initData();
  }
  initData() {
    if (this.wksBnsToUpdate !== undefined) {
      this.wksBnsCache = JSON.parse(JSON.stringify(this.wksBnsToUpdate));

    } else {
      this.wksBnsCache = undefined;
    }
    if (this._userService.getUserLogged() === undefined ) { return; }
    this.nameParent = undefined;
    this.isCompany = ( (this.wksBnsCache !== undefined)  &&  this.wksBnsCache.bsnType === 'company');
    this.okToRegister = false;
    if ((this.wksBnsCache !== undefined) &&
    ((this.wksBnsCache.id !== undefined) && (this.wksBnsCache.id !== null)) && 
    (this.wksBnsCache.bsnName !== undefined)) {
      this.nameParent = this.wksBnsCache.bsnName;
      this.getWksContactsList()          
      .then ((data: any) => {
        this.wksBnsCache.dataContacts = data;
        const workInputEvent: WorkInputEvent = {
          objectCur: this.optionItem,
          action: 'contactsList', 
          customer: this.wksBnsCache,
          iRow : this.iBsnRow
        };
        this.dataOut.emit(workInputEvent);
      })
      .catch ((error: any) => {

      });
      this.getDocumentsList();

    }
    
    this.eventBroadcastService.onChangeStatut
    .pipe(takeUntil(this.onDestroy))
    .subscribe((event: any) => {
      // console.log('event change ');
      if (event === undefined) {
        return;
      }
      if (event === null) {
        return;
      }
      if (event.eventType !== undefined) {
        if (event.eventType === 'contacts') {
          this.isCompany = false;
          if ((event.eventData === 'company') || (event.eventData === 'administration')) {
            this.isCompany = true;
          }
          if (this.configAddress !== undefined) {
            this.configAddress.professionnal = this.isCompany;
            // this.configAddress.identifier = ( this.isCompany ? 'professional' : 'individual');
          }
        }
        return;
      }

    });
    this._globalEventsManagerService.synchroEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {
      if (event === 'closeDocModal') {
        this.closeModal();
        this.getDocumentsList();
      } else if (event === 'cancelModal') {
        console.log('fermeture');
      } else if (event) {
        const typeEvent = typeof event;
        // const eventString = JSON.stringify(event).toString();
        const eventString = String(event);
        // console.log(eventString);
      }
      
      // this.router.navigate(['/jobHome']);
    });
    this.configAddress = {
      langCur: this._translate.getCurrentLang(),
      countryDefault: this._userService.getUserLogged().entitycountry,
      address: true,
      telephone: false,
      email: false,
      contact: false,
      professionnal: this.isCompany,
      /*identity: 'pro',
      fieldsId: ['proID']*/
      identity: undefined,
      fieldsId: []
    };
    this.doInputAddress = true;
    this.matTabCur = 0;
  }

  dataOutContacts(event: any) {
    // this.dataContacts = event;
    this.dataContacts = event;
    this.fillModelContacts();
    if (JSON.stringify(this.wksBnsCache.dataContacts) !== JSON.stringify(this.contactsList)) {
      this.wksBnsCache.dataContacts = this.contactsList;
      this.okToRegister = true;
      const workInputEvent: WorkInputEvent = {
        objectCur: this.optionItem,
        action: this.statutCall, 
        customer: this.wksBnsCache,
        iRow : this.iBsnRow
      };
      this.dataOut.emit(workInputEvent);
    }
  }
  dataOutBsnCard(event: WorkInputEvent) {
    if (event.action === 'INVALID') {
      this.okToRegister = false;
      return;
    }
    if ((this.contactsList === undefined) || (this.contactsList.length === 0)
      && (this.statutCall === 'createBsn')) {
        if ((event.customer.bsnType === 'person') 
        &&  (event.customer.bsnAddress)) {
          this.createContactDefault(event.customer);
        }
        /* 
        if ((event.customer.bsnType === 'company') || (event.customer.bsnType === 'administration')) {
          this.createContactEmpty();
        }
        */
    }
    event.customer.dataContacts = this.contactsList;
    event.iRow = this.iBsnRow;

    if (JSON.stringify(this.wksBnsCache) !== JSON.stringify(event.customer)) {
      this.wksBnsCache = event.customer;
      if (this.wksBnsCache.bsnAddress) {
        const dataAddress = this.wksBnsCache.bsnAddress;
        if ((this.wksBnsCache.bsnType === 'company') || (this.wksBnsCache.bsnType === 'administration')) {
          this.nameParent = dataAddress.corporateName;
        } else {
          this.nameParent = dataAddress.identity.cntLastname + ' ' + dataAddress.identity.cntFirstname;
        }
      }
      if (this.dataContacts !== undefined) {
        this.okToRegister = true;
      }

      this.dataOut.emit(event);
    }
  }
  doCancel() {
  
    if (this.statutCall) {
      // this.wksBnsCache.bsnAccounting = this.wksBnsCache.bsnAccounting;
      this._wksCentralService.onRegisterValid.emit('cancelRegister');
    } else {
      this.router.navigate(['/']);
    }
  }
  selectedTabChange(matTabSelected: MatTabChangeEvent) {

    if (this.matTabCur === 0 ) {
      this.wksBnsCache = this.fillModel();
     
      if (((this.contactsList === undefined) || (this.contactsList.length === 0)) 
      && (this.statutCall === 'createBsn')) {
        if (this.wksBnsCache.bsnType === 'person') {
          this.createContactDefault(this.wksBnsCache);
          this.wksBnsCache.dataContacts = this.contactsList;
        }
        if ((this.wksBnsCache.bsnType === 'company') || (this.wksBnsCache.bsnType === 'administration')) {
          this.createContactEmpty();
          this.wksBnsCache.dataContacts = this.contactsList;
        }
      }
      if (JSON.stringify(this.wksBnsToUpdate) !== JSON.stringify(this.wksBnsCache)) {
        // this.wksBnsToUpdate = this.wksBnsCache;
        this.wksBnsToUpdate = JSON.parse(JSON.stringify(this.wksBnsCache));
      }
    }
    this.matTabCur = matTabSelected.index;
  }
  createContactEmpty() {
    this.dataContacts = [];
    this.contactsList = [];
    const contact = {
      identity: {
        cntCivility: '',
        cntFirstname: '',
        cntLastname: '',
      },
      statut: 'new',
      selectApps: '',
      phones: [],
      emails: [],
      fonctionDept: '',
      selectDept: '',
    }; 
    this.dataContacts.push(contact);
    const localCnt: WksContactsModel = {
      id: undefined,
      stdEntity: this._userService.getUserLogged().entity,
      cntAppli: this.optionItem,
      cntAppliLnk: ((this.wksBnsCache && this.wksBnsCache.id) ? this.wksBnsCache.id : undefined),
      cntSpeciality: '',
      cntFunction: '',
      cntChannels: '',
      cntMails: '',
      cntPhones: '',
      cntCivility: '',
      cntFirstname: '',
      cntLastname: '',
      cntStatut: 'new',
    };
    this.contactsList.push(localCnt);
  }
  createContactDefault(wksBusinessCur: WksBusinessModel) {

    this.dataContacts = [];
    this.contactsList = [];
    // const dataAddress = JSON.parse(wksBusinessCur.bsnAddress);
    const dataAddress = wksBusinessCur.bsnAddress;
    const identitCur: Identity = {
      cntCivility: dataAddress.identity.cntCivility,
      cntFirstname:  dataAddress.identity.cntFirstname,
      cntLastname: dataAddress.identity.cntLastname
    };
    const contactPerson = {
      statut: 'new',
      selectApps: dataAddress.selectApps,
      identity: identitCur,
      phones: (dataAddress.phones ? dataAddress.phones as PhoneInput[] : undefined),
      emails: (dataAddress.emails ? dataAddress.emails as EmailInput[] : undefined),
      fonctionDept: '',
      selectDept: '',
    };

    this.dataContacts.push(contactPerson);
    const localCnt: WksContactsModel = {
      id: undefined,
      stdEntity: this._userService.getUserLogged().entity,
      cntAppli: this.optionItem,
      cntAppliLnk: ((this.wksBnsCache && this.wksBnsCache.id) ? this.wksBnsCache.id : undefined),
      cntSpeciality: contactPerson.selectDept,
      cntFunction: contactPerson.fonctionDept,
      cntChannels: contactPerson.selectApps,
      cntMails: JSON.stringify(contactPerson.emails),
      cntPhones: JSON.stringify(contactPerson.phones),
      cntCivility: contactPerson.identity.cntCivility,
      cntFirstname: contactPerson.identity.cntFirstname,
      cntLastname: contactPerson.identity.cntLastname,
      cntStatut: contactPerson.statut,
    };
    this.contactsList.push(localCnt);
  }
  getWksContactsList() {
    return new Promise((resolve, reject) => {
      this._wksCentralService.getContactsList(this._userService.getUserLogged().entity, this.optionItem, this.wksBnsCache.id )
     .pipe(takeUntil(this.onDestroy))
     .subscribe(
        data => {
          this.contactsList = data.body as WksContactsModel[];
          this.cacheDatatemp = [...data.body];
          this.dataContacts = [];
          if (this.contactsList.length > 0) {
            for (const cntCur of this.contactsList) {
              this.dataContacts.push({
                id: cntCur.id,
                selectDept: cntCur.cntSpeciality,
                fonctionDept: cntCur.cntFunction,
                selectApps: cntCur.cntChannels,
                emails: JSON.parse(cntCur.cntMails),
                phones: JSON.parse(cntCur.cntPhones),
                identity: {
                  cntCivility: cntCur.cntCivility,
                  cntFirstname: cntCur.cntFirstname,
                  cntLastname: cntCur.cntLastname,
                },
                statut: 'exists',
              });
            }
          }
          resolve(this.contactsList) ;
        }, err => {
          // console.log(err);
          reject(err.status);
        }
      );
    });
  } 
 
  getDocumentsList() {
    this._wksCentralService.getWksDocListOwner(this._userService.getUserLogged().entity,
                                                this.wksBnsCache.id)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        this.dispatchDocs(data.body);
      },
      err => {
        this.administrativeList = [];
        this.contractsList = [];
        this.guaranteesList = [];
        this.invoicesList = [] ;
        this.quotationsList = [];
        this.otherDocsList = [];
        if (err.status === 404) {

        } else {
          console.log(err);
        }
      }
    );
  }
  dispatchDocs(_docList: WksDocsModel[]) {
    this.administrativeList = [];
    this.contractsList = [];
    this.guaranteesList = [];
    this.invoicesList = [] ;
    this.quotationsList = [];
    this.otherDocsList = [];
    _docList.sort((obj1, obj2) => {
      return obj1.docName > obj2.docName ? 1 : -1;
    });
    for (const docCur of _docList) {
        switch (docCur.docTags) {
          case 'administrative': {
            this.administrativeList.push(docCur);
            break;
          }
          case 'contracts': {
            this.contractsList.push(docCur);
            break;
          }
          case 'guarantees': {
            this.guaranteesList.push(docCur);
            break;
          }
          case 'quotations': {
            this.quotationsList.push(docCur);
            break;
          }
          case 'invoices': {
            this.invoicesList.push(docCur);
            break;
          }
          case 'otherDocs': {
            this.otherDocsList.push(docCur);
            break;
        }
      }
    }
  }

  register() {

    const currentCard = this.fillModel();
    // this._wksCentralService.onRegisterValid.emit('closeRegister');
    this._wksCentralService.saveWksBusiness(currentCard, this.statutCall)
    .subscribe(
      (data) => {
        this.okToRegister = false;
        if (this.statutCall) {
          this._wksCentralService.onRegisterValid.emit('closeRegister');
        } else {
          this.router.navigate(['/']);
        }
      },
      (error) => {
        this.okToRegister = false;
        this.errorMessage(error.status);
      }
    );
  }
  errorMessage(errorNumber: number) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let messageBox = 'error recording';
    if (this.optionItem === 'wks-subcontractors') {
      titleBox = 'recording subcontractors';

    } else if (this.optionItem === 'wks-suppliers') {
      titleBox = 'recording suppliers';
 
    } else {
      titleBox = 'recording customers';
    }
    if (errorNumber === 409) {

      let bsnExists = this._translate.getTranslate('customer exists');
      if (this.optionItem === 'wks-suppliers') {
        bsnExists = this._translate.getTranslate('supplier exists');
      }
      if (this.optionItem === 'wks-subcontractors') {
        bsnExists = this._translate.getTranslate('subcontractor exists');
      }
      messageBox = bsnExists;
    }
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate(titleBox),
      typeDialog: 'alertWks',
      panelClass: 'stdTheme',
      contentMessage: this._translate.getTranslate(messageBox),
      data1: '',
      data2: '',
      messageType: 'ERROR'

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);
 
    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        console.log('Dialog output:', data);
      });

  }
  fillModelContacts() {
    this.contactsList = undefined;
    if (this.dataContacts) {
      this.contactsList = [];
      for (const cntCur of this.dataContacts) {
        const localCnt: WksContactsModel = {
          id: cntCur.id,
          stdEntity: this._userService.getUserLogged().entity,
          cntAppli: this.optionItem,
          cntAppliLnk: ((this.wksBnsCache && this.wksBnsCache.id) ? this.wksBnsCache.id : undefined),
          cntSpeciality: cntCur.selectDept,
          cntFunction: cntCur.fonctionDept,
          cntChannels: cntCur.selectApps,
          cntMails: JSON.stringify(cntCur.emails),
          cntPhones: JSON.stringify(cntCur.phones),
          cntCivility: cntCur.identity.cntCivility,
          cntFirstname: cntCur.identity.cntFirstname,
          cntLastname: cntCur.identity.cntLastname,
          cntStatut: cntCur.statut,
        };
        this.contactsList.push(localCnt);
      }
    }
  }
  fillModel(): WksBusinessModel {
    const wksBsnCard: WksBusinessModel = {};
    this.fillModelContacts();
    wksBsnCard.id = ((this.wksBnsCache && this.wksBnsCache.id) ? this.wksBnsCache.id : undefined);
    wksBsnCard.stdEntity = this.wksBnsCache.stdEntity;
    wksBsnCard.bsnCountry = this.wksBnsCache.bsnCountry;   
    wksBsnCard.bsnNature = this.wksBnsCache.bsnNature;
    wksBsnCard.bsnParent = this.wksBnsCache.bsnParent;
    wksBsnCard.bsnType = this.wksBnsCache.bsnType;
    wksBsnCard.bsnTax = (this.wksBnsCache.bsnTax === null ? 'unknow' : this.wksBnsCache.bsnTax);     
    if (wksBsnCard.bsnTax === undefined) {
      wksBsnCard.bsnTax = 'unknow';
    }
    wksBsnCard.stdCreation = ((this.wksBnsCache && this.wksBnsCache.stdCreation) ? this.wksBnsCache.stdCreation : undefined);

    let dataAddress = this.wksBnsCache.bsnAddress ;
    if (typeof dataAddress === 'string') {
      dataAddress = JSON.parse(this.wksBnsCache.bsnAddress);
    }
    if ((wksBsnCard.bsnType === 'company') || (wksBsnCard.bsnType === 'administration')) {

      wksBsnCard.bsnName = dataAddress.corporateName;
      wksBsnCard.bsnCompany = JSON.stringify(dataAddress);
    } else {
      wksBsnCard.bsnName = dataAddress.identity.cntLastname + ' ' + dataAddress.identity.cntFirstname;
      wksBsnCard.bsnPerson = JSON.stringify(dataAddress);
    }
    if ((this.wksBnsCache.bsnAccounting === undefined) || (this.wksBnsCache.bsnAccounting === null)) {
      wksBsnCard.bsnAccounting  = {
        bsnCashpay: false,
        bsnAccount: '',
        scaleName: '',
        bsnDiscount: 0.0,
        bsnDelayDays: 0,
        bsnDefferedPaiement: '',
        customerOutstanding: 0 };
    } else {
      let dataAccounting = this.wksBnsCache.bsnAccounting ;
      if (typeof dataAccounting === 'string') {
        dataAccounting = JSON.parse(this.wksBnsCache.bsnAccounting);
      }
      wksBsnCard.bsnAccounting = JSON.stringify(dataAccounting);
    }
    
    wksBsnCard.bsnAddress = JSON.stringify(dataAddress);
    wksBsnCard.bsnZipcode = dataAddress.address.zipcode;
    wksBsnCard.bsnCity = dataAddress.address.city;
    wksBsnCard.bsnState = dataAddress.address.state;
    if ((this.contactsList !== undefined) && (this.contactsList !== null)) {
      // wksBsnCard.dataContacts = '{ "contacts": ' + JSON.stringify(this.dataContacts) + '}';
      wksBsnCard.dataContacts = this.contactsList;
    }
    
    // console.log(wksBsnCard);

    // console.log(JSON.stringify(wksBsnCard));
    return wksBsnCard;
  }
  
  openDoc(docCur: WksDocsModel) {
    const fileExtension = docCur.docFilename.split('.').pop();
    if ( fileExtension === 'pdf') {
      this.openPdf(docCur);
    } else if ((fileExtension === 'jpeg') || 
                (fileExtension === 'jpg') || 
                (fileExtension === 'png')  ) {
      this.openImg(docCur);
    } else {
      this.saveDoc(docCur, fileExtension);
    }
  }
  openPdf(docCur: WksDocsModel) {
    this.openModal(this.pdfModalViewer, docCur);    
  }
  openImg(docCur: WksDocsModel) {
    this.openModal(this.imgModalViewer, docCur);    
  }
  openModal(template: TemplateRef<any>, docCur: WksDocsModel) {
    this.dataList = [];
    this.dataList.push(docCur);
    this.modalText = [docCur.docName];
    this.modalRef = this.modalService.show(template, this.configModal);
  }
  closeModal() {

    this.modalRef.hide();
  }
  saveDoc(row: WksDocsModel, fileExtension: string) {
    const path = row.docPath + '/' + row.docFilename;

    const mimeApp = this._wksCentralService.getContentMime(fileExtension);
    this._wksCentralService.downloadDoc(path, mimeApp)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        (retMes: any) => {
          const response = retMes.body;
          this._wksCentralService.saveToFileSystem(response, row.docFilename, mimeApp);
        }, err => {
 
        }
      );

  }
  addDoc(docType: string) {
    console.log('add --> ' + docType);
    this.pseudoDoc = {
      stdEntity: this.wksBnsCache.stdEntity,
      ownerId: this.wksBnsCache.id,
      docTags: docType,
      docName: '',
      docFilename: '',
    };
    this.openModal(this.docModal, this.pseudoDoc);
  }
  removeDoc(row: WksDocsModel) {
    this._wksCentralService.saveWksDoc(row, 'delete')
    .subscribe(
      () => {
          this.getDocumentsList();
      },
      () => {
        // this.errorMessage('remove', null);
      }
    );
   
  }
  getDateCreation(dateCur: any) {

  }
  deleteDoc(row: WksDocsModel) {
    // console.log('deleteEntity Row clicked: ', row);
    let titleBox: string;
    let messageBox: string;
   
    titleBox = 'deleting docs';
    titleBox = this._translate.getTranslate(titleBox);
    messageBox = (row.docName ? row.docName : row.docFilename);

    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'removeDoc', row );

  }
  displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string, dataCur: any): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        console.log('Dialog output:', data);
        if (( data === 'okAction') && (_actionCur === 'removeDoc')) {
          this.removeDoc(dataCur);
        }
      });

  }
}
