<div class="container-fluid stdTheme " *ngIf="okToDisplay" style="height: 700px!important; overflow-y: hidden;">
    <!--
    <div class="flex-row row stdTheme" style="margin: 0px;">
        <mat-toolbar class="example-header stdTheme">
        </mat-toolbar>
    </div>
    -->
    <ng-container *ngTemplateOutlet="equipListTemplate"></ng-container>
    <!--
    <div class="flex-row row stdTheme" style="margin: 0px;">
        <div class="actionsReferenced stdTheme col-3" style="padding: 0px" >
            <ng-container *ngTemplateOutlet="equipListTemplate"></ng-container>
        </div>

        <div class="actionsReferenced stdTheme col-9" style="padding: 0px" >
            <ng-container *ngTemplateOutlet="sidenavTemplate"></ng-container>
        </div>
    </div>
    -->
</div>
<ng-template #equipListTemplate >
    <div class="flex-row row stdTheme" style="margin: 0px;margin-top: 20px">
        <div class="actionsReferenced stdTheme col-3" style="padding: 0px" >
            <div class="stdTheme" *ngFor="let equipRow of equipItem.equipList; let idxEquip = index"  >
                <!--
                <div class="flex-row row rowEquipment rowEquipHover" 
                        [ngClass]="{'rowEquipOdd':rowEquip[idxEquip].isOdd,'rowEquipEven':rowEquip[idxEquip].isEven}">
                -->
                <fieldset class="rowEquipment-border" style="margin-bottom: 10px">
                    <div class="flex-row row rowEquipment " >
                        <div class="col-1 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                            <button mat-icon-button class="buttonTheme"  [disabled]="isReadOnly" style="padding-right: 10px;" (click)="addTaskEquipSelect(idxEquip);">
                                <mat-icon class="reorder" >add</mat-icon>
                            </button>
                        </div>
                        <div class="col-10 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;"
                                    [ngClass]="{
                                        'equipSelected':rowEquip[idxEquip].selected,
                                        'rowEquipment':!rowEquip[idxEquip].selected
                                    }">
                            <span *ngIf="equipRow.otherData === undefined || equipRow.otherData === null " style="margin-left: 0px">{{equipRow.label }}</span>
                            <span *ngIf="equipRow.otherData !== undefined && equipRow.otherData !== null " style="margin-left: 0px">
                                {{equipRow.label}} (SN : {{equipRow.otherData}})
                            </span>
                        </div>
                        <div class="col-1 d-flex justify-content-start" style="padding-left: 0px;padding-right: 0px;">
                            <button mat-icon-button (click)="toggleRowEquip(idxEquip)" 
                                title="{{'detail row' | translate }}">
                                <span *ngIf="rowEquip[idxEquip].detailCollapse" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                                <span *ngIf="!rowEquip[idxEquip].detailCollapse" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                            </button> 
                        </div>
                    </div>
                    <div *ngIf="!rowEquip[idxEquip].detailCollapse" style="margin-top: 10px; margin-bottom: 10px">
                        <div class="lineWks" style="margin-top: 5px;"></div>
                        <div class="stdTheme " *ngFor="let taskRow of rowEquip[idxEquip].workTasksEquip; let idxTask = index"  >
                            <div class="flex-row row rowTask" *ngIf="taskRow !== undefined && !taskRow.disabledTask"
                                    [ngClass]="{'greenBorder':!rowEquip[idxEquip].waitPricing[idxTask],'redBorder':rowEquip[idxEquip].waitPricing[idxTask]}">
                                <div class="col-1 d-flex justify-content-start divTask" style="padding-left: 0px;padding-right: 0px;">
                                    <button mat-icon-button matSuffix [disabled]="isReadOnly" (click)="taskEquipSelectRemoveOK(idxEquip, idxTask, taskRow);">
                                        <mat-icon class="reorder" >delete_sweep</mat-icon>
                                    </button>
                                </div>
                                <div class="col-10 d-flex justify-content-start divTask" style="padding-left: 0px;padding-right: 0px;"
                                                >
                                    <input id="taskrefLabel" #taskrefLabel class="inputTheme" style="margin-top: 5px;text-align: start;"
                                            type="text" value="{{taskRow.taskrefLabel}}"
                                            (change)="setEquipTaskLabelValue(taskrefLabel.value, idxEquip, idxTask)"
                                            [readonly]="isReadOnly"
                                            matInput placeholder="{{ 'taskrefLabel' | translate }}">
                                </div>
                                <div class="col-1 d-flex justify-content-start divTask " style="padding-left: 0px;padding-right: 0px;">
                                    <button mat-icon-button matSuffix  (click)="readEquipSelectedTaskValues(idxEquip, idxTask, taskRow);">
                                        <mat-icon class="reorder">details</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        
        <div class="actionsReferenced stdTheme col-9" style="padding: 0px;" >
            <div *ngIf="openedAccordion && parentTask.statutCall !==  'accordion'" >

                <div class="flex-row row rowEquipment" >
                    <div class="col-2 d-flex justify-content-start divTask" style="padding-left: 0px;padding-right: 0px;">
                        <!--
                        <button *ngIf="!isReadOnly" mat-button class="buttonTheme" 
                            style="margin-left: 10px;margin-right: 10px;" (click)="addTaskSpecific(); toggleSidenavRight('close')">
                                {{ 'CreateSpecificTask' | translate }}
                            <i class="fa fa-plus-square" style="margin-left: 10px; font-size: 20px" ></i>
                        </button>
                        -->
                    </div>
                    <div  class="col-8 d-flex justify-content-center divTask" style="padding-left: 0px;padding-right: 0px;">
                        <span *ngIf="equipItem.equipList[equipIdx].otherData === undefined || equipItem.equipList[equipIdx].otherData === null " style="margin-left: 0px">
                            <h6>{{equipItem.equipList[equipIdx].label}} / 
                            {{rowEquip[equipIdx].workTasksEquip[taskRowDisplay].taskrefLabel}}</h6>
                        </span>
                        <span *ngIf="equipItem.equipList[equipIdx].otherData !== undefined && equipItem.equipList[equipIdx].otherData !== null " style="margin-left: 0px">
                            <h6>{{equipItem.equipList[equipIdx].label}} (SN : {{equipItem.equipList[equipIdx].otherData}}) / 
                            {{rowEquip[equipIdx].workTasksEquip[taskRowDisplay].taskrefLabel}}</h6>
                        </span>
                    </div>
                    <div class="col-2 d-flex justify-content-center divTask" style="padding-left: 0px;padding-right: 0px;">
                        <button *ngIf="!isReadOnly" mat-button (click)="toggleSidenavRight('')">
                            <i *ngIf="!sidenavRight.opened" class='fa fa-angle-right fa-2x' style="margin-right: 10px;"></i>
                                {{ 'tasksReferenced' | translate }}
                            <i *ngIf="sidenavRight.opened" class='fa fa-angle-left fa-2x'style="margin-left: 10px;"></i>
                        </button>
                    </div>
                </div>
                <mat-sidenav-container class="stdTheme" style="margin-right: 10px; margin-left: 10px">
                    <mat-sidenav #sidenavRight mode="side" [(opened)]="openedRight" position="end" 
                                    (opened)="onChangeActionSidenav('openedRight open!')"
                                    (closed)="onChangeActionSidenav('openedRight close!')">
                        <div class="container-fluid stdTheme" style="height: 100%;">
                            <div class="flex-row row stdTheme" style="margin: 0px;">
                                <div class="actionsReferenced stdTheme col-12"  style="padding: 0px" >
                                    <mat-select id="workCode" placeholder="{{ 'workCode' | translate }}" #workCode
                                            panelClass="mdiselectcontent"   ngDefaultControl 
                                            (selectionChange)="onChangeWorkTask(workCode.value)" >
                                        <mat-option class="selectOptionsTheme" *ngFor="let workCodeCur of workCodeList"  
                                            [value]="workCodeCur.value"  >{{workCodeCur.label}}
                                        </mat-option>
                                    </mat-select>
                                    <button mat-icon-button matPrefix aria-label="search" >
                                        <mat-icon>search</mat-icon>
                                    </button>
                                    <input class="searchTheme" #filterTasks placeholder="{{ 'filterInput' | translate }}" 
                                            (keyup)="onKeyFilterTasks($event)" >
                                    <button mat-icon-button matSuffix aria-label="clear" 
                                            *ngIf="filterTasks.value" (click)="filterTasks.value=''; onKeyFilterTasks('');">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <div >
                                        <table class="table listAction stdTheme" id="tasksReferenced"  >
                                            <thead *ngIf="!isReadOnly" >
                                                <th colspan="3" >
                                                    <button mat-button class="buttonTheme" 
                                                        style="margin-left: 10px;margin-right: 10px;" (click)="addTaskReferenced()">
                                                            {{ 'CreateReferencedTask' | translate }}
                                                        <i class="fa fa-plus-square" style="margin-left: 10px; font-size: 20px" ></i>
                                                    </button>
                                                    <button mat-button class="buttonTheme" 
                                                        style="margin-left: 10px;margin-right: 10px;" (click)="refreshTaskReferenced()">
                                                        {{ 'Refresh' | translate }}
                                                    <i class="fas fa-redo" style="margin-left: 10px; font-size: 20px" ></i>
                                                </button>
                                                </th>
                                                    <th style="width: 5%"></th>
                                            </thead>
                                            <tbody >
                                                <div *ngFor="let referencedTaskCur of tasksReferenced; let i = index" >
                                                    <tr class="curAction curActionReferenced" *ngIf="referencedTaskCur.selectedTask === false" >
                                                        <td style="width: 100%; text-align: left;">
                                                            <button mat-button 
                                                            matTooltip={{referencedTaskCur.snTooltip}} 
                                                            matTooltipClass="custom-tooltip multiline-tooltip"
                                                            matTooltipPosition="above"
                                                            (click)="referencedToSelectedEquip(i);">
                                                                {{referencedTaskCur.taskrefLabel}}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </mat-sidenav>
            
                    <mat-sidenav-content  class="stdTheme">
                        <div class="container-fluid stdTheme " style="height: 600px;max-height: 600px;overflow-y: auto;">
                            <div class="flex-row row " style="margin: 0px;">
                                
                                <div class="col-12 actionsReferenced stdTheme contentTasks" style="padding: 0px" >                                    
                                    <div *ngIf="openedAccordion && parentTask.statutCall !==  'accordion'" >
                                        <span>
                                            <h5 class="titleTheme" style="padding-top: 10px;">{{ 'check list' | translate }}</h5>
                                        </span>
                                        <div class="flex-row row stdTheme" style="margin: 0px">
                                            <mdi-tasksref-accordion style="width: 100%;" 
                                                [parentTask]="parentTask"
                                                [ihmOptions]="ihmOptions"
                                                [tasksActionsContent]="tasksActionsContent"
                                                [tasksActionsParentContent]="tasksActionsParentContent"
                                                [actionType]="priceOption"
                                                [wksEntity]="wksEntity"
                                                [orig]="'workCard'"
                                                [rowTask]="rowTask">
                                            </mdi-tasksref-accordion>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-sidenav-content>
                </mat-sidenav-container>

            </div>
        </div>
    
    </div>
</ng-template>
<ng-template #sidenavTemplate >
    <mat-toolbar class="example-header stdTheme">
        <button *ngIf="!isReadOnly" mat-button class="buttonTheme" 
            style="margin-left: 10px;margin-right: 10px;" (click)="addTaskSpecific()">
                {{ 'CreateSpecificTask' | translate }}
            <i class="fa fa-plus-square" style="margin-left: 10px; font-size: 20px" ></i>
        </button>
        <p><button *ngIf="!isReadOnly" mat-button (click)="sidenavRight.toggle()">
            <i *ngIf="!sidenavRight.opened" class='fa fa-angle-right fa-2x' style="margin-right: 10px;"></i>
            {{ 'tasksReferenced' | translate }}
            <i *ngIf="sidenavRight.opened" class='fa fa-angle-left fa-2x'style="margin-left: 10px;"></i>
        </button></p>
    </mat-toolbar>

    <mat-sidenav-container class="stdTheme">
        <mat-sidenav #sidenavRight mode="side" [(opened)]="openedRight" position="end" 
                        (opened)="onChangeActionSidenav('openedRight open!')"
                        (closed)="onChangeActionSidenav('openedRight close!')">
            <div class="container-fluid stdTheme" style="height: 100%;">
                <div class="flex-row row stdTheme" style="margin: 0px;">
                    <div class="actionsReferenced stdTheme col-12"  style="padding: 0px" >
                        <mat-select id="workCode" placeholder="{{ 'workCode' | translate }}" #workCode
                                panelClass="mdiselectcontent"   ngDefaultControl 
                                (selectionChange)="onChangeWorkTask(workCode.value)" >
                            <mat-option class="selectOptionsTheme" *ngFor="let workCodeCur of workCodeList"  
                                [value]="workCodeCur.value"  >{{workCodeCur.label}}
                            </mat-option>
                        </mat-select>
                        <button mat-icon-button matPrefix aria-label="search" >
                            <mat-icon>search</mat-icon>
                        </button>
                        <input class="searchTheme" #filterTasks placeholder="{{ 'filterInput' | translate }}" 
                                (keyup)="onKeyFilterTasks($event)" >
                        <button mat-icon-button matSuffix aria-label="clear" 
                                *ngIf="filterTasks.value" (click)="filterTasks.value=''; onKeyFilterTasks('');">
                            <mat-icon>close</mat-icon>
                        </button>
                        <div >
                            <table class="table listAction stdTheme" id="tasksReferenced"  >
                                <thead *ngIf="!isReadOnly" >
                                    <th colspan="3" >
                                        <button mat-button class="buttonTheme" 
                                            style="margin-left: 10px;margin-right: 10px;" (click)="addTaskReferenced()">
                                                {{ 'CreateReferencedTask' | translate }}
                                            <i class="fa fa-plus-square" style="margin-left: 10px; font-size: 20px" ></i>
                                        </button>
                                        <button mat-button class="buttonTheme" 
                                            style="margin-left: 10px;margin-right: 10px;" (click)="refreshTaskReferenced()">
                                            {{ 'Refresh' | translate }}
                                        <i class="fas fa-redo" style="margin-left: 10px; font-size: 20px" ></i>
                                    </button>
                                    </th>
                                        <th style="width: 5%"></th>
                                </thead>
                                <tbody >
                                    <div *ngFor="let referencedTaskCur of tasksReferenced; let i = index" >
                                        <tr class="curAction curActionReferenced" *ngIf="referencedTaskCur.selectedTask === false" >
                                            <td style="width: 100%; text-align: left;">
                                                <button mat-button (click)="referencedToSelected(i);">
                                                    {{referencedTaskCur.taskrefLabel}}
                                                </button>
                                            </td>
                                        </tr>
                                    </div>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>  
            </div>
        </mat-sidenav>

        <mat-sidenav-content  class="stdTheme">
            <div class="container-fluid stdTheme " style="overflow-y: hidden;">
                <div class="flex-row row " style="margin: 0px;">
                    <div class="actionsReferenced stdTheme col-3" style="padding: 0px" >
                        <table class="tableWork listAction stdTheme" id="tasksSelected" >
                            <tbody class="tbodyWork" >
                                <div *ngFor="let taskSelectedCur of workTasksContent; let i = index"  >
                                    <tr class="trWork curAction curActionDisplayed" cdkDrag *ngIf="taskSelectedCur.disabledTask === false"
                                        (click)="readSelectedTaskValues(i);"
                                        [ngClass]="{'greenBorder':!waitPricing[i],'redBorder':waitPricing[i]}">
                                        <td style="width: 5%; padding: 0px; vertical-align: middle;" [disabled]="isReadOnly" ><mat-icon class="reorder" >reorder</mat-icon></td>
                                        <td style="width: 10%; padding: 0px; vertical-align: middle;">{{taskSelectedCur.ranking }}</td>
                                        <td style="width: 65%; padding: 0px; vertical-align: middle; text-align: left; vertical-align: middle;">
                                            <input id="taskrefLabel" #taskrefLabel class="inputTheme" 
                                                type="text" value="{{taskSelectedCur.taskrefLabel}}"
                                                (change)="setTaskLabelValue(taskrefLabel.value, i)"
                                                [readonly]="isReadOnly"
                                                matInput placeholder="{{ 'taskrefLabel' | translate }}">
                                        </td>
                                        <td style="width: 10%; padding: 0px; vertical-align: middle;" >
                                            <button mat-icon-button matSuffix [disabled]="isReadOnly" (click)="taskSelectRemoveOK(i, taskSelectedCur);">
                                                <mat-icon class="reorder" >delete_sweep</mat-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </div>
                            </tbody>
                        </table>
                    </div>
                    <div class="actionsReferenced stdTheme contentTasks col-9" style="padding: 0px" >
                        <div *ngIf="(workTasksContent === null) || (workTasksContent === undefined) || (workTasksContent.length === 0)" >
                            <h5 class="titleTheme" style="padding-top: 10px;">{{ 'check list empty' | translate }}</h5>
                        </div>
                        <div *ngIf="(tasksActionsContent !== undefined && tasksActionsContent.equipments !== null && tasksActionsContent.equipments !== undefined)" >
                            <div class="flex-row row " style="margin: 0px;height: 44px;">
                                <div class="col-lg-3 " style="margin-top: 5px;">
                                    <div class="checkbox">
                                        <label  style="margin-top: 5px; margin-left: 0px; margin-right: 15px;" >{{ 'allEquipments' | translate }}</label>
                                        <input type="checkbox" id="allEquipments"  #allEquipments 
                                                [checked]="allEquipmentsCheck" 
                                                (click)="changeCheckEquipments(allEquipments.checked)">
                                    </div>
                                </div>
                                <div class="col-lg-9 " *ngIf="!allEquipmentsCheck" style="padding-left: 0px;padding-right: 0px;">
                                    <div class="flex-row row " style="margin: 0px;height: 44px;">
                                        <div class="col-lg-3 ">
                                            <label  style="margin-top: 10px; margin-left: 0px; margin-right: 15px;" >{{ 'equipmentsSelected' | translate }}</label>
                                        </div>
                                        <div class="col-lg-9 " style="padding-left: 0px;padding-right: 0px;">
                                            <mat-form-field appearance="none" style="width: 100%;margin-top: -20px;">
                                                <!--<mat-label >{{ 'equipmentsSelected' | translate }}"</mat-label>-->       
                                                <mat-select #equipmentsSelected class="stdTheme stdSelect"
                                                            ngDefaultControl multiple 
                                                            [value]="equipListCheck"
                                                            (selectionChange)="changeListEquipments(equipmentsSelected.value)">
                                                    <mat-option class="selectOptionsTheme" *ngFor="let equipCur of equipList"  [value]="equipCur.value">
                                                            <span *ngIf="equipCur.otherData === null || equipCur.otherData === undefined ">{{ equipCur.label }}</span>
                                                            <span *ngIf="equipCur.otherData !== null && equipCur.otherData !== undefined ">
                                                                {{ equipCur.label }} (SN : {{ equipCur.otherData }}) </span>
                                                    </mat-option>
                                                    <div class="flex-row button-row text-center" style="margin-bottom: 5px;" >
                                                        <button mat-raised-button class="buttonTheme"  (click)="equipmentsSelected.toggle()">
                                                        {{'Close' | translate}}
                                                        <mat-icon>cancel</mat-icon>
                                                        </button>    
                                                    </div>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>                       
                        </div>
                        <div *ngIf="openedAccordion && parentTask.statutCall !==  'accordion'" >
                            <span>
                                <h5 class="titleTheme" style="padding-top: 10px;">{{ 'check list' | translate }}</h5>
                            </span>
                            <mdi-tasksref-accordion style="width: 100%;" 
                                            [parentTask]="parentTask"
                                            [ihmOptions]="ihmOptions"
                                            [tasksActionsContent]="tasksActionsContent"
                                            [tasksActionsParentContent]="tasksActionsParentContent"
                                            [actionType]="priceOption"
                                            [wksEntity]="wksEntity"
                                            [orig]="'workCard'"
                                            [rowTask]="rowTask"></mdi-tasksref-accordion>
                        </div>
                    </div>
                </div>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-template>
<ng-template #taskReferencedModal >
    <!--
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    -->
    <div class="modal-content" style="height: 750px!important; overflow-y: hidden!important;">
        <div class="modal-body modal-works-tasks-body" >
            <mdi-tasksref-card style="width: 100%;"
                [tasksrefList]="undefined"
                [optionItem]="'works'" 
                [actionType]="'add'" 
                [parentTask]="parentTaskRef"
                [ihmOptions]="ihmOptions">
        </mdi-tasksref-card>
        </div>
    </div>
     
  </ng-template>