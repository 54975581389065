
import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig, MatTabChangeEvent, MatRadioChange, MatDialogRef, MatSidenav } from '@angular/material';
import { Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


import { UserService } from '../../../../../../services/user.service';
import { RessourcesService } from '../../../../../../job/common/services/ressources.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { WksEntityModel } from '../../../../models/wks-entity.model';
import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';

import { TasksActionsModel, TaskrefJsonModel,
          WksTasksModel, ParentTask, TasksActionsContentModel, 
          IhmOptions, TasksActionCompletedModel,
          PartsRequirement,
          OrderParts } from '../../../../models/wks-param.model';
import { WksEquipmentModel} from '../../../../models/wks-equipment.model';

import { WksWorksModel, WorkEquipmentRef, WorkEquipments, WorkInputEvent } from '../../../../models/wks-works.model';
import { InternationalsFormats } from '../../../../../../models/data.model';

import { GlobalEventsManagerService } from '../../../../../../job/common/services/global-events-manager.service';
import { WksWorksService } from '../../../../services/wks-works.service';
import { MessageDisplay, ToolApiResponse, ToolArgs, ToolKeyValue } from '../../../../../../models/common.model';

@Component({
  selector: 'mdi-works-output-stocks',
  templateUrl: './works-output-stocks.component.html',
  styleUrls: ['./works-output-stocks.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WorksOutputStocksComponent implements OnInit {
  @Input() statutCall: any;
  @Input() actionType: string;
  @Input() iRow: number;
  @Input() ihmOptions: IhmOptions;
  @Input() wksWorks: WksWorksModel;
  @Output() dataOut = new EventEmitter<WorkInputEvent>();
  private readonly onDestroy = new Subject<void>();

  wksEntity: WksEntityModel;
  isReadOnly: boolean;

  modalTitle: string;
  workCur: WksWorksModel;
  workEquipments: WorkEquipments;
  partsIsLoaded: boolean;
  itemChoose: string;
  nameParent: string;

  ihmOptionsCkeck: IhmOptions;
  internationalsFormats: InternationalsFormats;
  dateFormat: string;
  dateFormatMoment: string;
  dateTimeFormat: string;
  checkEquipments: WksEquipmentModel [];
  workEquipmentId: WorkEquipmentRef;
  isOkToValid: boolean[];
 
  itemEquip: WksEquipmentModel;
  itemTasks: TasksActionCompletedModel;
  tasksActionsCompleted: TasksActionCompletedModel[];
  tasksActionsArgs: TasksActionCompletedModel[];
  // partsRequirement: PartsRequirement[];
  // partsRequirementCache: PartsRequirement[];
  dataLabelCur: string[];
  
  numberFormat: {
    locale: string;
    minDecimal: string;
    maxDecimal: string;
    currency: string;
  };

  verifyParts: boolean;
  messageDialogRef: MatDialogRef<ModalCommonComponent>;
  modalRef: BsModalRef;
  
  configModal = {
    class: 'modal-dialog-centered modal-works-tasks',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: false,
    
  };
  isOkToReserve: boolean[];
  ordersParts: OrderParts[];
  noParts: boolean;
  constructor(
    private _userService: UserService,
    private _ressourceService: RessourcesService,
    private _globalEventsManagerService: GlobalEventsManagerService,
    private _wksCentralService: WksCentralService,
    private _translateService: TranslateService,
    private _ressourcesService: RessourcesService,
    private modalService: BsModalService,
    private _dialog: MatDialog,
    private _wksWorksService: WksWorksService
  ) { }

  ngOnInit() {
    this.initData();
  }
  initData() {
    this.wksEntity = this._wksCentralService.getWksEntityCur();
    this.workCur = JSON.parse(JSON.stringify(this.wksWorks));
    this.initAllBooleans();
    this.settingFormats();
    this.initAllParam();
    this.initAllSubscribe();
    this.buildForm();
    this.initListParts();
    /*.then (() => {

    }, () => {


    }); // loadWork
    */
  }
  initAllBooleans() {

    this.partsIsLoaded = false;
    this.isReadOnly = true;
    // this.isCheckLoaded = false;
    // this.isOkToValid = false;
    this.verifyParts = false;
    // this.isOkToReserve = false;
    this.noParts = false;

  }
  settingFormats(): void {
    const otherData = JSON.parse(this._wksCentralService.getWksEntityCur().otherData);
    const intFormat = otherData.internationnalFormat;
    this.numberFormat = {
      locale: this._ressourceService.getFormat(intFormat, 'numbers', 'locale'),
      minDecimal: this._ressourceService.getFormat(intFormat, 'numbers', 'minimumFractionDigits'),
      maxDecimal: this._ressourceService.getFormat(intFormat, 'numbers', 'maximumFractionDigits'),
      currency:  this._ressourceService.getFormat(intFormat, 'numbers', 'currency'),
    };
  }
  initAllParam() {
    if (this.workCur.workStatut === 'accepted' || this.workCur.workStatut === 'approved' || this.workCur.workStatut === 'in-progress') {
      this.verifyParts = true;
    }
    this.wksEntity = this._wksCentralService.getWksEntityCur();
    const otherData = JSON.parse(this.wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.internationalsFormats = this._ressourcesService.getIntFormat(internationnalFormat);
    for (const fmtCur of this.internationalsFormats.datetime) {
      if (fmtCur.name === 'dateFormat') {
        this.dateFormat = fmtCur.value;
        this.dateFormatMoment = fmtCur.value;
        this.dateFormatMoment = this.dateFormatMoment.replace('dd', 'DD');
        this.dateFormatMoment = this.dateFormatMoment.replace('yyyy', 'YYYY');
      }
      if (fmtCur.name === 'timeFormat') {
        this.dateTimeFormat = fmtCur.value;
        this.dateTimeFormat = this.dateTimeFormat.replace('dd', 'DD');
        this.dateTimeFormat = this.dateTimeFormat.replace('yyyy', 'YYYY');
      }

    }
 
    this.getIhmOptions();
  }
  initAllSubscribe() {
    this._globalEventsManagerService.synchroEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {
      if (event === 'workfinished') {

        const eventWork = {
          workevent: 'finished',
          iRow: this.iRow,
          workStatut: 'finished',
        };
        this._globalEventsManagerService.synchroEvents.next(eventWork);
      } else if (event) {
        const typeEvent = typeof event;
        const eventString = String(event);
        // console.log(typeEvent);
      }
    });
  }
  
  getIhmOptions(): void {
    this.ihmOptionsCkeck = JSON.parse(JSON.stringify(this.ihmOptions));
    // this.ihmOptionsCkeck.readOnly = true;
    this.ihmOptionsCkeck.collapse = false;
    this.isReadOnly = this.ihmOptionsCkeck.readOnly;
  }
  
  buildForm(): void {

    this.dataLabelCur = [];
    this.dataLabelCur[0] = this._translateService.instant('suppliesLabel');
    this.dataLabelCur[1] = this._translateService.instant('brandRef');
    this.dataLabelCur[2] = this._translateService.instant('billingUnit');
    this.dataLabelCur[3] = this._translateService.instant('quantity');
    this.dataLabelCur[4] = this._translateService.instant('actionTask');
    this.dataLabelCur[5] = this._translateService.instant('inStock');
    this.dataLabelCur[6] = this._translateService.instant('ouputStock');
    this.dataLabelCur[7] = this._translateService.instant('movementStock');
  }
  
  initListParts(): void {

    // partsRequirement = [];
    // partsRequirementCache = [];
    this.ordersParts = [];
    this.isOkToValid = [];
    this.isOkToReserve = [];

    // const partsRequirement: PartsRequirement[] = [];   
    const partsRequirementTasks = this.initListPartsFromTasks();
    let orderedParts: PartsRequirement[] = [];  
    if (this.wksWorks.workJsonData) {
      const workJsonData = JSON.parse(this.wksWorks.workJsonData);
      if (workJsonData.orderedParts) {
        orderedParts = JSON.parse(JSON.stringify(workJsonData.orderedParts));
      }
    }
    const partsRequirement = this.sortOrderedParts(partsRequirementTasks, orderedParts);

    this.repartOrdersParts(partsRequirement);
    if (this.verifyParts) {
      let orderIdx = 0;
      for (const orderItem of this.ordersParts) {
        this.isOkToValid[orderIdx] = false;
        this.isOkToReserve[orderIdx] = false;
        if (orderItem.nws_order_ref === 'NOT_RESERVED' 
            || orderItem.nws_order_ref === '' 
            || orderItem.nws_order_ref === undefined) {
          this.verifyStockParts(orderItem.partsRequirement, orderIdx, orderItem.nws_order_ref);
        }
        orderIdx++;
      }
    }
    this.partsIsLoaded = true;
  }
  repartOrdersParts(partsRequirementAll:  PartsRequirement[]): void {
    this.ordersParts = [];
     // orders parts
     let orderRefTmp = '';
     let idxOrderCur = 0 ;
     for (const partItem of  partsRequirementAll) {
       if (partItem.nws_order_ref === undefined) {
         orderRefTmp = 'NOT_RESERVED';
       } else {
         orderRefTmp = partItem.nws_order_ref;
       }
       let isOrderFound = false;
       for (const orderItem of this.ordersParts) {
         if (orderItem.nws_order_ref !== orderRefTmp) {
           continue;
         } 
         isOrderFound = true;
         if (orderItem.partsRequirement === undefined) {
           orderItem.partsRequirement = [];
         }
         orderItem.partsRequirement.push(partItem);
       }
       if (!isOrderFound) {
         const orderTmp: OrderParts = {
           nws_order_ref: orderRefTmp,
           nws_order_id: partItem.nws_order_id,
           idxOrder: idxOrderCur,
           actionOrder: orderRefTmp === 'NOT_RESERVED' ? 'reserveStockParts' : 'removeOrderParts',
           partsRequirement: []
         };
         orderTmp.partsRequirement.push(partItem);
         this.ordersParts.push(orderTmp);
         idxOrderCur++;
       }
     }
  }
  sortOrderedParts(partsRequirementTasks:  PartsRequirement[], orderedParts: PartsRequirement[]): PartsRequirement[] {
    const sortedOrdersPart: PartsRequirement[] = [];
    let isFound = false;
    for (const orderedPartItem of orderedParts) {
      sortedOrdersPart.push(orderedPartItem);
    }
    for (const partTaskItem of partsRequirementTasks) {
      isFound = false;
      let orderedPartCur: PartsRequirement;
      for (const orderedPartItem of orderedParts) {
        if (orderedPartItem.partSupplieId === partTaskItem.partSupplieId) {
          isFound = true;
          orderedPartCur = orderedPartItem;
          break;
        }
      }
      if (!isFound) { // new parts 
        partTaskItem.nws_order_ref = 'NOT_RESERVED' ;
        sortedOrdersPart.push(partTaskItem);
      } else { // additional parts
        if (orderedPartCur.quantity < partTaskItem.quantity) {
          const partTaskCur = JSON.parse(JSON.stringify(partTaskItem));
          partTaskCur.quantity = partTaskItem.quantity - orderedPartCur.quantity;
          partTaskCur.nws_order_ref = 'NOT_RESERVED' ;
          sortedOrdersPart.push(partTaskCur);
        }
      }
    } 
    return sortedOrdersPart;
  }
  initListPartsFromTasks(): PartsRequirement[] {
    
    let suppliesActionsSelected: TasksActionsModel[] = [];
    let partsRequirementBdd: PartsRequirement[] = [];
    let partsRequirement: PartsRequirement[] = [];

    const partsRequirementNeeded: PartsRequirement[] = [];

    let equipments: {
        all: boolean;
        equipId: string[];
    };

    this.tasksActionsCompleted = [];
    let workEquipmentId: WorkEquipmentRef;
    let numberEquip = 0;
    workEquipmentId = JSON.parse(this.workCur.workEquip);
    if (workEquipmentId.subEquipments === undefined || workEquipmentId.subEquipments.length === 0) {
      numberEquip = 1;
    } else {
      numberEquip = workEquipmentId.subEquipments.length;
    }

    let equipmentsCur = 0;
    if (this.workCur.workTasksContent === null || this.workCur.workTasksContent === undefined) {

      this.noParts = true;
      this.partsIsLoaded = true;
      return;
    }
    for (const taskCur of this.workCur.workTasksContent) {
      suppliesActionsSelected = [];
      const taskrefJson = JSON.parse( taskCur.taskrefJson) as TaskrefJsonModel;
      if (taskrefJson.tasksActionsParentContent !== undefined) {
        const tasksActionsParentContent = taskrefJson.tasksActionsParentContent as TasksActionsContentModel;
        suppliesActionsSelected = tasksActionsParentContent.supplies;
      }
      if (taskrefJson.tasksActionsContent !== undefined) {
        const tasksActionsContent = taskrefJson.tasksActionsContent as TasksActionsContentModel;
        suppliesActionsSelected.push(...tasksActionsContent.supplies);
        equipments = tasksActionsContent.equipments;
        if (equipments.all) {
          equipmentsCur = numberEquip;
        } else {
          equipmentsCur = equipments.equipId.length;
        }
        // console.log(tasksActionsContent);
      }
      for (const actionItem of suppliesActionsSelected ) {
        let isFound = false;
        for (const partsRequirementItem of partsRequirementNeeded) {
          if (actionItem.partSupplieId === partsRequirementItem.partSupplieId) {
            partsRequirementItem.quantity = actionItem.quantity * equipmentsCur;
            isFound = true;
            break;
          }
        } // end lopp partsRequirementNeeded
        if (!isFound) {
          const itemPartsRequirement: PartsRequirement = {
            partSupplieId: actionItem.partSupplieId,
            partSupplieLabel: actionItem.actionrefLabel,
            brandRef: actionItem.brandRef,
            actionTask: actionItem.actionTask,
            billingUnit: actionItem.billingUnit,
            quantity: actionItem.quantity * equipmentsCur,
            outputStock: 0,
            inputStock: 0,
            movementStock: 0,
            inStock: 0,
            nws_order_id: undefined,
            nws_order_ref: undefined

          };
          partsRequirementNeeded.push(itemPartsRequirement);
        }
      } // end loop suppliesActionsSelected
      if (taskrefJson.partsRequirement !== undefined) {
        partsRequirementBdd = taskrefJson.partsRequirement;
        // ouputstock already done
        for (const itemBdd of partsRequirementBdd ) {
          for (const itemNeeded of partsRequirementNeeded) {
            if (itemBdd.partSupplieId === itemNeeded.partSupplieId) {
              itemNeeded.outputStock = itemNeeded.outputStock + itemBdd.outputStock;
              break;
            }
          }
        }
      }
    } // end loop workTasksContent
    partsRequirement = JSON.parse(JSON.stringify(partsRequirementNeeded));
    return partsRequirement;
  }
  // JLG -2025-01-07: ATTENTIOON Adapter à la dernière version API (movement Add - movement less)
  eventStock(field: string, value: number, idxPart: number, partsRequirement: PartsRequirement[], orderIdx: number): void {
 
    switch (field) {
      case 'inputStock': {

        partsRequirement[idxPart].movementStock += value;
        partsRequirement[idxPart].inputStock += value;
        partsRequirement[idxPart].outputStock -= value;
        this.isOkToValid[orderIdx] = true;
        if (partsRequirement[idxPart].movementStock === 0 ) {
          this.isOkToValid[orderIdx] = false;
        }
        break;
      } // end case
      case 'outputStock': {
        if ((partsRequirement[idxPart].movementStock + value) * -1 > partsRequirement[idxPart].inStock) {
          break;
        }
        if (partsRequirement[idxPart].outputStock + (value * -1) > partsRequirement[idxPart].quantity) {
          break;
        }

       partsRequirement[idxPart].movementStock += value;
       partsRequirement[idxPart].outputStock += (value * -1) ;
       this.isOkToValid[orderIdx] = true;
        if (partsRequirement[idxPart].movementStock === 0 ) {
          this.isOkToValid[orderIdx] = false;
        }
        break;
      } // end case
     
      default: {
        this.isOkToValid[orderIdx] = false;
        break;
      } // end case 
    }
  }

  fillModel(): void {
    
  }
  removeOrderParts( orderIdx: number): any {
    const apiService = 'orderRemove';
    const apiName = 'stockManagement';
    const toolArgs: ToolArgs = {
      toolDatas: []
    };
    let testData = {
      key: 'toolCustomerNwsCode',
      value: this._wksCentralService.getApiStockManagement().toolCustomerNwsCode
    };
    toolArgs.toolDatas.push(testData);
    testData = {
      key: 'toolCustomerNwsId',
      value: this._wksCentralService.getApiStockManagement().toolCustomerNwsId
    };
    toolArgs.toolDatas.push(testData);

    let toolKeyValue: ToolKeyValue = { 
      key: 'nws_order_id',
      value: this.ordersParts[orderIdx].nws_order_id,
      listValues: undefined,
      arrayValues: undefined
    }; 
    let jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);
    toolKeyValue = { 
      key: 'nws_order_ref',
      value: this.ordersParts[orderIdx].nws_order_ref,
      listValues: undefined,
      arrayValues: undefined
    }; 
    jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);
    
    const messageDisplay: MessageDisplay = {
      titleBox: 'removeOrderPartsTitle',
      messageLabel: apiName + 'Mes',
      messageArgs: [this._translateService.getTranslate(apiService), this._translateService.getTranslate('inProgress')],
      messageType: 'ERROR',
      messageDatas: undefined,
      typeDialog: 'infoProgressWks',
      actionCur: 'removeOrderParts',
      progressDeterminate: false,
    };
    this.messageBoxDisplay(messageDisplay);
    this._wksCentralService.requestApis(this.wksWorks.stdEntity, apiName, apiService, toolArgs )
    .subscribe(
      data => {
        this.closeMatDialog();
        const toolApiResponse = data.body as ToolApiResponse;
        if (toolApiResponse.statut === 'KO') {
          this.affectResponse(toolApiResponse, 'removeOrderParts', 'KO', this.ordersParts[orderIdx].partsRequirement, orderIdx);

          this.fillModel();
          this.validData();
          const messageDisplay2: MessageDisplay = {
            titleBox: 'removeOrderPartsTitle',
            messageLabel: apiName + 'Mes',
            messageArgs: [this._translateService.getTranslate(apiService), 'KO'],
            messageType: 'ERROR',
            messageDatas: undefined,
            typeDialog: 'alertWks',
            actionCur: 'removeOrderPartsKO',
            progressDeterminate: false,
          };
          this.messageBoxDisplay(messageDisplay2);
        } else  {
          const messageDisplay1: MessageDisplay = {
            titleBox: 'removeOrderPartsTitle',
            messageLabel: apiName + 'Mes',
            messageArgs: [this._translateService.getTranslate(apiService), 'OK'],
            messageType: 'INFO',
            messageDatas: undefined,
            typeDialog: 'alertWks',
            actionCur: 'removeOrderPartsOK',
            progressDeterminate: false,
          };
          this.messageBoxDisplay(messageDisplay1);
          this.affectResponse(toolApiResponse, 'removeOrderParts', 'OK', this.ordersParts[orderIdx].partsRequirement, orderIdx);

          this.fillModel();
          this.validData();
        }
      },
      err => {
        this.closeMatDialog();
        const toolParamReturn = err.error as ToolApiResponse;
        toolParamReturn.statut = err.status.toString();
        this.affectResponse(toolParamReturn, 'removeOrderParts', 'KO', this.ordersParts[orderIdx].partsRequirement, orderIdx);

        this.fillModel();
        this.validData();
        const messageDisplay2: MessageDisplay = {
          titleBox: 'testApi',
          messageLabel: apiName + 'Mes',
          messageArgs: [this._translateService.getTranslate(apiService), 'KO'],
          messageType: 'ERROR',
          messageDatas: undefined,
          typeDialog: 'alertWks',
          actionCur: 'testApiKO',
          progressDeterminate: false,
        };
        this.messageBoxDisplay(messageDisplay2);

      }
    );
  }
  reserveStockParts(partsRequirement: PartsRequirement[], orderIdx: number): any {
    const apiService = 'orderValidate';
    const apiName = 'stockManagement';
    const toolArgs: ToolArgs = {
      toolDatas: []
    };
    let testData = {
      key: 'toolCustomerNwsCode',
      value: this._wksCentralService.getApiStockManagement().toolCustomerNwsCode
    };
    toolArgs.toolDatas.push(testData);
    testData = {
      key: 'toolCustomerNwsId',
      value: this._wksCentralService.getApiStockManagement().toolCustomerNwsId
    };
    toolArgs.toolDatas.push(testData);

    const toolKeyValue: ToolKeyValue = { 
      key: 'partsList',
      value: '',
      listValues: undefined,
      arrayValues: partsRequirement
    }; 
    const jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);
    const messageDisplay: MessageDisplay = {
      titleBox: 'reserveStockPartsTitle',
      messageLabel: apiName + 'Mes',
      messageArgs: [this._translateService.getTranslate(apiService), this._translateService.getTranslate('inProgress')],
      messageType: 'ERROR',
      messageDatas: undefined,
      typeDialog: 'infoProgressWks',
      actionCur: 'reserveStockParts',
      progressDeterminate: false,
    };
    this.messageBoxDisplay(messageDisplay);
    this._wksCentralService.requestApis(this.wksWorks.stdEntity, apiName, apiService, toolArgs )
    .subscribe(
      data => {
        this.closeMatDialog();
        const toolApiResponse = data.body as ToolApiResponse;
        if (toolApiResponse.statut === 'KO') {
          this.affectResponse(toolApiResponse, 'reserveStockParts', 'KO', partsRequirement, orderIdx);

          this.fillModel();
          this.validData();
          const messageDisplay2: MessageDisplay = {
            titleBox: 'reserveStockPartsTitle',
            messageLabel: apiName + 'Mes',
            messageArgs: [this._translateService.getTranslate(apiService), 'KO'],
            messageType: 'ERROR',
            messageDatas: undefined,
            typeDialog: 'alertWks',
            actionCur: 'reserveStockPartsKO',
            progressDeterminate: false,
          };
          this.messageBoxDisplay(messageDisplay2);
        } else  {
          const messageDisplay1: MessageDisplay = {
            titleBox: 'reserveStockPartsTitle',
            messageLabel: apiName + 'Mes',
            messageArgs: [this._translateService.getTranslate(apiService), 'OK'],
            messageType: 'INFO',
            messageDatas: undefined,
            typeDialog: 'alertWks',
            actionCur: 'reserveStockPartsOK',
            progressDeterminate: false,
          };
          this.messageBoxDisplay(messageDisplay1);
          this.affectResponse(toolApiResponse, 'reserveStockParts', 'OK', partsRequirement, orderIdx);

          this.fillModel();
          this.validData();
        }
      },
      err => {
        this.closeMatDialog();
        const toolParamReturn = err.error as ToolApiResponse;
        toolParamReturn.statut = err.status.toString();
        this.affectResponse(toolParamReturn, 'reserveStockParts', 'KO', partsRequirement, orderIdx);

        this.fillModel();
        this.validData();
        const messageDisplay2: MessageDisplay = {
          titleBox: 'testApi',
          messageLabel: apiName + 'Mes',
          messageArgs: [this._translateService.getTranslate(apiService), 'KO'],
          messageType: 'ERROR',
          messageDatas: undefined,
          typeDialog: 'alertWks',
          actionCur: 'testApiKO',
          progressDeterminate: false,
        };
        this.messageBoxDisplay(messageDisplay2);
      }
    );
  }
  affectResponse(toolApiResponse: ToolApiResponse, action: string, statut: string, partsRequirement: PartsRequirement[], orderIdx: number ): void {
    if (statut === 'OK') {
      if (action === 'reserveStockParts') {
        let nws_order_id ;
        let nws_order_ref ;

        for (const itemData of toolApiResponse.toolDatas) {
          if (itemData.key === 'nws_order_id') {
            nws_order_id = itemData.value;
            continue;
          }
          if (itemData.key === 'nws_order_ref') {
            nws_order_ref = itemData.value;
            continue;
          }
        }

        for (const itemPart of partsRequirement) {
          itemPart.nws_order_id = nws_order_id;
          itemPart.nws_order_ref = nws_order_ref;
        }
        this.ordersParts[orderIdx].partsRequirement = partsRequirement;
        this.ordersParts[orderIdx].nws_order_ref = nws_order_ref;
        this.ordersParts[orderIdx].nws_order_id = nws_order_id;
        this.ordersParts[orderIdx].actionOrder = 'removeOrderParts';
      }
      if (action === 'removeOrderParts') {
        for (const itemPart of partsRequirement) {
          itemPart.nws_order_id = undefined;
          itemPart.nws_order_ref = 'NOT_RESERVED';
        }
        this.ordersParts[orderIdx].partsRequirement = partsRequirement;
        this.ordersParts[orderIdx].nws_order_ref = 'NOT_RESERVED';
        this.ordersParts[orderIdx].nws_order_id = undefined;
        this.ordersParts[orderIdx].actionOrder = 'reserveStockParts';
        const partsRequirementAll: PartsRequirement[] = [];
        const partsRequirementTmp: PartsRequirement[] = [];
        for (const orderItem of this.ordersParts) {
          partsRequirementTmp.push(...orderItem.partsRequirement);
        }
        // dedoublement parts
        for (const partItem of partsRequirementTmp) {
          if (partItem.nws_order_ref === 'NOT_RESERVED') {
            let isFound = false;
            for (const partItem1 of partsRequirementAll) {
              if (partItem1.nws_order_ref === 'NOT_RESERVED' 
                  && partItem1.partSupplieId === partItem.partSupplieId ) {
                    partItem1.quantity += partItem.quantity;
                    isFound = true;
              }

            } // end loop partsRequirementAll
            if (!isFound)  {
              partsRequirementAll.push(partItem);
            }
          } else  {
            partsRequirementAll.push(partItem);
          }
        }
        this.repartOrdersParts(partsRequirementAll);
      }
    }

  }
  validData(): void {
    const partsRequirementOrdered: PartsRequirement[] = [];
    for (const orderItem of this.ordersParts) {
      if (orderItem.nws_order_ref !== 'NOT_RESERVED' ) {
        partsRequirementOrdered.push(...orderItem.partsRequirement);
      }
    }
    const workInputEvent: WorkInputEvent = {
      objectCur: 'orderedParts',
      action: 'update',
      orderedParts: partsRequirementOrdered
    };
    this.dataOut.emit(workInputEvent);
  }
  verifyStockParts(partsRequirement: PartsRequirement[], orderIdx: number, orderRef: string): any {
    const messageDisplay: MessageDisplay = {
      titleBox: 'verifyStock',
      messageLabel: 'verifyStockParts',
      messageArgs: [],
      messageType: 'ERROR',
      messageDatas: undefined,
      typeDialog: 'infoProgressWks',
      actionCur: 'verifyStockParts',
      progressDeterminate: false,
    };
    this.messageBoxDisplay(messageDisplay);
    this._wksCentralService.verifyStockParts(partsRequirement, this.wksWorks.stdEntity, this.wksWorks.id, this._userService.getUserLogged().userlang)
    .subscribe(
      data => {
        this.closeMatDialog();
        const responseParts = data.body as PartsRequirement[];
        const messageDisplay1: MessageDisplay = {
          titleBox: 'verifyStock',
          messageLabel: 'verifyStockPartsDone',
          messageArgs: [],
          messageType: 'INFO',
          messageDatas: undefined,
          typeDialog: 'alertWks',
          actionCur: 'verifyStockPartsDone',
          progressDeterminate: false,
        };
        this.messageBoxDisplay(messageDisplay1);
        let stockError = 0;
        for (const partItem of partsRequirement) {
          for (const responseItem of responseParts) {
            if (responseItem.brandRef === partItem.brandRef) {
              partItem.stockStatus = responseItem.stockStatus;
              partItem.inStock  = responseItem.inStock;
              partItem.minStock  = responseItem.minStock;
              if (responseItem.stockStatus === 'NOT_REFERENCED') {
                stockError++;
                continue;
              }
              partItem.stockStatus = 'OK';
              if (partItem.inStock === 0 || partItem.inStock < partItem.quantity) {
                stockError++;
                partItem.stockStatus = 'KO';
              }
              break;
            }
          }
        }
    
        this.isOkToReserve[orderIdx] = stockError === 0 ? true : false;

        // const jsonParts = JSON.stringify(this.partsRequirement);
        // console.log(jsonParts);
      },
      err => {
        this.closeMatDialog();
        const messageDisplay2: MessageDisplay = {
          titleBox: 'verifyStock',
          messageLabel: 'verifyStockPartsFailed',
          messageArgs: [],
          messageType: 'ERROR',
          messageDatas: undefined,
          typeDialog: 'alertWks',
          actionCur: 'verifyStockPartsFailed',
          progressDeterminate: false,
        };
        this.messageBoxDisplay(messageDisplay2);

      }
    );
  }
  // ========================================================== errors alerts 
messageBoxDisplay(_messageDisplay: MessageDisplay): void {

  const dialogConfig = new MatDialogConfig();
  const messageBox = this._translateService.instant(_messageDisplay.messageLabel, _messageDisplay.messageArgs );
  
  // const messageDisplayCACHE = JSON.parse(JSON.stringify(_messageDisplay));
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data = {
    id: 1,
    title: this._translateService.getTranslate( _messageDisplay.titleBox),
    typeDialog: _messageDisplay.typeDialog,
    panelClass: 'stdTheme',
    contentMessage: messageBox,
    data1: _messageDisplay.messageDatas,
    data2: '',
    actionCur: _messageDisplay.actionCur,
    progressDeterminate: _messageDisplay.progressDeterminate,
    errorsList: undefined,
    messageType: _messageDisplay.messageType
  };
  this.messageDialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);
  
  this.messageDialogRef.afterClosed()
  .pipe(takeUntil(this.onDestroy))
  .subscribe(
    data => {
      // data: "okAction"
      if (data === 'okAction') {
        if (_messageDisplay.actionCur === 'verifyStockParts') {
          this.endProcessFile();
        }
        if (_messageDisplay.actionCur === 'verifyStockPartsDone') {
          this.endProcessFile();
        }
        if (_messageDisplay.actionCur === 'verifyStockPartsFailed') {
          this.endProcessFile();
        }

      }
    });
  }
  closeMatDialog(): void {
    this._dialog.closeAll();

    // console.log(this._dialog.openDialogs.length);
    if (this._dialog.openDialogs.length > 0) {

      for (const instCur of this._dialog.openDialogs) {
        this._dialog.openDialogs.splice(0, 1);
      }
    }
    this.messageDialogRef = null;
  }
  private endProcessFile() {
    this.closeMatDialog();

  }
  closeModal() {
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    } 
  }
}
