<div class="container-fluid stdTheme " style="background-color: transparent!important; height: 650px!important; overflow-y: hidden;">
    <div  *ngIf="!partsIsLoaded">
        <div  class="flex-row row d-flex justify-content-center" style="margin-right: 0px;margin-left: 0px;">
            <div class="col-12 " style="padding-left: 0px;padding-right: 0px;margin-top: 10px;">
                <mat-progress-spinner  [mode]="'indeterminate'"  diameter="40"></mat-progress-spinner>
            </div>
        </div>
        <div  class="flex-row row d-flex justify-content-center " style="margin-right: 0px;margin-left: 0px;margin-top: 50px;">
            <div class="loader-container">
                <div class="loading-text">{{ 'loadingParts' | translate }}<span class="dots"></span></div>
              </div>
        </div>
    </div>
    <div *ngIf="partsIsLoaded && !noParts">
        <div  *ngFor="let orderItem of ordersParts; let iOrder = index">
            <ng-container [ngTemplateOutlet]="orderTemplate" 
                            [ngTemplateOutletContext]="{orderParts:ordersParts[iOrder]}">
            </ng-container>
        </div>
    </div>
    <div *ngIf="partsIsLoaded && noParts">
        <div  class="flex-row row "  style="margin-left: 0px;margin-right: 0px;">
            <div class="col-10 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;margin-top: 10px;">
                <span class="loading-text">
                {{ 'noPartsFounded' | translate }}
                </span>
                
            </div>
        </div>
    </div>
</div>
<ng-template #orderTemplate let-orderParts = "orderParts" >
    <mat-card class="stdTheme" style="padding: 0px;">
        <mat-card-title >
            <div  class="flex-row row "  style="margin-left: 0px;margin-right: 0px;">
                <div class="col-1 d-flex justify-content-center" *ngIf="!isReadOnly" style="padding-left: 0px;padding-right: 0px;margin-top: 10px;">
                    <button mat-icon-button class="buttonTheme" [disabled]="!verifyParts" (click)="verifyStockParts(orderParts.partsRequirement, orderParts.idxOrder, orderParts.nws_order_ref);"
                            style="margin-left: 10px;margin-right: 10px;" title="{{ 'verifyStockParts' | translate }}">
                        <span class="fas fa-redo" style="font-size: 20px" ></span>
                    </button>
                </div>
                <div class="col-10 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;margin-top: 10px;">
                   <span *ngIf="orderParts.nws_order_ref === 'NOT_RESERVED'">
                    {{ 'NOT_RESERVED' | translate }}
                   </span>
                   <span *ngIf="orderParts.nws_order_ref !== 'NOT_RESERVED'">
                    {{ 'BOOKING_REF' | translate }} {{orderParts.nws_order_ref}}
                   </span>
                </div>
                <div class="col-1 d-flex justify-content-center" *ngIf="!isReadOnly" style="padding-left: 0px;padding-right: 0px;margin-top: 10px;">
                    <button mat-icon-button class="buttonTheme" [disabled]="!isOkToReserve" 
                                *ngIf="orderParts.actionOrder === 'reserveStockParts'"
                                (click)="reserveStockParts( orderParts.partsRequirement,  orderParts.idxOrder);"
                                style="margin-left: 10px;margin-right: 10px;" 
                                title="{{ orderParts.actionOrder | translate }}"
                            >
                            <span class="fas fa-warehouse" style="font-size: 20px; color: green"  ></span>
                    </button>
                    <button mat-icon-button class="buttonTheme" [disabled]="isOkToReserve" 
                                *ngIf="orderParts.actionOrder === 'removeOrderParts'"
                                (click)="removeOrderParts(orderParts.idxOrder);"
                                style="margin-left: 10px;margin-right: 10px;" 
                                title="{{ orderParts.actionOrder | translate }}"
                            >
                            <span class="fas fa-warehouse" style="font-size: 20px; color: red" ></span>
                    </button>
                </div>
            </div>
        </mat-card-title> 
        <mat-card-content>
            <div  style="overflow-x: hidden!important; overflow-y: auto!important; height: 500px!important; max-height: 500px!important;" class="w-100" >
                <div class="flex-row row" style="margin-left: 0px; margin-right: 0px;">
                    <div class="col-lg-12 col-xs-12 actionsSelected " >
                        <table class="table tableSupplies" #tableSupplies border="0" cellpadding="0" cellspacing="0" id="tableSupplies"  >
                            <thead >
                                <tr >
                                    <th style="width: 20%; padding: 2px; text-align: center; vertical-align: middle;">{{'suppliesLabel' | translate}}</th>
                                    <th style="width: 10%; padding: 2px; text-align: center; vertical-align: middle;">{{'brandRef' | translate}}</th>
                                    <th style="width: 10%; padding: 2px; text-align: center; vertical-align: middle;">{{'billingUnit' | translate}}</th>
                                    <th style="width: 10%; padding: 2px; text-align: center; vertical-align: middle;">{{'quantity' | translate}}</th>
                                    <th style="width: 10%; padding: 2px; text-align: center; vertical-align: middle;">{{'actionTask' | translate}}</th>
                                    <th style="width: 10%; padding: 2px; text-align: center; vertical-align: middle;" >{{'inStock' | translate}}</th>
                                    <th hidden style="width: 10%; padding: 2px; text-align: center; vertical-align: middle;" >{{'movementStock' | translate}}</th>
                                    <th hidden style="width: 20%; padding: 2px; text-align: center; vertical-align: middle;" >{{'movementStockAction' | translate}}</th>
                                </tr>
                            </thead>
                            
                            <tbody #bodySupplies  id="bodySupplies">
                                <tr class="curActionDisplayed"  *ngFor="let partCur of orderParts.partsRequirement; let iSupplie = index" 
                                            style="margin: 0px; margin-top: 10px;">
                                    <td [attr.data-label]="dataLabelCur[0]" style=" padding: 2px; text-align: left; vertical-align: middle;"
                                            [ngClass]="{greenTheme: partCur.stockStatus === 'OK', 
                                                        amberTheme: partCur.stockStatus === 'KO',
                                                        redTheme: partCur.stockStatus === 'NOT_REFERENCED'}">
                                        <span >
                                            {{partCur.partSupplieLabel}} 
                                        </span>
                                    </td>
                                    
                                    <td [attr.data-label]="dataLabelCur[1]" style=" padding: 2px; text-align: left; vertical-align: middle;">{{partCur.brandRef}}</td>
                                    <td [attr.data-label]="dataLabelCur[2]" style=" padding: 2px; text-align: center; vertical-align: middle;">{{'' + partCur.billingUnit | translate}}</td>
                                    <td [attr.data-label]="dataLabelCur[3]" style=" padding: 2px; text-align: right; vertical-align: middle;"
                                                    [ngClass]="{greenTheme: partCur.stockStatus === 'OK', 
                                                    amberTheme: partCur.stockStatus === 'KO',
                                                    redTheme: partCur.stockStatus === 'NOT_REFERENCED'}">
                                        {{partCur.quantity  | formatNumber : ['quantity',numberFormat.locale,numberFormat.minDecimal, numberFormat.maxDecimal ]}}
                                    </td>
            
                                    <td *ngIf="partCur.actionTask !== undefined" [attr.data-label]="dataLabelCur[4]" style="width: 5%; padding: 2px; text-align: center; vertical-align: middle;">
                                                            {{'' + partCur.actionTask | translate}}</td>
                                    <td *ngIf="partCur.actionTask === undefined"[attr.data-label]="dataLabelCur[4]" style="width: 5%; padding: 2px; text-align: center; vertical-align: middle;">
                                                            {{'changed' | translate}}</td>
                                    
                                    <td  [attr.data-label]="dataLabelCur[5]" style=" padding: 2px; vertical-align: middle;"
                                                    [ngClass]="{greenTheme: partCur.stockStatus === 'OK', 
                                                    amberTheme: partCur.stockStatus === 'KO',
                                                    redTheme: partCur.stockStatus === 'NOT_REFERENCED'}" >
                                        {{partCur.inStock  | formatNumber : ['quantity0',numberFormat.locale,numberFormat.minDecimal, numberFormat.maxDecimal ]}}
                                    </td>
                                    <td hidden  [attr.data-label]="dataLabelCur[6]" style=" padding: 2px; vertical-align: middle;" >
                                        {{partCur.movementStock  | formatNumber : ['quantity0',numberFormat.locale,numberFormat.minDecimal, numberFormat.maxDecimal ]}}
                                    </td>
                                    <td hidden  [attr.data-label]="dataLabelCur[7]" style=" padding: 2px; vertical-align: middle;" >
                                        <button class="mat-raised-button buttonTheme" (click)="eventStock('inputStock', 1 , iSupplie, partsRequirement, orderIdx)" [disabled]="isReadOnly">
                                            <span>{{'inputSt' | translate}} </span>
                                        </button> 
                                        <span style="margin-left: 10px; margin-right: 10px">
                                        </span>
                                        <button class="mat-raised-button buttonTheme" (click)="eventStock('outputStock', -1 , iSupplie, partsRequirement, orderIdx)" [disabled]="isReadOnly">
                                            <span>{{'outputSt' | translate}} </span>
                                        </button> 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </mat-card-content>
        <!--
        <mat-card-actions style="margin-left: 0px;margin-right: 0px;margin-bottom: 0px;">
            <div class="container-fluid d-flex justify-content-end" style="margin-right: 25px;padding-right: 25px;padding-left: 25px;">
                <button  class="mat-raised-button buttonTheme" [disabled]="!isOkToValid || isReadOnly" style="margin-bottom: 10px;" (click)="validData()" >{{'submit' | translate}}</button>
            </div>
        </mat-card-actions>
        -->
    </mat-card>
</ng-template>