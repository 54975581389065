import { Pipe, PipeTransform } from '@angular/core';
import { ValueService } from 'ag-grid-community';
import { TranslateService } from '../../../services/translate.service'; // our translate service

@Pipe({
  name: 'formatNumber',
  pure: false // add in this line, update value when we change language
})
export class FormatNumberPipe implements PipeTransform {

  constructor(private _translate: TranslateService) { }

  transform(value: number, args: string | string[]): any { // args can be string or string array
      // tslint:disable-next-line:curly
      if (!value) { 
        if ( Array.isArray(args) && ( args[0] !== 'currency' && args[0] !== 'quantity0')) {
          return;
         }
      }
      const regexNum = new RegExp('[^0-9]', 'g');
      // value = value.replace(regexNum, '');
      // console.log('value : ' + value + ' args : ' + JSON.stringify(args));
      const returnValue = this._translate.instantNumber(value, args); // pass in args
      return returnValue;
  }

}
