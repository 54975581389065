<div class="container-fluid" *ngIf="isformOK" style="width: 100%;margin-bottom: 40px">
    <mat-card appMaterialElevation class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px;">
        <mat-card-actions style="margin-bottom: 0px;">
            <!--<div class="container-fluid d-flex justify-content-between" >-->
                <div class="flex-row row"  style="margin-left: 5px;margin-right: 5px;">
                    <div class="col-2 d-flex justify-content-start" >
                        <button style="margin-top: 5px;" class="mat-raised-button buttonTheme" 
                            [disabled]="!isOkToSave" style="margin-bottom: 10px;" (click)="validData()" >{{'submit' | translate}}
                        </button>
                    </div>
                </div>
            <!--</div>-->
        </mat-card-actions>
        <mat-card-content class="stdTheme" style="padding-bottom: 20px;overflow-y: auto;overflow-x: hidden; height: 550px; max-height: 550px">
            <form [formGroup]="apiContentForm"  style="margin: 0px; height: 100%; width: 100%;" >
                <fieldset class="server-border " style="margin-top: 0px; margin-bottom: 10px">
                    <legend class="server-border"  ><b>{{ 'toolserver' | translate }}</b></legend>
                    <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                        <div class="col-5 d-flex justify-content-start">
                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                <mat-label>{{ 'toolName' | translate }}</mat-label>
                                <mat-select class="stdTheme"  panelClass="panelSelectContent" #toolName  
                                        formControlName="toolNameFg"
                                        disableOptionCentering required
                                        id="toolName"
                                        required  
                                        [value]="apiContentCache.toolName"
                                        (selectionChange)="onChangeToolApis(toolName.value, 'toolName')" >
                                  <mat-option class="stdTheme stdSelect" *ngFor="let toolApi of toolsApis" 
                                        class="selectOptionsTheme"  [value]="toolApi.toolName" >
                                                {{toolApi.toolName }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                        <div class="col-5 d-flex justify-content-start">
                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                <mat-label>{{ 'toolVersion' | translate }}</mat-label>
                                <mat-select class="stdTheme"  panelClass="panelSelectContent" #toolVersion  
                                        formControlName="toolVersionFg"
                                        disableOptionCentering required
                                        id="toolVersion"
                                        required  
                                        [value]="apiContentCache.toolVersion"
                                        (selectionChange)="onChangeToolApis(toolVersion.value, 'toolVersion')" >
                                  <mat-option class="stdTheme stdSelect" *ngFor="let toolApiVersion of toolApiVersions" 
                                        class="selectOptionsTheme"  [value]="toolApiVersion.toolVersion" >
                                                {{toolApiVersion.toolVersion }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                        <div class="col-2 d-flex justify-content-start">
                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                <mat-label>{{ 'toolProtocol' | translate }}</mat-label>
                                <input matInput  class="form-control inputTheme" formControlName="toolProtocolFg"  required >
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;margin-bottom: 15px;">
                        <div class="col-5 d-flex justify-content-start">
                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                <mat-label>{{ 'toolServerUrl' | translate }}</mat-label>
                                <input matInput  class="form-control inputTheme" formControlName="toolServerUrlFg" required >
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                        <div class="col-3 d-flex justify-content-start">
                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                <mat-label>{{ 'toolCustomerNwsId' | translate }}</mat-label>
                                <input matInput  class="form-control inputTheme" formControlName="toolCustomerNwsIdFg" required >
                            </mat-form-field>
                        </div>
                        <div class="col-3 d-flex justify-content-start">
                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                <mat-label>{{ 'toolCustomerNwsIdField' | translate }}</mat-label>
                                <input matInput  class="form-control inputTheme" formControlName="toolCustomerNwsIdFieldFg" required >
                            </mat-form-field>
                        </div>
                        <div class="col-3 d-flex justify-content-start">
                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                <mat-label>{{ 'toolCustomerNwsCode' | translate }}</mat-label>
                                <input matInput  class="form-control inputTheme" formControlName="toolCustomerNwsCodeFg" required >
                            </mat-form-field>
                        </div>
                        <div class="col-3 d-flex justify-content-start">
                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                <mat-label>{{ 'toolCustomerNwsCodeField' | translate }}</mat-label>
                                <input matInput  class="form-control inputTheme" formControlName="toolCustomerNwsCodeFieldFg" required >
                            </mat-form-field>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="server-border" style="margin-top: 0px; margin-bottom: 10px">
                    <legend class="server-border"  ><b>{{ 'authentification' | translate }}</b></legend>
                    <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                        <div class="col-12 d-flex justify-content-center">
                            <mat-radio-group class="taskAction-radio-group" 
                                #toolAuthentification="matRadioGroup"  aria-label="Select an option" labelPosition="before"
                                    style="margin-top: 0px;margin-bottom: 0px; padding-left: 25px;"
                                    formControlName="toolAuthentificationFg"
                                    [(ngModel)]="toolAuthentificationOpt"
                                    (change)="changeToolAuthentification(toolAuthentification.value)">
                                <mat-radio-button class="radioButton" 
                                        style="padding-right: 15px;" value="tooluser">{{ 'tooluser' | translate }}</mat-radio-button>
                                <mat-radio-button class="radioButton"  
                                        value="toolkey">{{ 'toolkey' | translate }}</mat-radio-button>
                            </mat-radio-group> 
                        </div>
                    </div>

                    <div class="flex-row row" *ngIf="toolAuthentificationOpt === 'tooluser'" style="margin-left: 0px;margin-right: 0px;margin-bottom: 15px;">
                        <div class="col-5 d-flex justify-content-start">
                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                <mat-label>{{ 'toolusername' | translate }}</mat-label>
                                <input matInput  class="form-control inputTheme" formControlName="usernameFg"  >
                            </mat-form-field>
                        </div>
                        <div class="col-5 d-flex justify-content-start">
                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                <mat-label>{{ 'toolpwd' | translate }}</mat-label>
                                <input matInput  class="form-control inputTheme" formControlName="pwdFg"  >
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="flex-row row" *ngIf="toolAuthentificationOpt === 'toolkey'" style="margin-left: 0px;margin-right: 0px;margin-bottom: 15px;">
                        <div class="col-5 d-flex justify-content-start">
                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                <mat-label>{{ 'toolkeyname' | translate }}</mat-label>
                                <input matInput  class="form-control inputTheme" formControlName="toolkeynameFg"  >
                            </mat-form-field>
                        </div>
                        <div class="col-5 d-flex justify-content-start">
                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                <mat-label>{{ 'toolkeyvalue' | translate }}</mat-label>
                                <input matInput  class="form-control inputTheme" formControlName="toolkeyvalueFg"  >
                            </mat-form-field>
                        </div>
                    </div>

                </fieldset>
                <fieldset class="server-border" style="margin-top: 0px; margin-bottom: 10px" >
                    <legend class="server-border"  ><b>{{ 'args' | translate }}</b></legend>
                    <div formArrayName="toolArgsFa">
                        <div  class="stdTheme" *ngFor="let argCur of apiContentForm.get('toolArgsFa').controls; let idxArg = index" style="margin-left: 10px; margin-right: 10px" >
                            <div class="flex-row row d-flex align-items-center rowToolservice " [formGroupName]="idxArg" >
                                <div class="col-5 d-flex justify-content-start">
                                    <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                        <mat-label>{{ 'headerKey' | translate }}</mat-label>
                                        <input matInput  class="form-control inputTheme" formControlName="keyFg" 
                                                (change)="onToolArgsChange('key', $event.target.value, idxArg)" >
                                    </mat-form-field>
                                </div>
                                <div class="col-5 d-flex justify-content-start">
                                    <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                        <mat-label>{{ 'headerValue' | translate }}</mat-label>
                                        <input matInput  class="form-control inputTheme" formControlName="valueFg"
                                                (change)="onToolArgsChange('value', $event.target.value, idxArg)"  >
                                    </mat-form-field>
                                </div>
                                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">                  
                                    <button mat-icon-button  
                                            (click)="addRowToolArgs(idxArg)" [disabled]="isReadOnly"
                                            data-toggle="tooltip" data-placement="top" title="{{ 'add tooltip' | translate }}">
                                        <span class="fa fa-plus" style="font-size: 15px;padding-top: 30px;" aria-hidden="true"></span>
                                    </button>
                                </div>
                                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">                  
                                    <button  mat-icon-button   
                                                (click)="removeRowToolArgs(idxArg)" 
                                                data-toggle="tooltip" data-placement="top"
                                                title="{{'delete row' | translate }}"
                                                [disabled]="isReadOnly">
                                        <span class="fa fa-trash" style="font-size: 15px;padding-top: 30px;" aria-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="server-border" style="margin-top: 0px; margin-bottom: 10px" >
                    <legend class="server-border"  ><b>{{ 'headers' | translate }}</b></legend>
                    <div formArrayName="toolHeadersFa">
                        <div  class="stdTheme" *ngFor="let headerCur of apiContentForm.get('toolHeadersFa').controls; let idxHeader = index" style="margin-left: 10px; margin-right: 10px" >
                            <div class="flex-row row d-flex align-items-center rowToolservice " [formGroupName]="idxHeader" >
                                <div class="col-5 d-flex justify-content-start">
                                    <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                        <mat-label>{{ 'headerKey' | translate }}</mat-label>
                                        <input matInput  class="form-control inputTheme" formControlName="keyFg" 
                                                (change)="onToolHeadersChange('key', $event.target.value, idxHeader)"  >
                                    </mat-form-field>
                                </div>
                                <div class="col-5 d-flex justify-content-start">
                                    <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                        <mat-label>{{ 'headerValue' | translate }}</mat-label>
                                        <input matInput  class="form-control inputTheme" formControlName="valueFg" 
                                                (change)="onToolHeadersChange('value', $event.target.value, idxHeader)" >
                                    </mat-form-field>
                                </div>
                                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">                  
                                    <button mat-icon-button  
                                            (click)="addRowToolHeaders(idxHeader)" [disabled]="isReadOnly"
                                            data-toggle="tooltip" data-placement="top" title="{{ 'add tooltip' | translate }}">
                                        <span class="fa fa-plus" style="font-size: 15px;padding-top: 30px;" aria-hidden="true"></span>
                                    </button>
                                </div>
                                <div class="col-1 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">                  
                                    <button  mat-icon-button   
                                                (click)="removeRowToolHeaders(idxHeader)" 
                                                data-toggle="tooltip" data-placement="top"
                                                title="{{'delete row' | translate }}"
                                                [disabled]="isReadOnly">
                                        <span class="fa fa-trash" style="font-size: 15px;padding-top: 30px;" aria-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                
                <fieldset class="server-border " style="margin-top: 0px; margin-bottom: 10px">
                    <legend class="server-border"  ><b>{{ 'toolauth2' | translate }}</b></legend>

                    <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;margin-bottom: 0px;">
                        <div class="col-12 d-flex justify-content-center">
                            <mat-checkbox class="activateJwt" color="warn" #activateJwtFg 
                                        formControlName="activateJwtFg"  
                                [labelPosition]="before"
                                (change)='activateJwtChange(activateJwtFg.checked)' 
                                [checked]='activateJwtChecked'>
                                    <div class="text-wrap">
                                        {{ 'activateJwt' | translate }}
                                    </div>
                            </mat-checkbox> 
                        </div>
                    </div>

                    <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;margin-bottom: 15px;">
                        <div class="col-6 d-flex justify-content-start">
                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                <mat-label>{{ 'toolJwtServerUrl' | translate }}</mat-label>
                                <input matInput  class="form-control inputTheme" formControlName="toolJwtServerUrlFg" required 
                                         >
                            </mat-form-field>
                        </div>
                        <div class="col-4 d-flex justify-content-start">
                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                <mat-label>{{ 'toolJwtServerArgs' | translate }}</mat-label>
                                <input matInput  class="form-control inputTheme" formControlName="toolJwtServerArgsFg"  
                                       >
                            </mat-form-field>
                        </div>
                    </div>
                </fieldset>

                <fieldset *ngIf="isOkToTest" class="tests-border " style="margin-top: 0px; margin-bottom: 10px">
                <!--<fieldset class="tests-border " style="margin-top: 0px; margin-bottom: 10px">-->
                    <legend class="tests-border"  ><b>{{ 'testsApi' | translate }}</b></legend>
                    <div class="flex-row row d-flex align-items-center  " style="margin-left: 10px; margin-right: 10px">
                        <div class="col-3 d-flex justify-content-center">
                            <span><b>{{ 'service' | translate }}</b></span>
                        </div>
                        <div class="col-2 d-flex justify-content-center">
                            <span><b>{{ 'statut'  | translate}}</b></span>
                        </div>
                        <div class="col-3 d-flex justify-content-center">
                            <span><b>{{ 'dateTest'  | translate}}</b></span>
                        </div>
                        <div class="col-1 d-flex justify-content-center">
                               
                        </div>
                        <div class="col-3 d-flex justify-content-center">
                            <button mat-icon-button class="buttonTheme"  (click)="refreshTest()" 
                                    style="margin-left: 10px;margin-right: 10px;">
                                <span class="fas fa-redo" style="font-size: 20px" ></span>
                            </button>         
                        </div>
                    </div>
                    <div  class="stdTheme" *ngFor="let toolTest of toolTests; let idxTests = index" style="margin-left: 10px; margin-right: 10px" >
                        <div class="flex-row row d-flex align-items-center rowTests " [ngClass]="{'rowTestOdd':oddEven(idxTests),'rowTestEven':!oddEven(idxTests)}" >
                            <div class="col-3 d-flex justify-content-start">
                                <span style="margin-right: 10px"><b>{{ toolTest.menuTest}}</b></span>
                                <span>{{ toolTest.apiService | translate }} </span>
                            </div>
                            <div class="col-2 d-flex justify-content-center"
                                [ngClass]="{'greenTheme':toolTest.statut === 'OK' ,
                                            'redTheme':toolTest.statut !== 'OK' && toolTest.statut !== 'NT',
                                            'amberTheme':toolTest.statut === 'NT'}">
                                <span>{{ toolTest.statut}} </span>
                            </div>
                            <div class="col-3 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                <span>{{ displayDate(toolTest.testDate)}} </span>
                            </div>
                            <div class="col-1 d-flex justify-content-start">
                               
                            </div>
                            <div class="col-3 d-flex justify-content-center">
                                <button *ngIf="toolTest.authorised" mat-raised-button  style="height: 30px!important;" 
                                        [disabled]="disableTest || !toolTestOK[idxTests]"
                                         (click)="testApi(toolTest.apiService, idxTests)">
                                    {{ 'Test' | translate }} {{ toolTest.apiService | translate }}
                                </button>
                                <button *ngIf="!toolTest.authorised" mat-raised-button  style="height: 30px!important;" 
                                        [disabled]="true"
                                        >
                                    {{ 'notAuthorised' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </mat-card-content>
    </mat-card>
</div>
