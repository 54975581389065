/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 * /*
Start JLG - 2021-05-28 - "date-fns": "^2.22.0", :
cette version de la date-fns comporte un bug, il faut effacer des lignes dans le module typings.d.ts dans les declaration de type pour les variables :
  const daysInWeek: number
  const maxTime: number.
  Elles sont en double.
End JLG - 2021-05-28
github source :
  https://github.com/mattlewis92/angular-calendar/tree/master/projects/angular-calendar
module inspiré de l'exemple suivant :
  https://stackblitz.com/edit/angular-calendar?file=demo%2Ftemplate.html
pour la langue  :
  https://stackblitz.com/edit/angular-6su8pq
autres exemples :
  https://github.com/mattlewis92/angular-calendar
  https://angular-calendar.com/#/kitchen-sink

  https://stackblitz.com/edit/angular-ukwpj8?file=demo%2Ftemplate.html
Personnalisation :
  https://angular-calendar.com/docs/components/CalendarMonthViewComponent.html#source
  https://stackblitz.com/edit/angular-hn2scb?file=demo%2Ftemplate.html
@input template :
  https://blog.angulartraining.com/how-to-pass-a-custom-template-to-an-angular-component-53592d634a47

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
var styles = ["h3{margin:0 0 10px}pre{color:var(--background-primary)!important;background-color:var(--foreground-primary)!important;padding:15px}.cell-border{border:.2px solid!important}::ng-deep .localTheme{color:var(--foreground-primary)!important;background-color:var(--background-primary)!important;border:.2px solid!important}::ng-deep .localTheme:hover{color:var(--foreground-tertiary)!important;background-color:var(--background-tertiary)!important;border:.2px solid!important}::ng-deep .cal-open-day-events .cal-events{color:var(--foreground-primary)!important;background-color:var(--background-primary)!important;border:.2px solid!important}.cal-day-columns,.cal-day-headers,.cal-header,.cal-hour{background-color:var(--background-primary)!important;color:var(--foreground-primary)!important;border:.2px solid!important}::ng-deep [role=columnheader]{color:var(--foreground-tertiary)!important;background-color:var(--background-tertiary)!important;border:.2px solid!important}::ng-deep [role=columnheader]:hover{color:var(--foreground-primary)!important;background-color:var(--background-primary)!important;border:.2px solid!important}.todayLoc{background-color:var(--background-data-selected)!important}.timepicker-backdrop-overlay,.timepicker-overlay{z-index:10000000!important}.modal-content,.modal-dialog{height:85%!important;width:95%!important}.modal-body{min-height:calc(100% - 120px)!important;overflow-y:auto!important;overflow-x:auto!important}.wksInput .mat-form-field-wrapper{margin-bottom:-1.25em}"];
export { styles as styles };
