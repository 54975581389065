import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';

import { UserService } from './user.service';
import { TranslateService } from './translate.service';
import { ThemeService } from './theme.service';
import { WksCentralService } from '../job/job-wks/services/wks-central.service';
import { RessourcesService } from '../job/common/services/ressources.service';
import { RemoteFilesService } from '../job/common/services/remote-files.service';
import { CalendarService } from '../job/common/services/calendar.service';
import { SchedulerService } from './scheduler.service';
import { CmdService } from './cmd.service';
import { WksManufacturersService } from '../job/job-wks/services/wks-manufacturers.service';

import { UserLoggedModel, EntityModel } from '../models/user.model';
import { ToolApiEntity } from '../models/common.model';


@Injectable()
export class AuthService {

  userLogged: UserLoggedModel;
  entityLogged: EntityModel;
  workshop: boolean;

  constructor(private http: HttpClient,
              private router: Router, 
              private _userService: UserService, 
              private _ressourceService: RessourcesService,
              private _remoteFilesService: RemoteFilesService,
              private _wksCentralService: WksCentralService,
              private _calendarService: CalendarService,
              private _schedulerService: SchedulerService,
              private _wksManufacturersService: WksManufacturersService,
              private _cmdService: CmdService) {
                this.workshop = environment.workshop;
              }
// loadEnvironment(paramValues: Map<String, any>, forcedEntity: string): boolean  {  
  loadEnvironment(userArg: UserLoggedModel, forcedEntity: string): boolean  {  

    const isMaster =  userArg.isMaster;
    userArg.entity = forcedEntity && isMaster ? forcedEntity : userArg.entity;
    this._userService.userCur.entity = userArg.entity;
    /*
    const entityLogged = paramValues.get('entity') as string;
    // const isMaster = entityLogged.startsWith('nautiko') ? true : false;
    // const isMaster = paramValues.get('entityMaster') === 'true' ? true : false;
    const additionalInfo = new Map(Object.entries(paramValues.get('additionalInfo')));
    const isMaster = additionalInfo.get('entityMaster') as boolean;

    userArg = {
      id: paramValues.get('id') as number,
      username: paramValues.get('username') as string,
      firstname: paramValues.get('firstName') as string,
      lastname: paramValues.get('lastName') as string,
      email: paramValues.get('email') as string,
      entity: forcedEntity && isMaster ? forcedEntity : paramValues.get('entity') as string,
      profile: paramValues.get('profile') as string,
      datafilter: paramValues.get('datafilter') as string,
      authorities: paramValues.get('authorities') as string,
      usergroup: paramValues.get('usergroup') as string,
      applications: paramValues.get('applications') as string,
      isMaster: isMaster,
    };
    */
    let authenticationFailed: boolean;
    this.getMenu()
    .then ( (menuListItems) => {
      if (!this.workshop && (userArg.applications !== 'ALL' || userArg.applications.indexOf('nautiko') === -1  )) {
        this.router.navigate(['/jobHome']);
      } else {
      /*---- Start WKS ----------------*/
        this._wksCentralService.setHeaders(this._userService.getHeaders());
        this._calendarService.setHeaders(this._userService.getHeaders());
        this._schedulerService.setHeaders(this._userService.getHeaders());
        this._wksManufacturersService.setHeaders(this._userService.getHeaders());
        if (isMaster) {
          this._cmdService.setHeaders(this._userService.getHeaders());
        }
        this.loadCountries();
        // this.loadCurrencies();
        this.loadContentsMime();
        // this.loadWksParams();
        this.loadWksEntity(userArg.entity)
        .then ((entityCur: any) => {
          const otherData = JSON.parse(entityCur.otherData);
          const activityCur = (otherData.activity !== undefined ? otherData.activity : 'workshop');  
          entityCur.activity = activityCur;
          if (entityCur.entityOptions !== undefined && entityCur.entityOptions !== null) {
            const entityOptions = JSON.parse(entityCur.entityOptions);
            if (entityOptions.notificationsApp !== undefined) {
              this._ressourceService.setNotifApp(entityOptions.notificationsApp);
            } else {
              this._ressourceService.setNotifApp(undefined);
            }
            if (entityOptions.APIS !== undefined) {
               const apisEntity = entityOptions.APIS as ToolApiEntity[];
               this._wksCentralService.setApisEntity(apisEntity);
            }
          }
          this._wksCentralService.setWksEntityCur(entityCur);

          this.loadCountryFmt(entityCur.internationalFormat)
          .then ((loadCountryFmt: any) => {
            // this.countryFmt = JSON.parse(loadCountryFmt.body);
            this._ressourceService.setCountryFmt(loadCountryFmt.body);
          });
          this.loadWksParams()
          .then ((wksParams: any) => {
            this._wksCentralService.setWksParams(wksParams, entityCur.activity);
            this._ressourceService.setPhonesType(this._wksCentralService.getPhonesType());
          });  
          // if (entityCur.stdEntity !== 'nautikoNWS') { 
          if (!isMaster) { 
            this.loadEntityParams(entityCur.stdEntity)
            .then ((responseParams: any) => {
              this._ressourceService.setWksEntityParams(responseParams.body); 
            });   
          }
          this.loadLocalDef(entityCur.entityCountry)
          .then ((loadLocalDef: any) => {
            const localDef = loadLocalDef.body;
            this._ressourceService.setCurrencies(localDef.currencies);
            this._wksCentralService.getWksEntityCur().localAddress = localDef.localAddress;
            this._wksCentralService.getWksEntityCur().telFmt = localDef.telephoneDef;
            this._wksCentralService.getWksEntityCur().mooring = localDef.mooring;
            this._wksCentralService.getWksEntityCur().wintering = localDef.wintering;
            this._wksCentralService.getWksEntityCur().locales = localDef.locales;
            this.loadIntFormats();
        
            this.getWksMenu()
              .then ( (menuWksListItems) => {
                this.router.navigate(['/jobHome']);
                }, (error) => {
                // console.log('getMenu : ', error);
                authenticationFailed = true;
              });
          });
        }, () => {
          // console.log('getMenu : ', error);
          authenticationFailed = true;

        }); // loadWksEntity
        
      }
      /*---- end WKS --------*/
    }, (error) => {
    // console.log('getMenu : ', error);
    authenticationFailed = true;

  });
  return authenticationFailed;
  }
  private loadCountries(): void  {
    this._remoteFilesService.loadCountries()
    .subscribe(
      data => {
        const countries = data.body;
        // console.log('loadCountries ' + JSON.stringify(countries));
        this._ressourceService.setCountries(JSON.stringify(countries));
        this._wksCentralService.loadAnchorages()
        .then ((anchorageList: any) => {
          
        }) // end loadAnchorages
        .catch((error: any) => {
          console.log('loadAnchorages : ' + error.message);
          return;
        }); // end loadAnchorages    
      },
      err =>  {
        console.log('loadCountries ' + err);
      }
    );
  }
  
  private loadLocalDef(countryKey: string): any {
    return new Promise((resolve, reject) => {
      this._wksCentralService.loadLocalDef(countryKey)
      .subscribe(
        data => {
          const response = data;
          resolve(response);
        },
        err => {
          console.log('loadLocaldef' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
  private loadContentsMime(): void {
    this._wksCentralService.loadContentsMime()
    .subscribe(
      data => {
        const contentsMime = data.body ;
        // console.log('loadCountries ' + JSON.stringify(countries));
        // this._wksCentralService.setContentsMime(JSON.stringify(contentsMime));
        this._wksCentralService.setContentsMime(contentsMime);
        // console.log('Menu ' + JSON.stringify(menuListItems));            
      },
      err =>  {
        console.log('loadContentsMime ' + err);
      }
    );
  }
  private loadWksParams(): any {
    return new Promise((resolve, reject) => {
      this._wksCentralService.loadWksParams()
      .subscribe(
        data => {
          const wksParams = data.body;
          // console.log('loadCountries ' + JSON.stringify(countries));
          // this._wksCentralService.setWksParams(wksParams);
          resolve(wksParams);
        },
        err =>  {
          console.log('loadWksParams ' + err);
          reject(err);
        }
      );
    });
  }
  private loadWksEntity(entity: string): any {
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksEntity(entity)
      .subscribe(
        data => {
          const wksEntity = data.body;
          resolve(wksEntity);
          // this._wksCentralService.setWksEntityCur(wksEntity);        
        },
        err =>  {
          console.log('loadWksEntity ' + err);
          reject(err);
        }
      );
    });
  }
private loadCountryFmt(countryKey: string): any {
    return new Promise((resolve, reject) => {
      this._wksCentralService.loadCountryFmt(countryKey)
      .subscribe(
        data => {
          const response = data;
          resolve(response);
        },
        err => {
          console.log('loadCountryFmt' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
private loadEntityParams(entityCur: string): any {
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksEntityParams(entityCur)
      .subscribe(
        data => {
          const response = data ;
          resolve(response);
        }, err => {
          console.log('loadEntityParams' + err.message);
          if (err.status === 404) {
          }
          reject(err.status);
        }
      );
    });
  }
private loadIntFormats(): any {
    this._remoteFilesService.loadIntFormats()
    .subscribe(
      data => {
        const formatsResponse = data.body;
        this._ressourceService.setIntFormats(formatsResponse);       
      },
      err =>  {
        console.log('loadIntFormats ' + err);
      }
    );
  }
  
private getMenu(): any {
    return new Promise((resolve, reject) => {
      this._userService.getMenu()
      .subscribe(
        data => {
          const menuListItems = data.body;
          // console.log('Menu ' + JSON.stringify(menuListItems));
          this._userService.setMenuItems(JSON.stringify(menuListItems));
          resolve(menuListItems);
        },
        err => reject(err)
      );
    });
  }
private getWksMenu(): any {
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksMenu()
      .subscribe(
        data => {
          const menuListItems = data.body;

          // console.log('Menu ' + JSON.stringify(menuListItems));
          this._wksCentralService.setWksMenuItems(JSON.stringify(menuListItems));
          resolve(menuListItems);
        },
        err => reject(err)
      );
    });
  }
}
