import { Component, OnInit, Input, ViewEncapsulation, ViewChild, ElementRef, TemplateRef, Output,  SimpleChanges, OnChanges, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig, MatTabChangeEvent, MatRadioChange, throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { CoordinatesService, TransformationType, Direction} from 'angular-coordinates';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';


import { GlobalEventsManagerService } from '../../../../../common/services/global-events-manager.service';
import { GridTools } from '../../../../../common/tools/gridTools';
import { environment } from '../../../../../../../environments/environment';
import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';

import { WksCentralService } from '../../../../services/wks-central.service';
import { WksWorksService } from '../../../../services/wks-works.service';
import { CalendarService } from '../../../../../common/services/calendar.service';

import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';
import { WorksPdfComponent } from '../works-pdf/works-pdf.component';

import { WksBusinessModel, WksContactsModel, PaiementsTerm, AccountModel } from '../../../../models/wks-business.model';
import { WksEquipmentModel, EquipItemModel, RevisionModel } from '../../../../models/wks-equipment.model';
import { WksWorksModel, WksWorksTracking, WksAlarmMessage, WksWorksDocs, WorkQuotation, Payment, DataWorkModel, WorkInputEvent, WksWorksRecapAmountModel } from '../../../../models/wks-works.model';
import { ParamActionsrefModel, ParamWorksRatesModel, TasksActionsModel, 
          WksTasksModel, ParentTask, TasksActionsContentModel, WorkStatutModel, IhmOptions, 
          PartsRequirement} from '../../../../models/wks-param.model';
import { DataUpstream, TypesLabel} from '../../../../models/wks-common.model';
import { WksWorksInterventionModel, WorkEquipmentRef, WksWorksInterventionEvent, WorkEquipments, DataLocationWorkModel, PhotoData } from '../../../../models/wks-works.model';

import { FileToDisplay } from '../../../../../../models/common.model';
import { ContactInt, PhoneInput, EmailInput, GoogleMaps, LatLng, LabelValue } from '../../../../../../models/data.model';




@Component({
  selector: 'mdi-works-card',
  templateUrl: './works-card.component.html',
  styleUrls: ['./works-card.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    // A voir : https://stackblitz.com/edit/mat-datepicker-for-period?file=src%2Fapp%2Fviews%2Fadd-period%2Fadd-period.component.ts

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})

export class WorksCardComponent implements OnChanges {

  @Input() statutCall: any;
  @Input() actionType: string;
  @Input() dataIn: DataWorkModel;

  @Output() dataOut = new EventEmitter<any>();
  private readonly onDestroy = new Subject<void>();

  rowTask: number;
  ownerCur: WksBusinessModel;
  ownerCache: WksBusinessModel;
  accountCur: AccountModel;
  equipBoatCur: WksEquipmentModel;
  workEquipments: WorkEquipments;
  contactsWorkList: WksContactsModel[];
  dataLocationEquip: DataLocationWorkModel[];
  dataContacts: ContactInt[];
  entityCur: string; 
  equipItem: EquipItemModel;
  wksWorks: WksWorksModel;
  workStatut: WorkStatutModel;
  wksWorkIntervention: WksWorksInterventionModel;

  ownerIsLoaded: boolean;
  ownerChoice: boolean;
  ownerSelect: boolean;
  ownerNew: boolean;

  openedRight: boolean;
  openedLeft: boolean;
  uploadDocs: boolean;
  uploadPhotos: boolean;
  displayPhotos: boolean;
  displayImages: boolean;
  displayPdf: boolean;
  displayList: boolean;
  displayQuotesList: boolean;
  okOwner: boolean;
  filesToDisplay: FileToDisplay[];
  wksOthersWorks: LabelValue[];
  wksOthersWorksLoaded: boolean;
  workCodeList: LabelValue[];
  isOkToSave: boolean;

  workTasksContent: WksTasksModel[];
  workTasksContentCache: WksTasksModel[];

  wksWorksTracking: WksWorksTracking[];
  wksQuotesValidated: WksWorksDocs [];
  wksQuotesApproved: WksWorksDocs [];
  wksQuotesRefused: WksWorksDocs [];
  wksQuotesNew: WksWorksDocs [];
  statusModal: string;
  allAvailable: boolean;
  listQuotes: {
    name: string,
    list: WksWorksDocs []
  } [];


  displayInvoicesList: boolean;
  listInvoices: {
    name: string,
    list: WksWorksDocs []
  } [];
  updateStatut: string;


  googleMaps: GoogleMaps;
  isGeoLocation: boolean;

  dataUpstream: DataUpstream;
  selectedDoc: number;
  selectedQuote: number;
  selectedInvoice: number;
  selectedCnt: number;
  doDisplayEmail: boolean;
  doDisplayPhone: boolean;
  phonesList: PhoneInput[];
  emailsList: EmailInput[];


  // customers list

  customerList: WksBusinessModel[];
  customerFiltredList: WksBusinessModel[];
  customerCacheList: WksBusinessModel[];
  customersLoading: boolean;
  customersLoaded: boolean;
  bsnStatut: string;
  changeOwner: boolean;
  changeEquipment: boolean;
  openIntervention: boolean;
  openEquipment: boolean;
  openTasks: boolean;
  workEquipmentId: WorkEquipmentRef;
  workEquipmentIdCache: WorkEquipmentRef;

  ownerTabOptions: IhmOptions;
  equipmentTabOptions: IhmOptions;
  interventionTabOptions: IhmOptions;
  tasksTabOptions: IhmOptions;
  dispatchTabOptions: IhmOptions;
  docsTabOptions: IhmOptions;
  statusJournalTabOptions: IhmOptions;
  planningOptions: IhmOptions;
  quoteTabOptions: IhmOptions;
  checkListTabOptions: IhmOptions;
  outputStockTabOptions: IhmOptions;
  invoicingTabOptions: IhmOptions;
  balanceTabOptions: IhmOptions;
  photosTabOptions: IhmOptions;
  ihmOptionsCkeck: IhmOptions;
  workTitle: string;

  // quotation /invoice
  recapAmountData: WksWorksRecapAmountModel;
  invoiceTabDisplay: number;

  // photos
  tasksList: LabelValue[] = [] ;

  photoData: PhotoData;
  photoDataBase64: string;
  sequenceAttRef: string[] = ['BEFORE', 'DURING', 'AFTER'] ;
  sequenceAttList: LabelValue[];
  formPhotoIsBuild: boolean;
  photoDataForm: FormGroup;

  constructor(private fb: FormBuilder,
      private _userService: UserService, 
      private _translate: TranslateService,
      private _wksCentralService: WksCentralService,
      private _wksWorksService: WksWorksService,
      private _globalEventsManagerService: GlobalEventsManagerService,
      private _dialog: MatDialog,
      private toastr: ToastrService,
      private adapterDate: DateAdapter<any>,
      private _coordinatesService: CoordinatesService) { }

  ngOnChanges(changes: SimpleChanges) {
     
    const listKey = Object.keys(changes);

    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          } // end case
           // actionType
          case 'actionType': {
            this.actionType = changes['actionType'].currentValue;
            break;
          } // end case
          // dataIn
          case 'dataIn': {
            this.dataIn = changes['dataIn'].currentValue;
            break;
          } // end case
        }  // end switch
      } // end if
    }// end loop
    this.initData();
  }
  initData() {

    // console.log(this.envCur.rigthClickEvent);
    this.getUserLocation();
    this.initAllBooleans();
  
    this.initGlobalsParameters();
    this.initAllSubscribes();
    this.loadListsParameters();

    this.wksQuotesNew = [];
    this.wksQuotesValidated = [];
    this.wksQuotesApproved = [];
    this.wksQuotesRefused = [];
    this.listQuotes = [];

    this.entityCur = this._userService.getUserLogged().entity;
    if ((this.dataIn !== undefined) && (this.dataIn.work !== undefined)) { // call from work list
      this.wksWorks = JSON.parse(JSON.stringify(this.dataIn.work));
 
      if (this.wksWorks.workEquip)  {
        this.workEquipmentId = JSON.parse(this.wksWorks.workEquip);
        this.readEquipments();
      }
      if (this.wksWorks.workJsonData) {
        const workJsonData = JSON.parse(this.wksWorks.workJsonData);
        if (workJsonData.wksWorksTracking) {
          this.wksWorksTracking = JSON.parse(JSON.stringify(workJsonData.wksWorksTracking));
        }
      }
      this.openEquipment = true;
      this.openIntervention = true;
      this.updateStatut = 'update';
      if (this.wksWorks.workDocs && this.wksWorks.workDocs.length > 0) {
        this.displayList = true;
      } else { 
        this.uploadDocs = true;
      }
      this.initIhmOptions(this.dataIn.work.workStatut);
      this.getOwner(this.dataIn.work.ownerId)
      .then ((ownerReturn: WksBusinessModel) => {
        this.ownerCur = ownerReturn;
        this._wksWorksService.setOwner(this.ownerCur);

        const accountTmp = JSON.parse(this.ownerCur.bsnAccounting) as AccountModel;
        this.accountCur = accountTmp;
        this.accountCur.bsnDelayDays = accountTmp.bsnDelayDays ;
        if (this.accountCur.bsnDefferedPaiement === undefined || this.accountCur.bsnDefferedPaiement === '' ) {
          this.accountCur.bsnDefferedPaiement = 'invoiceEmit';
        }
        this.ownerIsLoaded = true;
        this.ownerChoice = false;
        this._wksWorksService.setWksIntervention(this.wksWorks, this.ownerCur, this.accountCur);
        this.wksWorkIntervention = this._wksWorksService.getWksIntervention();
        this.getWksContactsList(ownerReturn)
        .then(() => {

        });
       
      }); // end customer
      this.readTasksAndDocs();
    } else { // new work
      this.accountCur = {
        bsnCashpay: true,
        bsnAccount: '',
        scaleName: '',
        bsnCoef: 1,
        bsnDelayDays: -1,
        bsnDefferedPaiement: 'invoiceEmit',
        customerOutstanding: 0
      };
      if (this.dataIn !== undefined && this.dataIn.newWork && this.dataIn.newWork.ownerId) { // call from equipement list or equipment card
        this.getOwner(this.dataIn.newWork.ownerId)
        .then ((ownerReturn: WksBusinessModel) => {
          this.ownerCur = ownerReturn;
          this._wksWorksService.setOwner(this.ownerCur);

          const accountTmp = JSON.parse(this.ownerCur.bsnAccounting) as AccountModel;
          this.accountCur = accountTmp;
          this.accountCur.bsnDelayDays = accountTmp.bsnDelayDays ;
          if (this.accountCur.bsnDefferedPaiement === undefined || this.accountCur.bsnDefferedPaiement === '' ) {
            this.accountCur.bsnDefferedPaiement = 'invoiceEmit';
          }
          this.ownerIsLoaded = true;
          this.ownerChoice = false;

          this.getWksContactsList(ownerReturn)
          .then(() => {

          });
          this.ownerCache = this.ownerCur;
          this.wksWorks = undefined;
          this.wksWorks = {
            stdEntity: this._userService.getUserLogged().entity,
            ownerId: this.ownerCache.id,
          };
          this.workEquipmentId = undefined;
          this.workEquipments = undefined;
          this.wksWorkIntervention = undefined;
          this.workTasksContent = undefined;
          this.workTasksContentCache = undefined;
          if (this.dataIn.newWork && this.dataIn.newWork.equipments.mainEquipment) {
            this.workEquipmentId = {
              mainEquipment: this.dataIn.newWork.equipments.mainEquipment.id,
            };
            this.workEquipments = {
              mainEquipment: this.dataIn.newWork.equipments.mainEquipment,
            };
          }
          this.titleChange();
          this.initIhmOptions('undefined');
          this.openEquipment = true;
          this.updateStatut = 'create';
        }); // end customer
      } else { // end // call from equipement list or equipment card
        this.ownerCur = undefined;
        this.ownerChoice = true;
        this.ownerIsLoaded = true;
        this.openEquipment = false;
        this.updateStatut = 'create';
        this.initIhmOptions('undefined');
      }
    }

  }
  initAllBooleans() {
    this.allAvailable = true;
    this.ownerIsLoaded = false;
    this.ownerChoice = false;
    this.ownerSelect = false;
    this.ownerNew = false;
    this.openTasks = false;
    this.uploadDocs = false;
    this.displayImages = false;
    this.displayPdf = false;
    this.displayList = false;
    this.displayQuotesList = false;
    this.wksOthersWorksLoaded = false;
    this.isOkToSave = false;
    this.wksWorks = undefined;
    this.okOwner = false;
    this.openIntervention = false;
    this.openEquipment = false;
    this.changeOwner = false;
    this.changeEquipment = false;
    this.uploadPhotos = false;
    this.displayPhotos = false;

  }
  initIhmOptions(workStatutLabel: string) {
    this.workStatut = this._wksCentralService.getWorkStatut(workStatutLabel);
    
    this.ownerTabOptions = this._wksCentralService.getIhmOption('ownerTab', this.workStatut);
    this.equipmentTabOptions = this._wksCentralService.getIhmOption('equipmentTab', this.workStatut);
    this.interventionTabOptions = this._wksCentralService.getIhmOption('interventionTab', this.workStatut);
    this.tasksTabOptions = this._wksCentralService.getIhmOption('tasksTab', this.workStatut);
    this.dispatchTabOptions = this._wksCentralService.getIhmOption('dispatchTab', this.workStatut);
    this.docsTabOptions = this._wksCentralService.getIhmOption('docsTab', this.workStatut);
    this.statusJournalTabOptions = this._wksCentralService.getIhmOption('statusJournalTab', this.workStatut);
    this.planningOptions = this._wksCentralService.getIhmOption('planning', this.workStatut);
    this.quoteTabOptions = this._wksCentralService.getIhmOption('quoteTab', this.workStatut);
    this.checkListTabOptions = this._wksCentralService.getIhmOption('checkList', this.workStatut);
    this.invoicingTabOptions = this._wksCentralService.getIhmOption('invoicingTab', this.workStatut);
    this.balanceTabOptions = this._wksCentralService.getIhmOption('balanceTab', this.workStatut);
    this.photosTabOptions = this._wksCentralService.getIhmOption('photosTab', this.workStatut);
    this.outputStockTabOptions = this._wksCentralService.getIhmOption('ouputStockTab', this.workStatut);
    if (this.quoteTabOptions.display) {
      this.quoteTabOptions.display = this._wksCentralService.getWksEntityCur().isQuotationActive;
    }
    this.ihmOptionsCkeck = this.quoteTabOptions;

    if (this.actionType === 'display') {
      this.ownerTabOptions.readOnly = true;
      this.ownerTabOptions.readOnly = true;
      this.equipmentTabOptions.readOnly = true;
      this.interventionTabOptions.readOnly = true;
      this.tasksTabOptions.readOnly = true;
      this.dispatchTabOptions.readOnly = true;
      this.docsTabOptions.readOnly = true;
      this.statusJournalTabOptions.readOnly = true;
      this.planningOptions.readOnly = true;
      this.quoteTabOptions.readOnly = true;
      this.checkListTabOptions.readOnly = true;
      this.photosTabOptions.readOnly = true;
      this.outputStockTabOptions.readOnly = true;
    }
    
  }
  initAllSubscribes () {
    
    this._globalEventsManagerService.synchroEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {
      if (event === 'closeTaskRefModal') {
        // this.closeModal();
        // this.loadDocs();
      } else if (event === 'closeUploadFiles') {
        this.toggleUploadFiles();
      } else if (event === 'closeRegisterTaskRefModal') {
        // this.closeModal();

      } else if (event) {
        let eventString: string;
        const typeEvent = typeof event;
        if (typeEvent === 'string')  {
          eventString = String(event);
          if (eventString !== undefined) {
           if (eventString.startsWith('approveQuoteModal_')) {
              // this.closeModal();
              const fileRef = eventString.split('__')[1];
              this.validateQuote(fileRef, 'approved');
              // validateQuoteModal
            } else if (eventString.startsWith('validateQuoteModal_')) {
              // this.closeModal();
              const fileRef = eventString.split('__')[1];
              this.validateQuote(fileRef, 'validatedNew');
            } else if (eventString.startsWith('refuseQuoteModal_')) {
              // this.closeModal();
              const fileRef = eventString.split('__')[1];
              this.validateQuote(fileRef, 'refused');
            } else if (eventString.startsWith('closeQuoteModal_')) {
              // this.closeModal();
            } else if (eventString.startsWith('closeInvoiceModal_')) {
              // this.closeModal();
            }
          }
        }
        // console.log(typeEvent);
      }
    });
    
  }
  
  // work ref 
  loadWorksType() {
    let dataReturn: ParamWorksRatesModel[];
    this.workCodeList = [];
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksWorkRatesList(this._userService.getUserLogged().entity, this._userService.getUserLogged().userlang)
     .pipe(takeUntil(this.onDestroy))
     .subscribe(
       data => {
          dataReturn = data.body;
          for (const workCur of dataReturn) {
            const actionTypeCur: LabelValue = {
              label: workCur.workLabel,
              value: workCur.workCode
            };
            this.workCodeList.push(actionTypeCur);
          }
          this.workCodeList.sort((obj1: LabelValue, obj2: LabelValue) => {
            return obj1.label > obj2.label ? 1 : -1;
          });
          this.workCodeList.splice(0, 0, {
              label: '',
              value: ''
            });
          resolve(dataReturn) ;
        }, err => {
          console.log(err);
          reject(err.status);
        }
      );
    });
  }
  initGlobalsParameters(): void {
    this.adapterDate.setLocale(this._translate.currentLang);
    this.photoData = {
      workId: undefined,
      equipId: undefined,
      taskId: undefined,
      sequenceAtt: undefined
    };
    this.sequenceAttList = [];
    let itemTmp: LabelValue;
    for (const attItem of this.sequenceAttRef) {
      itemTmp =  {
        label: this._translate.instant(attItem),
        value: attItem
      };
      this.sequenceAttList.push(itemTmp);
    }
    itemTmp =  {
      label: '',
      value: undefined
    };
    this.sequenceAttList.unshift(itemTmp);
  }

  loadListsParameters()  {

    this.loadWorksType()
    .then ((responseWork: any) => {

    })
    .catch ((error: any) => {
      
      console.log('loadWorksType : ' + error);
      return;
    }); // end works type

  }
  translateLabel(_key: string) {
    return this._translate.getTranslate(_key);
  }
  getUserLocation() {
    if (navigator.geolocation) {
     navigator.geolocation.getCurrentPosition(position => {
        /* this.lat = position.coords.latitude;
         this.lng = position.coords.longitude;*/
         console.log('Lat : ' + position.coords.latitude + ' Lng : ' + position.coords.longitude );
         const geolocationLat = this._coordinatesService.transform(position.coords.latitude, TransformationType.ToDegrees, Direction.Latitude);
         const geolocationLng = this._coordinatesService.transform(position.coords.longitude, TransformationType.ToDegrees, Direction.Longitude);
         console.log('Lat : ' + geolocationLat + ' Lng : ' + geolocationLng );
       });
    } else {
      console.log('User not allow');
    }
  }
  
  titleChange() {
    if (this.ownerCache === undefined) {
      return;
    }
    this.workTitle = undefined;
    if (this.wksWorks !== undefined && this.wksWorks.workRef !== undefined)  {
      this.workTitle = this.wksWorks.workRef;
    }
    if (this.workTitle === undefined) {
      this.workTitle = this.ownerCache.bsnName;
    } else {
      this.workTitle += ' / ' + this.ownerCache.bsnName;
    }

    if (this.equipBoatCur !== undefined) {
      this.workTitle += ' / ' + this.equipBoatCur.equipName;
    }

    if (this.wksWorkIntervention !== undefined) {
      this.workTitle += ' / ' + this.wksWorkIntervention.workObject;
    } else if (this.dataIn !== undefined && this.dataIn.work !== undefined)  {
      this.workTitle += ' / ' +  this.dataIn.work.workObject;
    }
  }
  readEquipments() {
    this.workEquipments = {
      mainEquipment: undefined,
      subEquipments: [],
    };
    let equipCur: LabelValue;
    this.getWksEquipmentByEquipId(this.workEquipmentId.mainEquipment)
    .then ((response: WksEquipmentModel) => {
      this.workEquipments.mainEquipment = response;
      this.equipBoatCur = response;
      this.equipItem = {
        equipBrand: response.equipBrand,
        equipModel: response.equipModel,
        equipType: response.equipType,
        equipList: undefined
      };
      let equipList: LabelValue[] = [];
      let revisions: RevisionModel[] = [] ;
      if (this.workEquipments.mainEquipment.equipRevisions !== undefined 
        && this.workEquipments.mainEquipment.equipRevisions !== null) {
          revisions = JSON.parse(this.workEquipments.mainEquipment.equipRevisions) as RevisionModel[];
      }
      equipCur = {
        label: this._translate.instant(this.workEquipments.mainEquipment.equipType) + ' - ' + (this.workEquipments.mainEquipment.equipDesign ? 
                this.workEquipments.mainEquipment.equipDesign : 
                this.workEquipments.mainEquipment.equipName), 
        value: this.workEquipments.mainEquipment.id,
        otherData: this.workEquipments.mainEquipment.equipSN,
        otherValues: revisions
      };
      equipList.push(equipCur);
      this.equipItem.equipList = equipList;

      this.dataLocationEquip = [];
      let dataMooringLocation: DataLocationWorkModel;
      if (this.workEquipments.mainEquipment.equipBoatAnchorage) {
        dataMooringLocation = {
          typeLocation: 'mooring',
          dataLocation : {
            portId : this.workEquipments.mainEquipment.equipBoatAnchorage,
            dataFields: JSON.parse(this.workEquipments.mainEquipment.equipBoatBerthing)
          }
        };
        this.dataLocationEquip.push(dataMooringLocation);
      }
      if (this.workEquipments.mainEquipment.equipBoatWintering) {
        dataMooringLocation = {
          typeLocation: 'wintering',
          dataLocation : {
            portId : this.workEquipments.mainEquipment.equipBoatWintering,
            dataFields: JSON.parse(this.workEquipments.mainEquipment.equipBoatStorage)
          }
        };
        this.dataLocationEquip.push(dataMooringLocation);
      }

      if (this.workEquipmentId.subEquipments) {
        this.workEquipments.subEquipments = [];
        let idx = 0;
        equipList = [];
        
        for (const subKey of this.workEquipmentId.subEquipments) {
          this.getWksEquipmentByEquipId(subKey)
          .then ((responseCur: WksEquipmentModel) => {
            this.workEquipments.subEquipments.push(responseCur);
            if (idx === 0) {
              this.equipItem = {
                equipBrand: responseCur.equipBrand,
                equipModel: responseCur.equipModel,
                equipType: responseCur.equipType
              };
            }
            revisions = [];
            if (responseCur.equipRevisions !== undefined 
              && responseCur.equipRevisions !== null) {
                revisions = JSON.parse(responseCur.equipRevisions) as RevisionModel[];
            }
            equipCur = {
              label: this._translate.instant(responseCur.equipType) + ' - ' + (responseCur.equipDesign ? responseCur.equipDesign : responseCur.equipName),
              value: responseCur.id, 
              otherData: responseCur.equipSN,
              otherValues : revisions
            };
            equipList.push(equipCur);
            this.equipItem.equipList = equipList;
            idx++;
          })
          .catch ((error: number) => {
            if (error === 404) {
            }
            return;
          });
        } 
      }
    })
    .catch ((error: number) => {
      if (error === 404) {

      }
      return;
    });
  }
  getWksEquipmentByEquipId(equipId: string) {

    if (this._userService.getUserLogged() === undefined ) { return; }

    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksEquipmentByEquipId(this.entityCur, equipId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          resolve(data.body);
        }, err => {
          // console.log('getOwnerEquipmentList ' + err.message);
          reject(err.status);
        }
      );
    });
  }
  readTasksAndDocs() {
    this.getTasksAndDocsWork()
    .then ((workReturn: WksWorksModel) => {
      this.wksWorks.allAvailable = workReturn.allAvailable;
      this.wksWorks.workTasksContent = workReturn.workTasksContent;
      this.wksWorks.workDocs = workReturn.workDocs;
      this.wksWorks.workQuotes = workReturn.workQuotes;
      this.wksWorks.workInvoices = workReturn.workInvoices;
      
      if ((this.wksWorks.workTasksContent !== undefined))   {
        this.workTasksContent = this.wksWorks.workTasksContent;
        this.workTasksContentCache = JSON.parse(JSON.stringify(this.wksWorks.workTasksContent));
      }
      if ((this.wksWorks.workQuotes !== undefined)  && (this.wksWorks.workQuotes !== null) && (this.wksWorks.workQuotes.length !== 0)) {
        this.loadQuotes(this.wksWorks.workQuotes)
        .then ((responseWork: any) => {

        })
        .catch ((error: any) => {
          if (error !== undefined) {
            console.log('loadQuotes : ' + error);
          }
          return;
        }); // end quotes
      } 
      if ((this.wksWorks.workInvoices !== undefined)  && (this.wksWorks.workInvoices !== null) && (this.wksWorks.workInvoices.length !== 0)) {
        this.loadInvoices(this.wksWorks.workInvoices)
        .then ((responseWork: any) => {

        })
        .catch ((error: any) => {
          if (error !== undefined) {
            console.log('loadInvoices : ' + error);
          }
          return;
        }); // end invoices
      }
    })
    .catch ((error: number) => {
      if (error === 404) {

      }
      return;
    });
  }
  getTasksAndDocsWork() {

    if (this._userService.getUserLogged() === undefined ) { return; }

    return new Promise((resolve, reject) => {
      this._wksCentralService.getTasksAndDocsWork(this.wksWorks, this.entityCur, this._userService.getUserLogged().userlang)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          resolve(data.body);
        }, err => {
          // console.log('getTasksAndDocsWork ' + err.message);
          reject(err.status);
        }
      );
    });    
  } 
  selectedTabChange(orig: string, matTabSelected: MatTabChangeEvent) {
    // console.log (matTabSelected);
    this.invoiceTabDisplay = 0;
    if (orig === 'invoicing') {

      switch (matTabSelected.index) {
        case 0: { // invoices
          this.invoiceTabDisplay = 0;
          break;
        }
        case 1: { // advances
          this.invoiceTabDisplay = 1;
          break;
        }
        case 2: { // creditnote
          this.invoiceTabDisplay = 2;
          break;
        }
        case 3: { // creditnote
          this.invoiceTabDisplay = 3;
          break;
        }
        default: // invoices
          this.invoiceTabDisplay = 0;
          break;
      }
    }
  }
  changeOwnerCur() {
    this.ownerChoice = true;
    this.openIntervention = false;
    this.openEquipment = false;
    this.openTasks = false;
    this.changeOwner = false;
    this.isOkToSave = false;
  }
  changeEquipmentCur() {
  
    this.openIntervention = false;
    this.isOkToSave = false;
    this.workEquipmentId = {
      mainEquipment: '',
      subEquipments: [],
    };
  
    this.workEquipments = {
      mainEquipment: undefined,
      subEquipments: [],
    };

    this._wksCentralService.onChangeStatut.emit('changeEquipment');
    // this.changeEquipment = false;
  }
  validEquipmentCur(equipCur: string) {
    if (equipCur === 'main') {
      this._wksCentralService.onChangeStatut.emit('validMainEquipment');
    } else {
      this._wksCentralService.onChangeStatut.emit('validSubEquipment');
    }
    // this.changeEquipment = false;
  }
  currentTabSelected(tabName: string) {
    // console.log (tabName);
    switch (tabName) {
      case 'ownerTab': {
        this.changeOwner = !this.ownerTabOptions.readOnly;
        this.changeEquipment = false;
        this.okOwner = false;
        break;
      }
      case 'equipmentTab': {
        this.changeOwner = false;
        this.okOwner = false;
        this.changeEquipment = !this.equipmentTabOptions.readOnly;
        break;
      }
      case 'interventionTab': {
        this.changeOwner = false;
        this.changeEquipment = false;
        this.okOwner = false;
        break;
      }
      case 'tasksTab': {
        this.changeOwner = false;
        this.changeEquipment = false;
        this.okOwner = false;
        break;
      }
      case 'dispatchTab': {
        this.changeOwner = false;
        this.changeEquipment = false;
        this.okOwner = false;
        break;
      }
      case 'docsTab': {
        this.changeOwner = false;
        this.changeEquipment = false;
        this.okOwner = false;
        break;
      }
      case 'quoteTab': {
        this.changeOwner = false;
        this.changeEquipment = false;
        this.okOwner = false;
        break;
      }
      case 'statusJournalTab': {
        this.changeOwner = false;
        this.changeEquipment = false;
        this.okOwner = false;
        break;
      }
      case 'checkListTab': {
        this.changeOwner = false;
        this.changeEquipment = false;
        this.okOwner = false;
        break;
      }
      case 'invoicingTab': {
        this.changeOwner = false;
        this.changeEquipment = false;
        this.okOwner = false;
        this.ihmOptionsCkeck  = this.invoicingTabOptions;
        break;
      }
      case 'balanceTab': {
        this.changeOwner = false;
        this.changeEquipment = false;
        this.okOwner = false;
        this.ihmOptionsCkeck  = this.balanceTabOptions;
        break;
      }
      case 'photosTab': {
        this.changeOwner = false;
        this.changeEquipment = false;
        this.okOwner = false;
        this.ihmOptionsCkeck  = this.photosTabOptions;
        this.photoData = undefined;
        this.photoDataBase64 = undefined;
        this.formPhotoIsBuild = false;
        this.buildFormPhoto();
        this.formPhotoIsBuild = true;
        break;
      }
      case 'ouputStockTab': {
        this.changeOwner = false;
        this.changeEquipment = false;
        this.okOwner = false;
        break;
      } 
      default:
        this.changeOwner = false;
        this.changeEquipment = false;
        this.okOwner = false;
        break;
    }
  }
  buildFormPhoto(): void {

    this.photoDataForm = this.fb.group({
      photoEquipment: this.fb.control(''),
      photoTask: this.fb.control(''),
      photoSequenceAtt: this.fb.control(''),
    },
    {
      updateOn: 'blur'
    });

  }
  radioCustomerChange(event: MatRadioChange) {
    this.ownerSelect = false;
    this.ownerNew = false;
    this.customersLoading = false;
    this.customersLoaded = false;
    this.changeOwner = false;

    if (event.value === 'existsCustomer') {
      this.ownerSelect = true;
      this.customersLoading = true;
      this.getWksBusinessList();
      this.bsnStatut = 'readBsn';
    } else {
      this.ownerNew = true;
      this.ownerChoice = false;
      this.ownerIsLoaded = true;
      this.customersLoading = false;
      this.customersLoaded = false;
      this.bsnStatut = 'createBsn';
      this.ownerCur = undefined;
    }

  }
  
  onChangeworkOwner(event: WksBusinessModel) {
    this.ownerCur = event;
    this._wksWorksService.setOwner(this.ownerCur);
    const accountTmp = JSON.parse(this.ownerCur.bsnAccounting) as AccountModel;
    this.accountCur = accountTmp;
    this.accountCur.bsnDelayDays = accountTmp.bsnDelayDays ;
    if (this.accountCur.bsnDefferedPaiement === undefined || this.accountCur.bsnDefferedPaiement === '' ) {
      this.accountCur.bsnDefferedPaiement = 'invoiceEmit';
    }
    this.ownerChoice = false;
    this.ownerIsLoaded = true;
    this.customersLoading = false;
    this.customersLoaded = false;
    this.changeOwner = false;
    this.openEquipment = true;
    this.workEquipmentId = undefined;
    this.bsnStatut = 'updateBsn';
    this.changeOwner = true;
  }
  onKeyOwner(value: any) {
    // console.log(value);
    this.customerFiltredList = this.searchOwners(value);
  }
  searchOwners(value: string) { 
    const filter = value.toLowerCase();
    return this.customerCacheList.filter(customerCur => customerCur.bsnName.toLowerCase().includes(filter));
  }
  
  
  getWksBusinessList() {
    this.customerList = [];
    this.customerFiltredList = [];
    this.customerCacheList = [];
    if (this._userService.getUserLogged() === undefined ) { return; }
    this._wksCentralService.getWksBusinessList(this._userService.getUserLogged().entity, 'wks-customers')
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        this.customerList = [...data.body];
        this.customerFiltredList = [...data.body];
        this.customerCacheList = [...data.body];
        this.customersLoading = false;
        this.customersLoaded = true;
      },
      err => {
        console.log(err);
        this.customersLoading = false;
        this.customersLoaded = false;
       
        if (err.status === 404) {
          const titleBox = this._translate.getTranslate('searching customers');
          const messageBox = this._translate.getTranslate('no customer');
          this.displayMessageBox(titleBox, messageBox, 'ERROR', 'alertWks', 'wks-customer', null );

        }
      }
    );
  }
  
  getOwner(ownerId: string) {
    if (this._userService.getUserLogged() === undefined ) { return; }
    
    return new Promise((resolve, reject) => {
      this._wksCentralService.getOwner(this._userService.getUserLogged().entity, ownerId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const ownerCur = data.body as WksBusinessModel;
   
          resolve(ownerCur);
        }, err => {
          console.log('getOwner' + err.message);
          if (err.status === 404) {
            
          }
          reject(err.status);
        }
      );
    });
  }
  getWksContactsList(_ownerCur: WksBusinessModel) {
  
    if ((_ownerCur.id === undefined) || (_ownerCur.id === null)) {
      return;
    }
    
    if (this._userService.getUserLogged() === undefined ) { return; }
    /*
    const isCompany = ( _ownerCur.bsnType === 'company');
    let contactPerso: ContactInt = null;
    if (!isCompany) {
      contactPerso = JSON.parse(_ownerCur.bsnPerson);
    }
    */
    this.contactsWorkList = [];
    this.dataContacts = [];
    return new Promise((resolve, reject) => {
      this._wksCentralService.getContactsList(this._userService.getUserLogged().entity, 'wks-customers', _ownerCur.id )
     .pipe(takeUntil(this.onDestroy))
     .subscribe(
        data => {
          this.contactsWorkList = data.body as WksContactsModel[];  
          /*
          if (!isCompany) {
            this.dataContacts.push(contactPerso);
          } */      
          if (this.contactsWorkList.length > 0) {
            this.contactsWorksToContactInt();
          }
          resolve(this.contactsWorkList) ;
        }, err => {
          // console.log(err);
          /*
          if ((err.status === 404) && (!isCompany)) {
            this.dataContacts.push(contactPerso);
          
          }
          */
          reject(err.status);
        }
      );
    });
  }
  contactsWorksToContactInt() {
    this.dataContacts = [];
    let selectDeptLabel = '';
    for (const cntCur of this.contactsWorkList) {
      let cntSpecialities;
      try  {
        cntSpecialities =  cntCur.cntSpeciality.split(',');
        if (cntSpecialities.length === 1) {
          selectDeptLabel = this._translate.getTranslate(cntSpecialities[0]);
        } else {
          let iSpeciality = 0;
          for (const speciality of cntSpecialities) {
            if (iSpeciality === 0) {
            selectDeptLabel = this._translate.getTranslate(speciality);
            } else {
              selectDeptLabel += ', ' + this._translate.getTranslate(speciality);
            }
            iSpeciality++;
          }
        }
      } catch (err) {
   
      }


      this.dataContacts.push({
        id: cntCur.id,
        selectDept: selectDeptLabel,
        fonctionDept: (cntCur.cntFunction ? cntCur.cntFunction : ''),
        selectApps: cntCur.cntChannels,
        emails: JSON.parse(cntCur.cntMails),
        phones: JSON.parse(cntCur.cntPhones),
        identity: {
          cntCivility: cntCur.cntCivility,
          cntFirstname: cntCur.cntFirstname,
          cntLastname: cntCur.cntLastname,
        },
        statut: 'existant',
      });
    }
  }

  displayEmail(row: number)  {
    // emailsList: EmailInput[];
    this.doDisplayEmail = (this.doDisplayEmail ? false : true);
    if (!this.doDisplayEmail)  {
      this.selectedCnt = -1;
      this.emailsList = [];
      return;
    }
    this.doDisplayPhone = false;
    this.selectedCnt = row;
    const emailsData = this.dataContacts[row].emails;
    // console.log(emailsData);
    this.emailsList = emailsData;
    /* if ( this.selectedCnt === row) {
      this.selectedCnt = -1;

    } else {
      this.selectedCnt = row;
    }*/
  } 
  displayPhone(row: number)  {
    //  phonesList: PhoneInput[];
    this.doDisplayPhone = (this.doDisplayPhone ? false : true);
    if (!this.doDisplayPhone)  {
      this.selectedCnt = -1;
      this.phonesList = [];
      return;
    }
    this.doDisplayEmail = false;
    this.selectedCnt = row;
    const phonesData = this.dataContacts[row].phones;
    this.phonesList = phonesData;

    // console.log(phonesData);
    /*if ( this.selectedCnt === row) {
      this.selectedCnt = -1;

    } else {
      this.selectedCnt = row;
    }*/
  } 
  loadOthersWorks() {

    let equipId: string = null;
    let equipPartOf: string = null;
    this.wksOthersWorks = [];
    this.wksOthersWorks  = [];

    if (this.workEquipmentId.mainEquipment !== undefined) {
      equipPartOf = this.workEquipmentId.mainEquipment;
    }
    if (this.workEquipmentId !== undefined &&  this.workEquipmentId.mainEquipment !== undefined) {
      equipId = this.workEquipmentId.mainEquipment;
    }
    if (equipId === null)  {
      return;
    }
    return new Promise((resolve, reject) => {
      this._wksCentralService.getOthersWorks(equipId, this.wksWorks.id, this._userService.getUserLogged().userlang, equipPartOf )
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const worksReturn = data.body as WksWorksModel[];
          for (const workCur of worksReturn) {
            const otherwork: LabelValue = {
              label: workCur.workObject,
              value: workCur.id
            };
            this.wksOthersWorks.push(otherwork);
          }
          this.workCodeList.sort((obj1: LabelValue, obj2: LabelValue) => {
            return obj1.label > obj2.label ? 1 : -1;
          });
          this.wksOthersWorks.splice(0, 0, {
              label: '',
              value: ''
            });
          this.wksOthersWorksLoaded = true;
          this._wksWorksService.setOthersWorks(this.wksOthersWorks);
          resolve(data);
        },
        err => {
          if (err.status !== 404) {
            console.log('loadOthersWorks : ' + err.message);
          }
          this.wksOthersWorksLoaded = false;
          reject(err);
        }
      );
    });
  }
  
  setClickedDoc(row: number)  {


    if ( this.selectedDoc === row) {
      this.selectedDoc = -1;

    } else {
      this.selectedDoc = row;
    }
  }
  onChangePhotoEquipment(equipId: string): void {
    // console.log('onChangePhotoEquipment : ' + equipId);
    this.photoData = {
      workId: this.wksWorks.id,
      equipId: undefined,
      taskId: undefined,
      sequenceAtt: undefined
    };
    this.photoDataForm.controls.photoTask.setValue(undefined);
    this.photoDataForm.controls.photoSequenceAtt.setValue(undefined);

    this.photoData.equipId = equipId;
    this.photoDataBase64 = btoa(JSON.stringify(this.photoData));

    // select task for selected equipment

    /*
    workTasksContent:
	    id
	    taskrefLabel
	    taskActionContent: 
		    equipments: 
			    all: false
			    equipId: ['2bf775e5-afc2-4f04-986a-3238c1e9784b']
    */
    this.tasksList = [];
    for (const itemTask of this.workTasksContent) {
      let isOK = false;
      const taskTmp = JSON.parse(JSON.stringify(itemTask));
      const taskactionContent = taskTmp.taskActionContent;
      if (taskactionContent.equipments.all) {
        isOK = true;
      } else {
        for (const equipItem of taskactionContent.equipments.equipId) {
          if (equipItem === equipId)  {
            isOK = true;
          }
        }

      } 
      if (isOK) {
        const taskItem: LabelValue = {
          label: taskTmp.taskrefLabel,
          value: taskTmp.id
        };

        this.tasksList.push(taskItem);
      }
    }
    this.tasksList.sort((obj1: LabelValue, obj2: LabelValue) => {
      return obj1.label > obj2.label ? 1 : -1;
    });
    const optBlank: LabelValue = {
      value: undefined,
      label: ''
    };
    this.tasksList.unshift(optBlank); 
  } 
  onChangePhotoTask(taskId: string): void {
    this.photoData.taskId = taskId;
    this.photoDataBase64 = btoa(JSON.stringify(this.photoData));
  }
  onChangePhotoSequenceAtt(sequenceAtt: string): void {
    this.photoData.sequenceAtt = sequenceAtt;
    this.photoDataBase64 = btoa(JSON.stringify(this.photoData));
  }
  getDateUpload(_value: string) {
    const dateTmp =  moment(_value).format(environment.fmtDateTimeTZ);
    // GridTools.dateRenderer_unixM
    return GridTools.date_unix(dateTmp, this._translate.getLocalFmt('timeFormat'));
  }
 /*
  closeModal() {

  }
  */
  dataOutWorkEquipment(event: any): void {
    switch (event) {
      case 'closeBoat': {

        break;
      } // end case
      default: {
        try {
          const object = event as WksEquipmentModel;
          const equipList: LabelValue[] = [];
          // this.okToValidMainEquip = false;
          // this.okToValidSubEquip = false;
          if (!this.equipmentTabOptions.readOnly) {
            this.changeEquipment = true;
          }
          if (this.workEquipmentId === undefined) {
            this.workEquipmentId = {
              mainEquipment: '',
              subEquipments: [],
            };
          }
          // this.workEquipments
          if (this.workEquipments === undefined) {
            this.workEquipments = {
              mainEquipment: undefined,
              subEquipments: [],
            };
          }
          if (object.isWorkEquip) {
            this.equipItem = {
              equipBrand: object.equipBrand,
              equipModel: object.equipModel,
              equipType: object.equipType
            };
          }
          if (object.isMainEquip && (this.workEquipmentId.mainEquipment !==  object.id)) {
            this.workEquipmentId = {
              mainEquipment: object.id,
              subEquipments: [],
            };
            this.workEquipments = {
              mainEquipment: object,
              subEquipments: [],
            };
            this.equipBoatCur = object;
            this.openIntervention = true;
            this.dataLocationEquip = [];
            let dataMooringLocation: DataLocationWorkModel;
            if (this.workEquipments.mainEquipment.equipBoatAnchorage) {
              dataMooringLocation = {
                typeLocation: 'mooring',
                dataLocation : {
                  portId : this.workEquipments.mainEquipment.equipBoatAnchorage,
                  dataFields: JSON.parse(this.workEquipments.mainEquipment.equipBoatBerthing)
                }
              };
              this.dataLocationEquip.push(dataMooringLocation);
            }
            if (this.workEquipments.mainEquipment.equipBoatWintering) {
              dataMooringLocation = {
                typeLocation: 'wintering',
                dataLocation : {
                  portId : this.workEquipments.mainEquipment.equipBoatWintering,
                  dataFields: JSON.parse(this.workEquipments.mainEquipment.equipBoatStorage)
                }
              };
              this.dataLocationEquip.push(dataMooringLocation);
            }
            if (object.isWorkEquip) {
              const equipCur: LabelValue = {
                  label: (this.workEquipments.mainEquipment.equipDesign ? this.workEquipments.mainEquipment.equipDesign : this.workEquipments.mainEquipment.equipName),
                  value: this.workEquipments.mainEquipment.id,
                  otherData: this.workEquipments.mainEquipment.equipSN
                };
                equipList.push(equipCur);
              this.equipItem.equipList = equipList;
            }
          } 
          if (!object.isMainEquip) {
              let isFound = false;
              let iItem = 0;
              for ( const idCur of this.workEquipmentId.subEquipments) {
                if (idCur === event.id)  {
                  isFound = true;
                  if (object.doRemove) {
                    this.workEquipmentId.subEquipments.splice(iItem, 1);
                  }
                  this.workEquipments.subEquipments.splice(iItem, 1);
                  this.workEquipments.subEquipments.push(object);
                  break;
                }
                iItem++;
              }
              if (!isFound)  {
                this.workEquipmentId.subEquipments.push(object.id);
                this.workEquipments.subEquipments.push(object);
              }
              let equipCur: LabelValue;
              for ( const subEquip of this.workEquipments.subEquipments) {
                equipCur = {
                  label: (subEquip.equipDesign ? subEquip.equipDesign : subEquip.equipName),
                  value: subEquip.id,
                  otherData: subEquip.equipSN
                };
                equipList.push(equipCur);
              }
              this.equipItem.equipList = equipList;
              this.openIntervention = true;
          }
          if (this.wksWorks !== undefined && this.wksWorks.id !== undefined) {
            this.loadOthersWorks()
            .then ((data: any) => {
              this._wksWorksService.setOthersWorks(this.wksOthersWorks);
            })
            .catch ((error: any) => {

            });
          }
        } catch (error) {
          console.error(error);
        }
        if ((this.workEquipmentIdCache === undefined ) || 
            (JSON.stringify(this.workEquipmentId) !== JSON.stringify(this.workEquipmentIdCache))) {
            this.workEquipmentIdCache = JSON.parse(JSON.stringify(this.workEquipmentId));
            if (this.actionType === 'addWork')  {
              this.wksWorkIntervention = undefined;
              this.workTasksContent = undefined;
              this.workTasksContentCache = undefined;
              this.wksWorks = undefined;
              this.wksWorks = {
                stdEntity: this._userService.getUserLogged().entity,
                ownerId: this.ownerCache.id,
                workEquip: JSON.stringify(this.workEquipmentId),
              };
            }
        }
        this.titleChange();
      }
    }
  }
  
  dataOutBsnCardSheet(event: WorkInputEvent): void {
    if (event.objectCur === 'wks-customers') {
      this.okOwner = false;
      if (JSON.stringify(this.ownerCache) !== JSON.stringify(event.customer)) {
        // this.ownerCur = event.customer;
        this.ownerCache = event.customer;        
               
        const accountTmp = JSON.parse(this.ownerCache.bsnAccounting) as AccountModel;
        this.accountCur = accountTmp;
        this.accountCur.bsnDelayDays = accountTmp.bsnDelayDays ;
        if (this.accountCur.bsnDefferedPaiement === undefined || this.accountCur.bsnDefferedPaiement === '' ) {
          this.accountCur.bsnDefferedPaiement = 'invoiceEmit';
        }
        this._wksWorksService.setOwner(this.ownerCache);
      }
      
      if (event.action === 'contactsList' ) {
        this.contactsWorkList = event.customer.dataContacts;
        if (this.contactsWorkList.length > 0) {
          this.contactsWorksToContactInt();
        }
        this.titleChange();
        return;
      }
      if (!this.ownerTabOptions.readOnly) {
        if (event.action === 'updateBsn' || event.action === 'createBsn' ) {
          if (this.ownerCache.dataContacts 
            && this.ownerCache.dataContacts.length > 0)  {
              const name = this.ownerCache.dataContacts[0].cntFirstname + this.ownerCache.dataContacts[0].cntLastname;
            if (name !== undefined && name.trim().length > 0) { // not empty contact
              this.okOwner = true;
              this.changeOwner = false;
            }
          }
        }
      } 
    }
    if (this.ownerCache.id !== this.wksWorks.ownerId) {
      this.wksWorks.ownerId = this.ownerCache.id;
      this.workEquipmentId = undefined;
      this.wksWorkIntervention = undefined;
      this.workTasksContent = undefined;
      this.workTasksContentCache = undefined;
      this.wksWorks = undefined;
      this.wksWorks = {
        stdEntity: this._userService.getUserLogged().entity,
        ownerId: this.ownerCache.id,
      };
    }

    this.titleChange();
  }
  dataOutWorkIntervention(event: WksWorksInterventionEvent): void {
    this.wksWorkIntervention = event.wksWorksIntervention;
    if (event.eventAction === 'okToValidWork' && !this.interventionTabOptions.readOnly) {
      this.changeOwner = false;
      this.changeEquipment = false;
      this.okOwner = false;

      // this.wksWorks = this.fillModel();
      this.isOkToSave = true;
    } else {
      this.isOkToSave = false;
    }
    this.titleChange();
  }
  dataOutQuotation($event: any): void {
    // console.log(JSON.stringify($event));
    const dataOut = {
      action: $event.action,
      typeEvent: $event.typeEvent,
      recapAmountData: $event.data,
    };

    if (dataOut.action === undefined || dataOut.recapAmountData === undefined) {
      return;
    }
    if (dataOut.action === 'doAction') {
      if (dataOut.typeEvent === 'invoicing') {
        dataOut.action = 'invoice';
      } else {
        dataOut.action = 'quotation';
      } 
      this.displayMessage('waitingQuotation');
    } else {
      dataOut.action = 'updateQuotInv';
    }

    this.registerWork(dataOut);
    /*
    const event = {
      orig: option,
      data: this.recapAmountInput
    };
    */
  }
  dataOutTasks(event: any): void {
    if (this.tasksTabOptions.readOnly) {
      return;
    }
    if (JSON.stringify(this.workTasksContentCache) !== JSON.stringify(event)) {
      this.workTasksContent =  event;
      this.isOkToSave = true;
      this.workTasksContentCache = JSON.parse(JSON.stringify(event));
      let activesTask = 0;
      for ( const taskCur of this.workTasksContent) {
        if (!taskCur.disabledTask) {
          activesTask++;
        }
      }
      this.wksWorks.workJsonQuotation = null;
      /*
      if (activesTask > 0) {
        // this.wksWorks = this.fillModel();
        this.isOkToSave = true;
      }
      */
    }

  }
  dataOutoutputStock(event: WorkInputEvent): void {
    if (event.objectCur === 'orderedParts') {
      this.registerWork(undefined, event);
    }
  }
  dataValidTasks(event: any): void {
    // console.log('dataValidTasks : ' + JSON.stringify(event));
    this.isOkToSave = event.validOK;
  }
  registerWork(quotationInvoice?: any, eventOrdered?: WorkInputEvent) {
    if (this.actionType === 'display')  {
      return;
    }
    let argSave = this.updateStatut;
    if (quotationInvoice !== undefined) {
      argSave = quotationInvoice.action;
    }
    const wksWorksCard = this.fillModel(quotationInvoice, eventOrdered);
    this._wksCentralService.saveWksWorks(wksWorksCard, argSave)
    .subscribe(
      returnValue => {
        this._dialog.closeAll();
        // this.workRateToUpdate = paramWorksRatesReturn.body;
        if (this.dataIn !== undefined && this.dataIn.iRow !== undefined)  {
          const dataReturn = {
            wksWorksCard: returnValue.body,
            iRow: this.dataIn.iRow
          };

          this.dataOut.emit(dataReturn);
        }
        this.wksWorks = returnValue.body;
        if ((this.wksWorks.workTasksContent !== undefined))   {
          this.workTasksContent = this.wksWorks.workTasksContent;
          this.workTasksContentCache = JSON.parse(JSON.stringify(this.wksWorks.workTasksContent));
        }
        if (quotationInvoice !== undefined && quotationInvoice.action === 'updateQuotInv'  ) {
          return;
        } else if (quotationInvoice !== undefined  ) {
          this.displayResult(quotationInvoice);

          if (quotationInvoice.action === 'quotation') {
            this.loadQuotes(this.wksWorks.workQuotes)
            .then ((responseWork: any) => { })
            .catch ((error: any) => {
              if (error !== undefined) {
                console.log('loadQuotes : ' + error);
              }
              return;
            });
          }

          return;
        }
        if (this.statutCall === 'sidenav') {
          // this._wksCentralService.onRegisterValid.emit('closeRegister');
          this.isOkToSave = false;
          return;
        } else if  ((this.statutCall === 'modal')  ) {
          this._globalEventsManagerService.synchroEvents.next('closeWorkModal');
          return;
        } else {
          this.doCancel();
        }

      },
      () => {
        this._dialog.closeAll();
        this.errorMessage('register');
      }
    );
  }
  displayResult(quotationInvoice: any): void {
    const titleBox = ( quotationInvoice.action === 'quotation' ? this._translate.getTranslate('quotations') :  this._translate.getTranslate('invoicing'));
    const messageBox = this._translate.getTranslate('process finished');
    this.displayMessageBox(titleBox, messageBox, 'ERROR', 'alertWks', 'wks-customer', null );
  }

  registerOwner(): void {
    this._wksCentralService.saveWksBusiness(this.ownerCache, this.statutCall)
    .subscribe(
      (response) => {
        this.ownerCache = response.body;
        this.ownerCur = response.body;
        this.okOwner = false;
        this.changeOwner = true;
        this.openEquipment = true;
        this.bsnStatut = 'updateBsn';
        this.updateStatut = 'update';
        if (this.workTasksContent !== undefined) {
          this.isOkToSave = true;
        }
      },
      () => {
        this.errorMessage('registerCustomer');
        this.okOwner = false;
        this.openEquipment = false;
      }
    );
  }
  doCancel() {
    if (this.okOwner || this.isOkToSave ) {
      const titleBox = this._translate.getTranslate('confirmCancel');
      const messageBox = this._translate.getTranslate('data no validate');
      this.displayMessageBox(titleBox, messageBox, 'ERROR', 'confirmAction', 'confirmCancel', null );
    } else {
      if  ((this.statutCall === 'modal')  ) {
        this._globalEventsManagerService.synchroEvents.next('closeWorkModal');
        return;
      } else if  (this.statutCall === 'sidenavEquip') {
        this._wksCentralService.onRegisterValid.emit('closeRegister');
        return;
      } else {
        this._globalEventsManagerService.synchroEvents.next('cancelWork');
        return;
      } 
    }
  }
  fillModel(quotationInvoice?: any, eventOrdered?: WorkInputEvent): WksWorksModel {
    if (this.wksWorksTracking === undefined) {
      this.wksWorksTracking = [];
    }
    // pour test
    /*
    const wksAlarmMessages: WksAlarmMessage [] = [];
    wksAlarmMessages.push({
      sendBy: this._userService.getUserLogged().username,
      recipientsMes: this._userService.getUserLogged().username,
      sendDate: undefined,
      mediaMes: 'whatsApp',
      objectMes: 'Alarm date',
      bodyMes: 'Retard intervention',
      metaData: '',   
    });
    */
    // fin test
  
    // console.log((this.workForm.controls.workStartDate.value).format(environment.fmtDateBdd));
    let statutCommandLocal: string;
    if (this.workTasksContent !== undefined && this.workTasksContent !== null && this.workTasksContent.length > 0) {
      if (this.wksWorkIntervention.workStatut === 'elaboration') {
        this.wksWorkIntervention.workStatut = 'completed'; // OK for quotation
      }
    }
    let statutChangeLocal = (this.wksWorks === undefined ? true : 
                          this.wksWorks.workStatut === undefined ? true : 
                          this.wksWorks.workStatut !== this.wksWorkIntervention.workStatut ? true : false );
    if (quotationInvoice !== undefined && this.wksWorks.statutChange) {
      statutChangeLocal = true;
    }
    if (statutChangeLocal && this.wksWorkIntervention !== undefined && this.wksWorkIntervention.workStatut !== undefined) {
      this.initIhmOptions(this.wksWorkIntervention.workStatut);
      const wksStatut = this._wksCentralService.getWorkStatut(this.wksWorkIntervention.workStatut);
      statutCommandLocal = wksStatut.command;
    }

    let workJsonData ;
    if (this.wksWorks.workJsonData) {
      workJsonData = JSON.parse(this.wksWorks.workJsonData);
    } else {
      workJsonData = JSON.parse('{}');
    }
    if (eventOrdered) {
      workJsonData.orderedParts = eventOrdered.orderedParts;
    }

    let jsonQuotation = (this.wksWorks.workJsonQuotation !== undefined ? this.wksWorks.workJsonQuotation : undefined);
    let jsonInvoicing = (this.wksWorks.workJsonInvoicing !== undefined ? this.wksWorks.workJsonInvoicing : undefined);
    if ((quotationInvoice !== undefined && quotationInvoice.typeEvent === 'quotation')) {
      jsonQuotation = JSON.stringify(quotationInvoice.recapAmountData);
    }
    if ((quotationInvoice !== undefined && quotationInvoice.typeEvent === 'invoice')) {
      jsonInvoicing = JSON.stringify(quotationInvoice.recapAmountData);
    }

    let wksWorkCard: WksWorksModel = {
      id: (this.wksWorks && this.wksWorks.id) ? this.wksWorks.id : undefined,
      stdEntity: this._userService.getUserLogged().entity,
      workEquip: (this.workEquipmentId ? JSON.stringify(this.workEquipmentId) : undefined),
      ownerId: this.wksWorks.ownerId ? this.wksWorks.ownerId : this._wksWorksService.getOwner().id,
      workIdPrevious: this.wksWorkIntervention.workIdPrevious,
      workObject: this.wksWorkIntervention.workObject,
      workStatut: this.wksWorkIntervention.workStatut,
      workTicket:  this.wksWorkIntervention.workTicket,
      workCriticality: this.wksWorkIntervention.workCriticality,
      workPlanningId: ( this.wksWorkIntervention.calendarEventBdd ? this.wksWorkIntervention.calendarEventBdd.id : undefined),
      /*workStartDate: this.wksWorkIntervention.,
      workEndDate: this.wksWorkIntervention.calendarEventBdd.calStart,*/
      workComment: this.wksWorkIntervention.workComment,
      workLocation: this.wksWorkIntervention.workLocation,
      workLocationData: this.wksWorkIntervention.workLocationData,
      /*workMooringPort: this.wksWorkIntervention.workMooringPort,
      workMooringPool: this.wksWorkIntervention.workMooringPool,
      workMooringPontoon: this.wksWorkIntervention.workMooringPontoon,
      workMooringRing: this.wksWorkIntervention.workMooringRing,
      */
      workPosition: this.wksWorkIntervention.workPosition,
      workJsonQuotation: jsonQuotation,
      workJsonInvoicing: jsonInvoicing,
      workJsonData: JSON.stringify(workJsonData),
      workStatutDate: (this.wksWorks && this.wksWorks.workStatutDate) ? this.wksWorkIntervention.workStatutDate : undefined,
      workRecordedDate: (this.wksWorks && this.wksWorks.workRecordedDate) ? this.wksWorks.workRecordedDate : undefined,
      workTasksContent: this.workTasksContent,
      wksWorksTracking: this.wksWorksTracking,
      allAvailable: this.allAvailable,
      statutChange: statutChangeLocal,
      wksCalendar: ( this.wksWorkIntervention.calendarEventBdd ? this.wksWorkIntervention.calendarEventBdd : undefined),
      stdCreation: (this.wksWorks && this.wksWorks.stdCreation) ? this.wksWorks.stdCreation : undefined,
      statutCommand : statutCommandLocal,
    };
    if (statutChangeLocal) {
      wksWorkCard = this._wksWorksService.fillTracking(wksWorkCard, this._userService.getUserLogged().username, 
                                          wksWorkCard.workStatut, wksWorkCard.workComment);
    }
    return wksWorkCard;
  }
  
  errorMessage(_actionCur: string) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let messageBox: string;
    if (_actionCur === 'register') {
      messageBox = 'error recording';
      titleBox = 'recording work';
      
    }
    if (_actionCur === 'registerCustomer') {
      messageBox = 'error recording';
      titleBox = 'recording customers';
      
    }
    if (_actionCur === '208') {
      messageBox = 'No more data';
      titleBox = 'quote';
    } 

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate(titleBox),
      typeDialog: 'alertWks',
      panelClass: 'stdTheme',
      contentMessage: this._translate.getTranslate(messageBox),
      data1: '',
      data2: '',
      messageType: 'ERROR'

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        console.log('Dialog output:', data);
      });

  }
  toggleDisplayPdf()  {
    this.displayImages = false;
    this.uploadDocs = false;
    this.displayList = false;
    this.displayPdf = true;

    this.filesToDisplay = [];
    if (this.wksWorks.workDocs)  {
      for (const docCur of this.wksWorks.workDocs as WksWorksDocs []) {
        if (docCur.workdocType !== 'document' )  {
          continue;
        }
        const fileToDisplay = {
          fileName: docCur.workdocFilename,
          fileLegend: docCur.workdocLegend,
          filePath: docCur.workdocPath + '/' + docCur.workdocFilename,
          dataImgLoaded: false,
          imageToShow: undefined,
          fileType: docCur.workdocType
        };
        this.filesToDisplay.push(fileToDisplay);
      }
      if (this.filesToDisplay.length === 0) {
        this.displayImages = false;
        this.uploadDocs = false;
      }
      this.uploadDocs = false;
    } else {
      this.displayImages = false;
      this.uploadDocs = false;
    }

  }
  toggleDisplayList()  {
    this.displayImages = false;
    this.uploadDocs = false;
    this.displayList = true;
    this.displayPdf = false;
  }
  toggleUploadFiles()  {
    this.uploadDocs = true;
    this.displayImages = false;
    this.displayPdf = false;
    this.displayList = false;
  }
  toggleUploadPhotos()  {
    this.uploadPhotos = true;
    this.displayPhotos = false;
  }
  // https://stackblitz.com/edit/angular-1yr75s?file=src%2Fapp%2Fapp.component.ts
  toggleDisplayImages()  {
    this.displayImages = true;
    this.uploadDocs = false;
    this.displayList = false;
    this.displayPdf = false;

    this.filesToDisplay = [];
    if (this.wksWorks.workDocs)  {
      for (const docCur of this.wksWorks.workDocs as WksWorksDocs []) {
        if (docCur.workdocType !== 'image' )  {
          continue;
        }
        const fileToDisplay = {
          fileName: docCur.workdocFilename,
          fileLegend: docCur.workdocLegend,
          filePath: docCur.workdocPath + '/' + docCur.workdocFilename,
          dataImgLoaded: false,
          imageToShow: undefined,
          fileType: docCur.workdocType
        };
        this.filesToDisplay.push(fileToDisplay);
      }
      if (this.filesToDisplay.length === 0) {
        this.displayImages = false;
      }
    } else {
      this.displayImages = false;
    }
  }
  toggleDisplayPhotos()  {
    this.displayPhotos = true;
    this.uploadPhotos = false;


    this.filesToDisplay = [];
    if (this.wksWorks.workDocs)  {
      for (const docCur of this.wksWorks.workDocs as WksWorksDocs []) {
        if (docCur.workdocType !== 'photo' )  {
          continue;
        }
        const fileToDisplay = {
          fileName: docCur.workdocFilename,
          fileLegend: docCur.workdocLegend,
          filePath: docCur.workdocPath + '/' + docCur.workdocFilename,
          dataImgLoaded: false,
          imageToShow: undefined,
          fileType: docCur.workdocType
        };
        this.filesToDisplay.push(fileToDisplay);
      }
      if (this.filesToDisplay.length === 0) {
        this.displayPhotos = false;
      }
    } else {
      this.displayPhotos = false;
    }
  }
  getLinkPhotos(): void {

      this._dialog.closeAll();
      this.displayMessage('waitingLinkPhoto');
      this.generateLinkPhotos()
      .then ((responseLink: any) => {
        this._dialog.closeAll();
        this._wksCentralService.copyToClipboard(responseLink.value);
        this.toastr.success(this._translate.instant('linkPhotoGenerated'), responseLink.value,
          {positionClass : 'toast-center-center'}
        );
      })
      .catch ((error: any) => {
        this._dialog.closeAll();
        console.log('generateLinkPhotos : ' + error);
        return;
      }); // end works type
  }
  generateLinkPhotos() {

    return new Promise((resolve, reject) => {
      this._wksCentralService.getLinkPhotos(this.photoData, this.wksWorks.stdEntity)
     .pipe(takeUntil(this.onDestroy))
     .subscribe(
       data => {
          const dataReturn = data.body;
          resolve(dataReturn) ;
        }, err => {
          console.log(err);
          reject(err.status);
        }
      );
    });
  }
  displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string, dataCur: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType

      };

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
        this._dialog.closeAll();
        if (( data === 'okAction') && (_actionCur === 'removeDoc')) {
          this.deleteDocOK(dataCur);
        }
        if (( data === 'okAction') && (_actionCur === 'wks-customer')) {
          this.ownerNew = true;
          this.ownerChoice = false;
          this.ownerIsLoaded = true;
          this.customersLoading = false;
          this.customersLoaded = false;
          this.bsnStatut = 'createBsn';
          this.ownerCur = undefined;
        }
            // 'data no validate'
        if (( data === 'okAction') && (_actionCur === 'confirmCancel')) {
          if (this.wksWorks !== undefined && (this.dataIn !== undefined && this.dataIn.work !== undefined)) {
             this.dataIn.work = JSON.parse(JSON.stringify(this.wksWorks));
          }
          if  ((this.statutCall === 'modal')  ) {
            this._globalEventsManagerService.synchroEvents.next('closeWorkModal');
            return;
          } else if  (this.statutCall === 'sidenavEquip') {
            this._wksCentralService.onRegisterValid.emit('closeRegister');
            return;
          } else {
            this._globalEventsManagerService.synchroEvents.next('cancelWork');
            return;
          } 
        } else if (_actionCur === 'confirmCancel') {
          return;
        }
      });
  }
  removeDoc(_row: number) {
      // console.log('deleteEntity Row clicked: ', row);
      let titleBox: string;
      let messageBox: string;
      titleBox = 'deleting docs';
        
      titleBox = this._translate.getTranslate(titleBox);
      messageBox = this.wksWorks.workDocs[_row].workdocFilename;
      const dataIn =  {
        fileToRemove: this.wksWorks.workDocs[_row],
        row: _row
      };
      this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'removeDoc', dataIn );
    }
  deleteDocOK(data: any) {

    // this.filesToDisplay.splice(data.row, 1);
    //  as WksWorksDocs []
    // this.wksWorks.workDocs = [];

    for (const docCur of this.wksWorks.workDocs)  {
      if (docCur.workdocFilename === data.fileToRemove.workdocFilename)  {        
        this.removeFile(docCur)
        .then ((responseType: any) => {
          this.wksWorks.workDocs.splice(data.row, 1);
        })
        .catch ((error: any) => {
          console.log('removeFile : ' + error);
          return;
        });
        break;
      }
    }

  }
  private removeFile(currentDoc: WksWorksDocs) {

    // console.log('fichier transféré ' + _file.name);
    return  new Promise<void>((resolve, reject) => {
      this._wksCentralService.removeWksWorksDoc(currentDoc).subscribe((event) => {
            resolve();
        },
        response => {
          reject(response);
          } ,
        );
      });
  }  
 
/*================================================================= quotation */
  setClickedQuote(row: number)  {


    if ( this.selectedQuote === row) {
      this.selectedQuote = -1;

    } else {
      this.selectedQuote = row;
    }
  }
  loadQuotes(_wksQuotes: WksWorksDocs []) {

    this.wksQuotesNew = [];
    this.wksQuotesValidated = [];
    this.wksQuotesApproved = [];
    this.wksQuotesRefused = [];
    this.listQuotes = [];
    this.displayQuotesList = false;
    return new Promise<void>((resolve, reject) => {
      if ((_wksQuotes === null) || (_wksQuotes === undefined) || (_wksQuotes.length === 0)) {
        reject();
      }

      for (const docCur of _wksQuotes) {
        if (docCur.workdocPath === null) { // error bdd on recording quotation
          continue;
        }
        if (docCur.workdocPath.includes('new')) {
          this.wksQuotesNew.push(docCur);
          continue;
        } else if (docCur.workdocPath.includes('submitted')) {
          this.wksQuotesValidated.push(docCur);
          continue;
        } else if (docCur.workdocPath.includes('approved')) {
          this.wksQuotesApproved.push(docCur);
          continue;
        } else if (docCur.workdocPath.includes('refused')) {
          this.wksQuotesRefused.push(docCur);
          continue;
        }
      }
      this.listQuotes[0] = {
        name: 'quoteNews',
        list: this.wksQuotesNew
      };
      this.listQuotes[1] = {
        name: 'quoteSubmitted',
        list: this.wksQuotesValidated
      };
      this.listQuotes[2] = {
        name: 'quoteApproved',
        list: this.wksQuotesApproved
      };
      this.listQuotes[3] = {
        name: 'quoteRefused',
        list: this.wksQuotesRefused
      };
      this.displayQuotesList = true;
      resolve() ;
    });
  }
  
  openModalQuote() {

    this.displayWorkPdf();
  }
  validateQuote(_fileRef: string, _actionArg: string) {
    const docKeys: string [] = _fileRef.split(':');
    // console.log('validateQuote : ' + _fileRef);
    // this._wksCentralService.updateQuotation(this.userLangCur, docKeys[2], this.workToUpdate, 'validateNew');
    this._wksCentralService.updateQuotation( this._userService.getUserLogged().userlang, docKeys[2], this.wksWorks, _actionArg)
    .subscribe(
      (data) => {
        const workReturn = data.body as WksWorksModel;
        if ((_actionArg === 'approved') || (_actionArg === 'refused') ) {
          this.wksWorkIntervention.workStatut = _actionArg;
          this.registerWork();
        }  else {
          const dataReturn = {
            wksWorksCard: workReturn,
            iRow: this.dataIn.iRow
          };
          this.dataOut.emit(dataReturn);    
        }  
        this.wksWorks = workReturn;
        
        this.loadQuotes(workReturn.workQuotes)
        .then ((responseWork: any) => { })
        .catch ((error: any) => {
          if (error !== undefined) {
            console.log('loadQuotes : ' + error);
          }
          return;
        });
      },
      (err) => {
        console.log('validateQuote ' + _fileRef + ' KO ' + err );
      }
    );
  }
  
  removeQuote(_fileRef: string, _quoteQueue: string, _rowNum: number, _quoteCur: WksWorksDocs) {
    let docKeys: string [];
    let action: string;
    if ((_fileRef !== undefined) && (_fileRef !== null)) {
      docKeys = _fileRef.split(':');
      action = 'remove_new';
    }
    if ((_quoteCur !== undefined) && (_quoteCur !== null)) {
      const fileRef = _quoteCur.id + ':' + _quoteCur.workdocFilename + ':' + _quoteCur.id;
      docKeys = fileRef.split(':');
      action = 'remove_' + _quoteQueue;
    }
    // console.log('removeQuote : ' + _fileRef);
    // this._wksCentralService.updateQuotation(this.userLangCur, docKeys[2], this.workToUpdate, 'removeNew');
    this._wksCentralService.updateQuotation( this._userService.getUserLogged().userlang, docKeys[2], this.wksWorks, action)
    .subscribe(
      (data) => {
        const workReturn = data.body as WksWorksModel;
        const dataReturn = {
          wksWorksCard: workReturn,
          iRow: this.dataIn.iRow
        };
        this.dataOut.emit(dataReturn);
        this.wksWorks = workReturn;
        this.loadQuotes(this.wksWorks.workQuotes)
        .then ((responseWork: any) => {})
        .catch ((error: any) => {
          if (error !== undefined) {
            console.log('loadQuotes : ' + error);
          }
          return;
        });
      },
      (err) => {
        console.log('removeQuote ' + _fileRef + ' KO ' + err );
      }
    );
  }
  disapprovedQuote( _rowNum: number, _quoteCur: WksWorksDocs) {

    const fileRef = _quoteCur.id + ':' + _quoteCur.workdocFilename + ':' + _quoteCur.id;
    const docKeys = fileRef.split(':');
    const action = 'disapproved';
    this._wksCentralService.updateQuotation( this._userService.getUserLogged().userlang, docKeys[2], this.wksWorks, action)
    .subscribe(
      (data) => {
        const workReturn = data.body as WksWorksModel;
        const dataReturn = {
          wksWorksCard: workReturn,
          iRow: this.dataIn.iRow
        };
        this.wksWorks = workReturn;
        this.dataOut.emit(dataReturn);
        this.loadQuotes(workReturn.workQuotes)
        .then ((responseWork: any) => {})
        .catch ((error: any) => {
          if (error !== undefined) {
            console.log('loadQuotes : ' + error);
          }
          return;
        });
      },
      (err) => {
        console.log('removeQuote ' + fileRef + ' KO ' + err );
      }
    );
   
  }
  viewQuote(_quoteCur: WksWorksDocs, _quoteQueue: string, _action: string) {
    const fileRef = _quoteCur.id + ':' + _quoteCur.workdocFilename + ':' + _quoteCur.id;
    const docKeys = fileRef.split(':');
    this.filesToDisplay = [];

    const fileToDisplay: FileToDisplay = {
      fileName: _quoteCur.workdocFilename,
      fileLegend: _quoteCur.workdocLegend + ' / ' + _quoteCur.workdocFilename,
      filePath: _quoteCur.workdocPath + '/' + _quoteCur.workdocFilename,
      fileRef: _quoteCur.id + ':' + _quoteCur.workdocFilename + ':' + _quoteCur.id,
      dataImgLoaded: false,
      imageToShow: undefined,
      fileType: _quoteCur.workdocType
    };
    this.filesToDisplay.push (fileToDisplay);
    this.statusModal = 'quotes_read';
    if (_action === 'approved') {
      this.statusModal = 'quotes_approved';
    }
    // validated
    if (_action === 'validated') {
      this.statusModal = 'quotes_validated';
    }
    if (_action === 'refused') {
      this.statusModal = 'quotes_refused';
    }
    this.openModalQuote();
  }
/*==================================================== invoicing ===*/
  openModalInvoice() {

    this.displayWorkPdf();
  }
  viewInvoice(_invoiceCur: WksWorksDocs, _invoiceQueue: string, _action: string) {
    const fileRef = _invoiceCur.id + ':' + _invoiceCur.workdocFilename + ':' + _invoiceCur.id;
    const docKeys = fileRef.split(':');
    this.filesToDisplay = [];

    const fileToDisplay: FileToDisplay = {
      fileName: _invoiceCur.workdocFilename,
      fileLegend: _invoiceCur.workdocLegend + ' / ' + _invoiceCur.workdocFilename,
      filePath: _invoiceCur.workdocPath + '/' + _invoiceCur.workdocFilename,
      fileRef: _invoiceCur.id + ':' + _invoiceCur.workdocFilename + ':' + _invoiceCur.id,
      dataImgLoaded: false,
      imageToShow: undefined,
      fileType: _invoiceCur.workdocType
    };
    this.filesToDisplay.push (fileToDisplay);
    this.statusModal = 'invoice_read';
    
    this.openModalInvoice();
  }
  setClickedInvoice(row: number)  {

    if ( this.selectedInvoice === row) {
      this.selectedInvoice = -1;

    } else {
      this.selectedInvoice = row;
    }
  }
  loadInvoices(_wksInvoice: WksWorksDocs []) {

    this.listInvoices = [];
    const wksinvoices: WksWorksDocs [] = [];
    const wksAdvance: WksWorksDocs [] = [];
    const wksCreditnotes: WksWorksDocs [] = [];

    this.displayInvoicesList = false;
    return new Promise<void>((resolve, reject) => {
      if ((_wksInvoice === null) || (_wksInvoice === undefined) || (_wksInvoice.length === 0)) {
        reject();
      }

      for (const docCur of _wksInvoice) {
        if (docCur.workdocPath.includes('invoice')) {
          wksinvoices.push(docCur);
          continue;
        } else if (docCur.workdocPath.includes('creditnote')) {
          wksCreditnotes.push(docCur);
          continue;
        } else if (docCur.workdocPath.includes('advance')) {
          wksAdvance.push(docCur);
          continue;
        }
      }
      this.listInvoices[0] = {
        name: 'invoices',
        list: wksinvoices
      };
      this.listInvoices[1] = {
        name: 'creditnotes',
        list: wksCreditnotes
      };
      this.listInvoices[2] = {
        name: 'advances',
        list: wksAdvance
      };
      this.displayInvoicesList = true;
      resolve() ;
    });
  }
  dataOutInvoicing($event: any): void {
    let action = $event.action;
    const typeEvent = $event.typeEvent;
    const dataOut = $event.data;

    if (action === undefined || dataOut === undefined) {
      return;
    }
    if (action === 'doAction') {
      action = 'invoice';
    }
    try {
      JSON.stringify(dataOut);
    } catch (error) {
      console.log('dataOutInvoicing : ' + error);
      return;
    } finally { }
    
    let invoicingCache: any;

    if (this.wksWorks.workJsonInvoicing) {
      invoicingCache = JSON.parse(JSON.stringify(this.wksWorks.workJsonInvoicing));
    } 
    if (!invoicingCache || JSON.stringify(invoicingCache) !== JSON.stringify(dataOut)) {
      this.wksWorks.workJsonInvoicing = JSON.stringify(dataOut);

      if (action === 'invoice') {
        this.displayMessage('validInvoice', action);
      } else {
        this.validRecapAmount(action);
      } 
    }

  }
  dataOutAdvance($event: any): void {
    const action = $event.action;
    const typeEvent = $event.typeEvent;
    const dataOut = $event.data;

    if (action === undefined || dataOut === undefined) {
      return;
    }

    try {
      JSON.stringify(dataOut);
    } catch (error) {
      console.log('dataOutAdvance : ' + error);
      return;
    } finally { }
    
    let invoicingCache: any;

    if (this.wksWorks.workJsonInvoicing) {
      invoicingCache = JSON.parse(JSON.stringify(this.wksWorks.workJsonInvoicing));
    } 
    if (!invoicingCache || JSON.stringify(invoicingCache) !== JSON.stringify(dataOut)) {
      this.wksWorks.workJsonInvoicing = JSON.stringify(dataOut);

      if (typeEvent === 'advances'  && action.startsWith('sendAdvance')) {
        this.displayMessage('validAdvance', action);
      } else {
        this.validRecapAmount(action);
      } 
    }

  }
  dataOutCreditnote($event: any): void {
    const action = $event.action + ';' + $event.itemRow;
    const typeEvent = $event.typeEvent;
    const dataOut = $event.data;

    if (action === undefined || dataOut === undefined) {
      return;
    }

    try {
      JSON.stringify(dataOut);
    } catch (error) {
      console.log('dataOutCreditnote : ' + error);
      return;
    } finally { }
    
    let invoicingCache: any;

    if (this.wksWorks.workJsonInvoicing) {
      invoicingCache = JSON.parse(JSON.stringify(this.wksWorks.workJsonInvoicing));
    } 
    if (!invoicingCache || JSON.stringify(invoicingCache) !== JSON.stringify(dataOut)) {
      this.wksWorks.workJsonInvoicing = JSON.stringify(dataOut);

      if (typeEvent === 'creditnotes'  && action.startsWith('sendCreditnote')) {
        this.displayMessage('validCreditnote', action);
      } else {
        this.validRecapAmount(action);
      } 
    }

  }
  validRecapAmount(actionArg: string): void {
    this.fillModel();
    this._wksCentralService.saveWksWorks(this.wksWorks, actionArg)
    .subscribe(
      returnValue => {
        const workReturn = returnValue.body;
        this.wksWorks = workReturn;
        if (actionArg === 'invoice') {
          this._dialog.closeAll();
          this.displayMessage('closeInvoicing');
          this.loadInvoices(workReturn.workQuotes)
          .then ((responseWork: any) => { })
          .catch ((error: any) => {
            if (error !== undefined) {
              console.log('validRecapAmount: invoice : ' + error);
            }
            return;
          });
        }
        if (actionArg === 'creditnotes' || actionArg.startsWith('sendCreditnote')) {
          this._dialog.closeAll();
          this.displayMessage('closeCreditnote');

        }
        if (actionArg === 'advances' || actionArg.startsWith('sendAdvance')) {
          this._dialog.closeAll();
          this.displayMessage('closeAdvance');
 
        }
      },
      err => {
        const message = JSON.stringify(err);
        // console.log(JSON.stringify(err));
        this._dialog.closeAll();
        if (message.includes('303')) {
          if (actionArg.startsWith('sendCreditnote')) {
            this.displayMessage('creditnote');
          } else  {
            this.displayMessage('invoice');
          }
        } else  {
          this.displayMessage('register');
        }
      }
    );
  }
  displayWorkPdf() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '85%';
    dialogConfig.width = '60%';

    dialogConfig.data = {
      statutCall: this.statusModal,
      dataList: this.filesToDisplay,

      };
    
      const dialogRef = this._dialog.open(WorksPdfComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
        this._dialog.closeAll();
      });

  }
  displayMessage(_actionCur: string, _actionArg?: string) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let messageBox: string;
    let diagType: string;
    diagType = 'alertWks';
    if (_actionCur === 'register') {
      messageBox = 'error recording';
      titleBox = 'recording work';
      
    }
    if (_actionCur === 'invoice') {
      messageBox = 'error invoicing';
      titleBox = 'invoicing';
      
    }
    if (_actionCur === 'advance') {
      messageBox = 'error advance';
      titleBox = 'invoicing';
      
    }
    if (_actionCur === 'creditnote') {
      messageBox = 'error creditnote';
      titleBox = 'invoicing';
      
    }
    if (_actionCur === 'validInvoice') {
      messageBox = 'Ok to emit invoice';
      titleBox = 'invoicing';
      diagType = 'confirmAction';
    }
    if (_actionCur === 'validCreditnote') {
      messageBox = 'Ok to emit creditnote';
      titleBox = 'invoicing';
      diagType = 'confirmAction';
    }
    if (_actionCur === 'validAdvance') {
      messageBox = 'Ok to emit advance';
      titleBox = 'invoicing';
      diagType = 'confirmAction';
    }
    if (_actionCur === 'closeInvoicing') {
      messageBox = 'invoice emit';
      titleBox = 'invoicing';
    }
    if (_actionCur === 'closeCreditnote') {
      messageBox = 'credit note emit';
      titleBox = 'invoicing';
    }
    if (_actionCur === 'closeAdvance') {
      messageBox = 'advance emit';
      titleBox = 'invoicing';
    }
    if (_actionCur === 'waitingQuotation') {
      messageBox = 'quotation_inprogress';
      titleBox = 'Quotation';
      diagType = 'infoProgressWks';
    }
    if (_actionCur === 'waitingInvoicing') {
      messageBox = 'invoicing_inprogress';
      titleBox = 'invoicing';
      diagType = 'infoProgressWks';
    }
    if (_actionCur === 'waitingAdvance') {
      messageBox = 'advance_inprogress';
      titleBox = 'invoicing';
      diagType = 'infoProgressWks';
    }
    if (_actionCur === 'waitingCreditnote') {
      messageBox = 'creditnote_inprogress';
      titleBox = 'invoicing';
      diagType = 'infoProgressWks';
    }
    if (_actionCur === 'waitingLinkPhoto') {
      messageBox = 'generatingLinkPhoto';
      titleBox = 'photos';
      diagType = 'infoProgressWks';
    }

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate(titleBox),
      typeDialog: diagType,
      panelClass: 'stdTheme',
      contentMessage: this._translate.getTranslate(messageBox),
      data1: '',
      data2: '',
      messageType: 'ERROR'

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
        if (_actionCur === 'validInvoice' && data === 'okAction') {
          this._dialog.closeAll();
          this.validRecapAmount(_actionArg);
          this.displayMessage('waitingInvoicing');
        }
        if (_actionCur === 'validAdvance' && data === 'okAction') {
          this._dialog.closeAll();
          this.validRecapAmount(_actionArg);
          this.displayMessage('waitingAdvance');
        }
        if (_actionCur === 'validCreditnote' && data === 'okAction') {
          this._dialog.closeAll();
          this.validRecapAmount(_actionArg);
          this.displayMessage('waitingCreditnote');
        }
      });

  }
}
