<div class="mat-elevation-z8 flex-row row w-100" style="margin-top: 10px" >
    <div *ngIf="loadingPhotosInProgress" class="spinner-container  stdTheme w-100" >
      <div class="flex-row row d-flex justify-content-center" style="padding: 0px; margin-left: 0px; margin-right: 0px;margin-top: 50px">
        <mat-spinner diameter="40" strokeWidth="5"></mat-spinner>
      </div>
      <div class="flex-row row d-flex justify-content-center" style="padding: 0px; margin-left: 0px; margin-right: 0px;margin-top: 70px">
        <div class="loader-container">
          <div class="loading-text">{{ 'loadingPhotos' | translate }}<span class="dots"></span></div>
        </div>
      </div>
    </div>

    <mat-sidenav-container *ngIf="!loadingPhotosInProgress && displayPhotos " (backdropClick)="toggleSidenav('backdrop')" [hasBackdrop]="hasBackdrop" fxFlexFill class="container-sidenav w-100" autosize>
        <mat-sidenav #sidenav fxLayout="column" mode="over" opened="false" position="end" class="lateral-sidenav-90" disableClose style="overflow:hidden !important">
          <!-- 
          <ngb-carousel
                [showNavigationArrows]="true" 
                [showNavigationIndicators]="true"
                interval="12000"
                [keyboard]="true"
                [pauseOnHover]="true"
                [wrap]="true"
                [activeId]=""
            >
      
            <div  *ngFor="let imgCur of imgFiles; let i=index">
              <ng-template ngbSlide id="{{imgCur.fileName}}">
                <div class="picsum-img-wrapper">
                  <img [src]="imgCur.imageToShow  | safeHtml: 'resourceUrl' " alt="{{imgCur.fileName}}">
                </div>
                <div class="carousel-caption">
                  <h3 class="stdTheme">{{imgCur.fileLegend}}</h3>
                  <p class="stdTheme">{{imgCur.fileName}}</p>
                </div>
              </ng-template>
            </div>
          </ngb-carousel>
          -->
        </mat-sidenav>
        <mat-sidenav-content style="padding-left: 15px">
          <div class="container-fluid"  style="overflow-x: hidden!important; overflow-y: auto!important; height: 600px!important; max-height: 600px!important;" >
            <div class="flex-row row w-100"> <!-- primary row-->
              <div class="col-4">
                <div  *ngFor= "let equipItem of photosEquip; let iEquip=index; let c=count;let isLast=last">
                  <div id="photosEquip_{{iEquip}}" #photosEquip_{{iEquip}} class="row rowEquip " 
                              style="margin: 0px; padding: 0px;line-height: 30px;margin-left: 0px;margin-right: 0px;;margin-bottom: 5px;" >
                    <div class="col-lg-1 col-xs-1 " style="padding-right: 0px;padding-left: 0px;">
                        <button mat-icon-button
                            (click)="equipItem.isCollapsed = !equipItem.isCollapsed"
                            [attr.aria-expanded]="!equipItem.isCollapsed" aria-controls="collapseExample">
                                <span *ngIf="equipItem.isCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                                <span *ngIf="!equipItem.isCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                        </button> 
                    </div>
                    <div class="col-lg-11 col-xs-9 labelEquip " style="margin-top: 10px;" >
                      <p  style="margin: 0px;"><b>{{ equipItem.equipLabel }}</b>             
                      </p>
                    </div>
                  </div>
                  <div  [ngbCollapse]="equipItem.isCollapsed">
                    <div *ngTemplateOutlet="photosTask; context: {isCollapsed: equipItem.isCollapsed, photosTask: equipItem.photosTask}"></div>
                  </div>
                </div> <!-- end for Equips -->
              </div> <!-- end col list-->
              <div class="col-8" >
                <div class="flex-row row w-100" *ngIf="imagesViewerDisplay">
                  <div class="col-12" >
                    <span> <b>{{ imageIndex}} / {{ imagesViewerList.length }}</b></span>
                    <span> {{ imagesLegendList[imageIndex - 1]}}</span>
                  </div>
                </div>
                <div class="flex-row row w-100" *ngIf="imagesViewerDisplay" style="height: 550px!important; max-height: 550px!important; min-height: 550px!important;">
                  <div class="col-12" >
                    <ngx-image-viewer [src]="imagesViewerList "  
                      [config]="{btnIcons: {rotateClockwise: 'fa fa-redo'},
                                customBtns:[{name: 'download', icon: 'fa fa-download'}
                                ]}"
                      (customEvent)="handleEventPhotosView($event)"
                      (indexChange)="indexChangeEventPhotosView($event)">
                    </ngx-image-viewer>
                  </div>
              </div>

              </div>
            </div> <!-- end primary row -->
          </div> <!-- end container -->
        </mat-sidenav-content>
      </mat-sidenav-container>
</div>
<ng-template #photosTask let-isCollapsed="isCollapsed" let-photosTask="photosTask" >
  <div *ngIf="!isCollapsed">  
    <div  *ngFor= "let taskItem of photosTask; let iTask=index; let c=count;let isLast=last">
      <fieldset class="rowTask-border" style="margin: 0px; padding: 0px;margin-left: 15px;margin-right: 10px">
        <div id="photosTask_{{iTask}}" #photosTask_{{iTask}} class="row rowTask" style="line-height: 30px;margin-left: 0px;margin-right: 0px;" >
          <div class="col-lg-1 col-xs-1" style="padding-right: 0px;padding-left: 0px;">
              <button mat-icon-button
                  (click)="taskItem.isCollapsed = !taskItem.isCollapsed"
                  [attr.aria-expanded]="!taskItem.isCollapsed" aria-controls="collapseExample">
                      <span *ngIf="taskItem.isCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                      <span *ngIf="!taskItem.isCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
              </button> 
          </div>
          <div class="col-lg-11 col-xs-9" style="margin-top: 10px;" >
            <p style="margin: 0px;" ><b>{{ taskItem.taskLabel }}</b>             
            </p>
          </div>
        </div>
        <div  [ngbCollapse]="taskItem.isCollapsed">
          <div *ngTemplateOutlet="photosSequences; context: {isCollapsed: taskItem.isCollapsed, photosSequences: taskItem.photosSequences}"></div>
        </div>
      </fieldset>
    </div> <!-- end for Tasks-->
  </div>
</ng-template>
<ng-template #photosSequences let-isCollapsed="isCollapsed" let-photosSequences="photosSequences" >
  <div *ngIf="!isCollapsed">  
    <div  *ngFor= "let sequenceItem of photosSequences; let iSequence=index; let c=count;let isLast=last">
      <fieldset class="rowTask-border" style="margin: 0px; padding: 0px;margin-left: 15px;margin-right: 10px">
        <div id="photosSequences_{{iSequence}}" #photosSequences_{{iSequence}} class="row rowTask" style="line-height: 30px;margin-left: 0px;margin-right: 0px;" >
          <div class="col-lg-1 col-xs-1" style="padding-right: 0px;padding-left: 0px;">
              <button mat-icon-button
                  (click)="sequenceItem.isCollapsed = !sequenceItem.isCollapsed"
                  [attr.aria-expanded]="!sequenceItem.isCollapsed" aria-controls="collapseExample">
                      <span *ngIf="sequenceItem.isCollapsed" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                      <span *ngIf="!sequenceItem.isCollapsed" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
              </button> 
          </div>
          <div class="col-lg-11 col-xs-9" style="margin-top: 10px;" >
            <p style="margin: 0px;" ><b>{{ sequenceItem.sequenceAtt | translate }}</b>             
            </p>
          </div>
        </div>
        <div  [ngbCollapse]="sequenceItem.isCollapsed">
          <div *ngTemplateOutlet="photosSequence; context: {isCollapsed: sequenceItem.isCollapsed, photosSequence: sequenceItem.photosSequence}"></div>
        </div>
      </fieldset>
    </div> <!-- end for Sequences -->
  </div>
</ng-template>
<ng-template #photosSequence let-isCollapsed="isCollapsed" let-photosSequence="photosSequence" >
  <div *ngIf="!isCollapsed">  
    <div  *ngFor= "let photosSequenceItem of photosSequence; let iPhotosSequence=index; let c=count;let isLast=last">
      <fieldset class="rowTask-border" style="margin: 0px; padding: 0px;margin-left: 15px;margin-right: 10px" [class.data-selected]="photosSequenceItem.sequenceDateTime === dateTimeSequenceSelected">
        <div id="photosSequence_{{iPhotosSequence}}" #photosSequence_{{iPhotosSequence}} class="row rowTask" style="line-height: 30px;margin-left: 0px;margin-right: 0px;" 
              [class.data-selected]="photosSequenceItem.sequenceDateTime === dateTimeSequenceSelected">
          <div class="col-lg-11 col-xs-9"  [class.data-selected]="photosSequenceItem.sequenceDateTime === dateTimeSequenceSelected" >
            <p style="margin: 0px;" 
            (click)="initViewerList(photosSequenceItem.filesToDisplay, photosSequenceItem.sequenceDateTime)"
            >
            <b>{{ photosSequenceItem.sequenceDateTimeLabel | translate }}</b>             
            </p>
          </div>
        </div>
        <!--
        <div  [ngbCollapse]="photosSequenceItem.isCollapsed">
          <div *ngTemplateOutlet="fileToDisplay; context: {isCollapsed: photosSequenceItem.isCollapsed, filesToDisplay: photosSequenceItem.filesToDisplay}"></div>
        </div>
        -->
      </fieldset>
    </div> <!-- end for photosSequence -->
  </div>
</ng-template>
<ng-template #fileToDisplay let-isCollapsed="isCollapsed" let-filesToDisplay="filesToDisplay" >
  <div *ngIf="!isCollapsed">  
    <div  *ngFor= "let fileToDisplayItem of filesToDisplay; let ifileToDisplay=index; let c=count;let isLast=last">
      <fieldset class="rowTask-border" style="margin: 0px; padding: 0px;margin-left: 15px;margin-right: 10px">
        <div id="fileToDisplay_{{ifileToDisplay}}" #fileToDisplay_{{ifileToDisplay}} class="row rowTask" style="line-height: 30px;margin-left: 0px;margin-right: 0px;" >

          <div class="col-lg-9 col-xs-9" style="margin-top: 10px;" >
            <p style="margin: 0px;" class="lineTask"><b>{{ fileToDisplayItem.fileNameFormatted  }}</b>             
            </p>
          </div>
        </div>
      </fieldset>
    </div> <!-- end for photosSequence -->
  </div>
</ng-template>