/*
Start JLG - 2021-05-28 - "date-fns": "^2.22.0", :
cette version de la date-fns comporte un bug, il faut effacer des lignes dans le module typings.d.ts dans les declaration de type pour les variables :
  const daysInWeek: number
  const maxTime: number.
  Elles sont en double.
End JLG - 2021-05-28
github source :
  https://github.com/mattlewis92/angular-calendar/tree/master/projects/angular-calendar
module inspiré de l'exemple suivant :
  https://stackblitz.com/edit/angular-calendar?file=demo%2Ftemplate.html
pour la langue  :
  https://stackblitz.com/edit/angular-6su8pq
autres exemples :
  https://github.com/mattlewis92/angular-calendar
  https://angular-calendar.com/#/kitchen-sink

  https://stackblitz.com/edit/angular-ukwpj8?file=demo%2Ftemplate.html
Personnalisation :
  https://angular-calendar.com/docs/components/CalendarMonthViewComponent.html#source
  https://stackblitz.com/edit/angular-hn2scb?file=demo%2Ftemplate.html
@input template :
  https://blog.angulartraining.com/how-to-pass-a-custom-template-to-an-angular-component-53592d634a47
*/
import { TemplateRef, OnChanges, SimpleChanges, NgZone, EventEmitter } from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { isSameDay, isSameMonth } from 'date-fns';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import { GridTools } from '../../../../../job/common/tools/gridTools';
import { TranslateService } from '../../../../../services/translate.service';
import { CalendarService } from '../../../../common/services/calendar.service';
import { CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, CalendarMonthViewBeforeRenderEvent, CalendarWeekViewBeforeRenderEvent, CalendarDayViewBeforeRenderEvent, DAYS_OF_WEEK } from 'angular-calendar';
import { ModalCommonComponent } from '../../../../../job/common/components/modal-common/modal-common.component';
const ɵ0 = MAT_MOMENT_DATE_FORMATS;
/*
const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};
*/
export class CalendarComponent {
    constructor(fb, modalService, _translateService, adapterDate, ngZone, dialog, _calendarService) {
        this.fb = fb;
        this.modalService = modalService;
        this._translateService = _translateService;
        this.adapterDate = adapterDate;
        this.ngZone = ngZone;
        this.dialog = dialog;
        this._calendarService = _calendarService;
        this.dataOut = new EventEmitter();
        this.displayRequest = new EventEmitter();
        this.eventClicked = new EventEmitter();
        this.highlightDay = new EventEmitter();
        this.unhighlightDay = new EventEmitter();
        this.refresh = new Subject();
        this.tooltipPlacement = 'auto';
        this.onDestroy = new Subject();
        this.segmentTimesMap = new Map();
        this.now = new Date();
        this.yearCur = this.now.getFullYear();
        this.monthCur = this.now.getMonth();
        this.dayCur = this.now.getDay();
        // validateStartTime: any;
        // validateEndTime: any;
        this.view = CalendarView.Month;
        this.CalendarView = CalendarView;
        this.viewDate = new Date();
        this.configModal = {
            class: 'modal-dialog-centered modal-dialog-std',
            backdrop: true,
            ignoreBackdropClick: true,
            animated: true,
            size: 'lg'
        };
        this.weekStartsOn = DAYS_OF_WEEK.MONDAY;
        this.labelEdit = this._translateService.instant('editAppointment');
        this.labelDelete = this._translateService.instant('deleteAppointment');
        this.labelInfos = this._translateService.instant('detailAppointment');
        this.labelAdd = this._translateService.instant('appointmentNewDate');
        this.weekendDays = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];
        this.actionsRh = [
            {
                label: '<i class="fa fa-fw fa-pencil" title="' + this.labelEdit + '"></i>',
                onClick: ({ event }) => {
                    this.handleEvent('Edited', event);
                }
            },
            {
                label: '<i class="fa fa-fw fa-times" title="' + this.labelDelete + ' "></i>',
                onClick: ({ event }) => {
                    this.events = this.events.filter(iEvent => iEvent !== event);
                    this.handleEvent('Deleted', event);
                }
            }
        ];
        this.actions = [
            {
                label: '<i class="fa fa-fw fa-pencil" title="' + this.labelEdit + '"></i>',
                onClick: ({ event }) => {
                    this.handleEvent('Edited', event);
                }
            },
            {
                label: '<i class="fa fa-fw fa-times" title="' + this.labelDelete + ' "></i>',
                onClick: ({ event }) => {
                    // this.events = this.events.filter(iEvent => iEvent !== event);
                    this.handleEvent('Deleted', event);
                }
            },
            {
                label: '<i class="fas fa-info-circle" title="' + this.labelInfos + ' "></i>',
                onClick: ({ event }) => {
                    this.handleEvent('Detail', event);
                }
            },
            {
                label: '<i class="fa fa-fw fa-calendar-plus" title="' + this.labelAdd + ' "></i>',
                onClick: ({ event }) => {
                    this.handleEvent('NewDate', event);
                }
            }
        ];
        this.actionsNew = [
            {
                label: '<i class="fa fa-fw fa-calendar-plus" title="' + this.labelAdd + ' "></i>',
                onClick: ({ event }) => {
                    this.handleEvent('Added', event);
                }
            }
        ];
        this.filterDate = (d) => {
            // console.log('Filter is called');
            const day = moment(d).day();
            // const day = d.getDay();
            return day !== this.weekendDays[0] && day !== this.weekendDays[1];
            // return true;
            // 0 means sunday
            // 6 means saturday
        };
        // this.locale = 'fr';
        // https://www.tektutorialshub.com/angular/angular-pass-data-to-route/
        // console.log(this.router.getCurrentNavigation());
    }
    triggerResize() {
        // Wait for changes to be applied, then trigger textarea resize.
        this.ngZone.onStable.pipe(take(1))
            .subscribe(() => this.eventDescription.resizeToFitContent(true));
    }
    ngOnChanges(changes) {
        const listKey = Object.keys(changes);
        for (const propName of listKey) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'applicationCall': {
                        // tslint:disable-next-line:no-string-literal
                        this.applicationCall = changes['applicationCall'].currentValue;
                        break;
                    }
                    case 'localeDateFmt': {
                        // tslint:disable-next-line:no-string-literal
                        this.localeDateFmt = changes['localeDateFmt'].currentValue;
                        break;
                    }
                    case 'localelang': {
                        // tslint:disable-next-line:no-string-literal
                        this.localelang = changes['localelang'].currentValue;
                        break;
                    }
                    case 'events': {
                        // tslint:disable-next-line:no-string-literal
                        this.events = changes['events'].currentValue;
                        if ((this.events !== undefined) && (this.events.length > 0)) {
                            this.segmentTimesStorage();
                            this.addActionsAndColors();
                        }
                        break;
                    }
                    case 'dateTimeFormat': {
                        // tslint:disable-next-line:no-string-literal
                        this.dateTimeFormat = changes['dateTimeFormat'].currentValue;
                        break;
                    }
                    // workingTime
                    case 'workingTime': {
                        // tslint:disable-next-line:no-string-literal
                        this.workingTime = changes['workingTime'].currentValue;
                        break;
                    }
                    // modalMode
                    case 'modalMode': {
                        // tslint:disable-next-line:no-string-literal
                        this.modalMode = changes['modalMode'].currentValue;
                        break;
                    }
                } // end switch
            } // end if
        }
        this.initData();
    }
    initData() {
        // https://www.tektutorialshub.com/angular/angular-pass-data-to-route/
        /* this._currentRoute.paramMap.subscribe(params => {
          this.optionsRouter = params.get('display');
        });
        this._currentRoute.data.subscribe(data => {
          this.optionsRouter = data;
        });*/
        // workingTime
        this.weekStartsOn = this.workingTime.weekStartsOn;
        this.weekendDays = this.workingTime.weekendDays;
        this.addEventOK = false;
        this.updateEventOK = false;
        this.deleteEventOK = false;
        this.displayPopover = false;
        this.dayHours = this.workingTime.dayHours;
        this.settingHoursAndDays();
        // this.activeDayIsOpen = true;
        // this.daysWeek = [0, 6];
        this.minInputDate = new Date(); // moment({year: this.yearCur, month: this.monthCur , day: this.dayCur});
        this.maxInputDate = new Date();
        this.maxInputDate.setDate(60);
        this.adapterDate.setLocale(this.localeDateFmt);
        this.locale = this.localelang;
    }
    settingHoursAndDays() {
        let timeHour = this.dayHours[0].split(':')[0];
        this.hourStart = parseInt(timeHour, 10);
        timeHour = this.dayHours[1].split(':')[0];
        this.hourEnd = parseInt(timeHour, 10);
        this.daysWeek = [];
        for (let dayNum = 0; dayNum < 7; dayNum++) {
            let isFound = false;
            for (let weekEnd = 0; weekEnd < this.weekendDays.length; weekEnd++) {
                if (this.weekendDays[weekEnd] === dayNum) {
                    isFound = true;
                }
            }
            if (!isFound) {
                this.daysWeek.push(dayNum);
            }
        }
    }
    addActionsAndColors() {
        for (const eventCur of this.events) {
            eventCur.actions = ((this.applicationCall === eventCur.calType && (eventCur.calType === 'appointmentCustomer'
                || eventCur.calType === 'appointmentWork')) ? this.actions :
                (this.applicationCall === eventCur.calType && eventCur.calType === 'rhPlanning') ? this.actionsRh : undefined);
            eventCur.color = this.getColorType(eventCur.calType);
        }
    }
    getColorType(typeCur) {
        const colorCur = {
            primary: '',
            secondary: '',
        };
        for (const calEvent of this.calendarEvents) {
            if (calEvent.type === typeCur) {
                colorCur.primary = calEvent.primary;
                colorCur.secondary = calEvent.secondary;
                break;
            }
        }
        return colorCur;
    }
    segmentTimesStorage() {
        this.segmentTimesMap = new Map();
        for (const eventCur of this.events) {
            this.updateSegmentTimes(eventCur);
        }
    }
    // https://angular-calendar.com/#/before-view-render
    beforeMonthViewRender(renderEvent) {
        renderEvent.body.forEach((day) => {
            const dayOfMonth = day.date.getDate();
            day.cssClass = 'localTheme';
            if (day.isToday) {
                // todayLoc
                day.cssClass = 'todayLoc';
            }
        });
    }
    beforeWeekViewRender(renderEvent) {
        renderEvent.hourColumns.forEach((hourColumn) => {
            if (this.isToday(hourColumn.date.getDate(), hourColumn.date.getMonth(), hourColumn.date.getFullYear())) {
                hourColumn.hours.forEach((hour) => {
                    hour.segments.forEach((segment) => {
                        segment.cssClass = 'todayLoc';
                    });
                });
            }
            else {
                hourColumn.hours.forEach((hour) => {
                    hour.segments.forEach((segment) => {
                        segment.cssClass = 'localTheme';
                    });
                });
            }
        });
    }
    beforeDayViewRender(renderEvent) {
        renderEvent.hourColumns.forEach((hourColumn) => {
            hourColumn.hours.forEach((hour) => {
                hour.segments.forEach((segment) => {
                    segment.cssClass = 'localTheme';
                });
            });
        });
    }
    dayClicked({ date, events }) {
        this.eventsOfDay = events;
        if (isSameMonth(date, this.viewDate)) {
            this.viewDate = date;
            // if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true)) {
                this.activeDayIsOpen = false;
            }
            else {
                if (events.length > 0) {
                    this.activeDayIsOpen = true;
                }
                if ((events.length === 0) || (events[events.length - 1].title === 'New event')) {
                    this.addEvent(date);
                }
            }
        }
    }
    hourSegmentClicked({ date, events }) {
        // console.log('hourSegmentClicked : ' + date );
        if (isSameMonth(date, this.viewDate)) {
            this.viewDate = date;
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0) {
                this.activeDayIsOpen = false;
            }
            else {
                this.activeDayIsOpen = true;
            }
        }
    }
    isToday(day, month, year) {
        const today = new Date();
        return day === today.getDate() &&
            month === today.getMonth() &&
            year === today.getFullYear();
    }
    eventTimesChanged({ event, newStart, newEnd }) {
        const eventTmp = JSON.stringify(event);
        const eventCur = JSON.parse(eventTmp);
        if (eventCur.calType === 'appointmentCustomer' || eventCur.calType === 'appointmentWork') {
            event.start = newStart;
            event.end = newEnd;
            this.handleEvent('TimeChanged', event);
        }
        this.refresh.next();
    }
    handleEvent(action, event) {
        this.modalData = { event, action };
        this.eventCur = event;
        event.action = action.toLowerCase();
        if (action === 'Detail') {
            const eventCur = {
                eventCur: this.eventCur,
                action: 'displayRequest',
            };
            this.displayRequest.emit(eventCur);
            return;
        }
        if (action === 'Edited') {
            // this.dataUpdate.emit(event);
            this.buildEventDetailForm('edit');
            this.fillFormEvent();
            this.openModal();
            this.addEventOK = false;
            this.updateEventOK = true;
            this.deleteEventOK = false;
        }
        if (action === 'Deleted') {
            const idxRow = event.id;
            this.addEventOK = false;
            this.updateEventOK = false;
            this.deleteEventOK = true;
            event.bddAction = 'deleted';
            // this.dataUpdate.emit(event);
            this.confirmDeleteEvent(event, idxRow);
        }
        // NewDate
        if (action === 'NewDate') {
            if (!this.modalMode) {
                this.addEventOK = false;
                this.updateEventOK = true;
                this.deleteEventOK = false;
                const eventCur = {
                    eventCur: event,
                    action: 'NewDate',
                };
                this.refresh.next();
                this.eventClicked.emit(eventCur);
            }
        }
        if (action === 'TimeChanged') {
            const idxRow = event.id;
            this.addEventOK = false;
            this.updateEventOK = true;
            this.deleteEventOK = false;
            this.refresh.next();
            event.bddAction = 'changed';
            this.confirmUpdateEvent(event);
        }
    }
    closePopover() {
        const eventCur = {
            eventCur: undefined,
            action: 'closePopover',
        };
        this.displayRequest.emit(eventCur);
    }
    confirmDeleteEvent(eventCur, idxRow) {
        const titleBox = this._translateService.instant('appointmentDelete');
        let messageBox;
        let data1;
        let data2;
        messageBox = this._translateService.instant('referencedRequest') + eventCur.ref + ' ' +
            this._translateService.instant('scheduledFor') + ' ' +
            moment(eventCur.start).format(this.dateTimeFormat) +
            this._translateService.instant('appointmentSeparator') +
            moment(eventCur.end).format(this.dateTimeFormat) + ' ';
        data1 = idxRow;
        data2 = undefined;
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'deleted', eventCur, data1, data2);
    }
    confirmUpdateEvent(eventCur) {
        const titleBox = this._translateService.instant('appointmentChangeTime');
        let messageBox;
        let data1;
        let data2;
        const time1 = moment(eventCur.oldTime.start).format(this.dateTimeFormat) + ' ' +
            moment(eventCur.oldTime.end).format(this.dateTimeFormat);
        const time2 = moment(eventCur.start).format(this.dateTimeFormat) + ' ' +
            moment(eventCur.end).format(this.dateTimeFormat);
        if (time1 === time2) {
            return;
        }
        messageBox = this._translateService.instant('appointmentimetable');
        data1 = this._translateService.instant('appointmentOldTime') + ' ' +
            moment(eventCur.oldTime.start).format(this.dateTimeFormat) +
            this._translateService.instant('appointmentSeparator') +
            moment(eventCur.oldTime.end).format(this.dateTimeFormat) + ' ';
        data2 = this._translateService.instant('appointmentNewTime') + ' ' +
            moment(eventCur.start).format(this.dateTimeFormat) +
            this._translateService.instant('appointmentSeparator') +
            moment(eventCur.end).format(this.dateTimeFormat) + ' ';
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'TimeChanged', eventCur, data1, data2);
    }
    updateSegmentTimes(eventCur) {
        const dateStartTime = moment(eventCur.start).format('HH:mm').split(':');
        const dateEndTime = moment(eventCur.end).format('HH:mm').split(':');
        // console.log( 'dateStartTime : ' + dateStartTime + ' dateEndTime : ' + dateEndTime);
        const dateCur = moment(eventCur.start).format('YYYY-MM-DD');
        // console.log( 'dateCur : ' + dateCur +  ' dateStartTime : ' + dateStartTime + ' dateEndTime : ' + dateEndTime);
        const startHour = Number(dateStartTime[0]);
        const startMinutes = Number(dateStartTime[1]);
        const endHour = Number(dateEndTime[0]);
        const endMinutes = Number(dateEndTime[1]);
        let hours = startHour - 1;
        const minutes = startMinutes;
        let minutesEnd = 60;
        do {
            hours += 1;
            if (hours === endHour) {
                minutesEnd = endMinutes + 5;
            }
            const formattedHour = ('0' + hours).slice(-2);
            // console.log(formattedHour);
            for (let minuteCur = minutes; minuteCur < minutesEnd; minuteCur += 5) {
                const formattedTime = dateCur + ' ' + ('0' + hours).slice(-2) + ':' + ('0' + minuteCur).slice(-2);
                this.segmentTimesMap.set(formattedTime, eventCur.ref);
                // console.log(formattedTime);
            }
            if (hours === endHour) {
                break;
            }
        } while (hours < endHour + 1);
    }
    addEvent(dateCur) {
        const currentDate = dateCur ? dateCur : new Date();
        if (currentDate < this.minInputDate) {
            return;
        }
        const currentDateStart = new Date(currentDate.setHours(this.hourStart, 0, 0, 0));
        const currentDateEnd = new Date(currentDate.setHours(this.hourEnd, 0, 0, 0));
        let linkIdCur = '';
        this.calObject = this._calendarService.getCalObject();
        const titleCur = 'New event';
        let outsideEvent;
        if ((this.applicationCall === 'appointmentCustomer' || this.applicationCall === 'appointmentWork')
            && (this.calObject === undefined)) {
            return;
        }
        if ((this.applicationCall === 'appointmentCustomer' || this.applicationCall === 'appointmentWork')) {
            outsideEvent = this.calObject.customer;
        }
        if (this.calObject) {
            linkIdCur = this.calObject.id;
        }
        this.eventCur = {
            id: this.events.length,
            title: titleCur,
            // start: startOfDay(currentDateStart),
            // end: endOfDay(currentDateEnd),
            start: currentDateStart,
            end: currentDateEnd,
            color: this.getColorType(this.applicationCall),
            actions: this.actionsNew,
            draggable: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            linkId: linkIdCur,
            calType: this.applicationCall,
            ref: (this.applicationCall === 'appointmentCustomer') ? this.calObject.appointmentRef : this.applicationCall === 'appointmentWork' ? this.calObject.workRef : '',
            outside: outsideEvent,
        };
        this.buildEventDetailForm('new');
        this.fillFormEvent();
        this.activeDayIsOpen = false;
        this.openModal();
        this.addEventOK = true;
        this.updateEventOK = false;
        this.deleteEventOK = false;
    }
    changeTime(option, $event) {
        // console.log('changeTime : ' + $event);
        if (option === 'start') {
            const calDuration = moment.duration(moment(new Date(this.eventCur.start)).diff(moment(new Date(this.eventCur.end)))).asMinutes() * -1;
            const dateStart = moment(this.detailEventForm.controls.eventStartDate.value).format('YYYY-MM-DD') + ' ' + $event;
            const newEndDate = moment(dateStart).add(calDuration, 'minutes');
            // this.detailEventForm.controls.eventEndDate.setValue(newEndDate);
            // this.detailEventForm.controls.eventEndTime.setValue(newEndTime);
            const hourCtrl = this.validHour(newEndDate);
            const hourCur = moment(hourCtrl).format('HH:mm');
            this.detailEventForm.controls['eventEndDate'].setValue(moment(newEndDate).toDate());
            // this.detailEventForm.controls['eventEndTime'].setValue(moment(newEndDate).format('HH:mm'));
            this.detailEventForm.controls['eventEndTime'].setValue(hourCur);
        }
    }
    onChangeTitle(option, $event) {
        // console.log($event);
        if (option === 'appointmentCustomer' || option === 'appointmentWork') {
            return;
        }
        if (option === 'rhPlanning') {
            for (const eventRhCur of this.eventsRh) {
                if (eventRhCur.label === $event) {
                    this.detailEventForm.controls.eventType.setValue(eventRhCur.type);
                    break;
                }
            }
        }
    }
    validTimeInput(timeOpt, $event, displayError) {
        // console.log('validTimeInput : ' + $event);
        const dateCur = moment(this.viewDate).format('YYYY-MM-DD');
        let hourCur;
        hourCur = moment($event).format('HH:mm');
        if (hourCur === 'Invalid date') {
            hourCur = $event;
        }
        const hourDate = dateCur + ' ' + hourCur;
        let timeIsValid = true;
        if (!this.compareSegmentHours(this.eventCur.ref, hourDate)) {
            timeIsValid = false;
            if (timeOpt === 'start' && displayError) {
                this.errorMessage('startTime');
            }
            if (timeOpt === 'end' && displayError) {
                this.errorMessage('endTime');
            }
        }
        return timeIsValid;
    }
    compareSegmentHours(refAppointment, hourDate) {
        let isValid = false;
        const exitsHour = this.segmentTimesMap.has(hourDate);
        if (exitsHour) {
            const ref = this.segmentTimesMap.get(hourDate);
            if (ref === refAppointment) {
                isValid = true;
            }
        }
        else {
            isValid = true;
        }
        return isValid;
    }
    fillFormEvent() {
        this.detailEventForm.controls.eventType.setValue(this._translateService.getTranslate(this.applicationCall));
        let workCur;
        if (this.applicationCall === 'appointmentWork') {
            if (this.eventCur.calObject !== undefined) {
                workCur = this.eventCur.calObject;
            }
            else {
                workCur = this._calendarService.getCalObject();
            }
            this.dataLocationWork = {
                typeLocation: (workCur.workLocation ? workCur.workLocation : undefined),
                dataLocation: (workCur.workLocationData ? JSON.parse(workCur.workLocationData) : undefined)
            };
        }
        if (this.eventCur.title !== 'New event') {
            this.detailEventForm.controls.eventTitle.setValue(this.eventCur.title);
            this.idxList = parseInt(this.eventCur.id.toString(), 10);
        }
        else {
            if (this.applicationCall === 'appointmentWork') {
                this.detailEventForm.controls.eventTitle.setValue(workCur.workObject);
            }
            else {
                this.detailEventForm.controls.eventTitle.setValue('');
            }
            this.idxList = this.events.length;
        }
        this.detailEventForm.controls.eventDescription.setValue(this.eventCur.description);
        this.detailEventForm.controls.eventComment.setValue(this.eventCur.comment);
        this.detailEventForm.controls.eventOutside.setValue(this.eventCur.outside);
        this.detailEventForm.controls.eventStartDate.setValue(this.eventCur.start);
        this.detailEventForm.controls.eventEndDate.setValue(this.eventCur.end);
        let validateStartTime = moment(this.eventCur.start).format('HH:mm');
        validateStartTime = this.validHour(validateStartTime);
        let validateEndTime = moment(this.eventCur.end).format('HH:mm');
        validateEndTime = this.validHour(validateEndTime);
        this.detailEventForm.controls.eventStartTime.setValue(validateStartTime.format('HH:mm'));
        this.detailEventForm.controls.eventEndTime.setValue(validateEndTime.format('HH:mm'));
    }
    fillModelEvent() {
        const calObject = this._calendarService.getCalObject();
        let startDate = moment(this.detailEventForm.controls.eventStartDate.value).format('YYYY-MM-DD');
        startDate += ' ' + this.detailEventForm.controls.eventStartTime.value;
        let endDate = moment(this.detailEventForm.controls.eventEndDate.value).format('YYYY-MM-DD');
        endDate += ' ' + this.detailEventForm.controls.eventEndTime.value;
        const idxRow = this.eventCur.id;
        let refCur = '';
        let linkIdCur = '';
        if (this.applicationCall === 'appointmentCustomer' || this.applicationCall === 'appointmentWork') {
            if (calObject && calObject.id) {
                linkIdCur = calObject.id;
                refCur = (this.applicationCall === 'appointmentCustomer' || this.applicationCall === 'appointmentWork') ? calObject.appointmentRef : '';
            }
            else {
                linkIdCur = this.eventCur.linkId;
                refCur = this.eventCur.ref;
            }
        }
        if (this.applicationCall === 'rhPlanning') {
            refCur = 'HR';
        }
        let bddActionCur = 'add';
        if (this.updateEventOK) {
            bddActionCur = 'updated';
        }
        this.eventCur = {
            id: this.idxList,
            start: new Date(startDate),
            end: new Date(endDate),
            title: this.detailEventForm.controls.eventTitle.value,
            color: this.getColorType(this.applicationCall),
            actions: (this.applicationCall === this.eventCur.calType ? this.actions : undefined),
            draggable: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            allDay: false,
            linkId: linkIdCur,
            description: this.detailEventForm.controls.eventDescription.value,
            comment: this.detailEventForm.controls.eventComment.value,
            calType: this.applicationCall,
            // calStatut: (this.eventCur.calStatut === '' ? 'WAITING' : this.eventCur.calStatut),
            calStatut: 'FIXED',
            outside: this.detailEventForm.controls.eventOutside.value,
            ref: refCur,
            stdCreationDate: this.eventCur.stdCreationDate,
            stdCreationUser: this.eventCur.stdCreationUser,
            bddAction: bddActionCur,
            bddId: this.eventCur.bddId
        };
        // this.dataOut.emit(eventModel);
        // this.events.splice(idxRow, 1);
        this.activeDayIsOpen = true;
        if (!this.addEventOK) {
            this.events.splice(idxRow, 1);
        }
        this.events.push(this.eventCur);
        this.refresh.next();
        this._calendarService.setCalObject(undefined);
    }
    buildEventDetailForm(statut) {
        const isReadOnly = (statut === 'read' ? true : false);
        this.detailEventForm = this.fb.group({
            eventType: this.fb.control({ value: '', disabled: isReadOnly }),
            eventTitle: this.fb.control({ value: '', disabled: isReadOnly }),
            eventDescription: this.fb.control({ value: '', disabled: isReadOnly }),
            eventComment: this.fb.control({ value: '', disabled: isReadOnly }),
            eventOutside: this.fb.control({ value: '', disabled: isReadOnly }),
            eventStartDate: this.fb.control({ value: '', disabled: isReadOnly }),
            eventStartTime: this.fb.control({ value: '', disabled: isReadOnly }),
            eventEndDate: this.fb.control({ value: '', disabled: isReadOnly }),
            eventEndTime: this.fb.control({ value: '', disabled: isReadOnly }),
        });
    }
    openModal() {
        this.modalRef = this.modalService.show(this.modalDetailEvent, this.configModal);
    }
    closeModal(status) {
        if (this.modalRef !== undefined) {
            this.modalRef.hide();
        }
        if (status === 'validate') {
            this.activeDayIsOpen = true;
        }
        if (status === 'canceled') {
            if (this.eventCur.title === 'New event' && this.eventCur.linkId === undefined) {
                this.eventCur = undefined;
            }
            this.activeDayIsOpen = false;
            if (this.eventsOfDay.length > 0) {
                this.activeDayIsOpen = true;
            }
        }
    }
    validStatutDate(event, fieldName) {
        const dateInput = moment(event.value);
        const okDate = dateInput.isBetween(this.minInputDate, this.maxInputDate);
        if (okDate) {
            if (fieldName === 'eventStartDate') {
                const dateStartTime = moment(event.value).format('YYYY-MM-DD') + ' ' + moment(this.eventCur.start).format('HH:mm');
                const calDuration = moment.duration(moment(new Date(this.eventCur.start)).diff(moment(new Date(this.eventCur.end)))).asMinutes() * -1;
                // const dateStart = moment(this.eventCur.start).format('YYYY-MM-DD HH:mm');
                const newEndDate = moment(dateStartTime).add(calDuration, 'minutes');
                const newEnd = new Date(newEndDate.toString());
                const newStart = new Date(dateStartTime.toString());
                // this.eventCur.end =  moment(newEndDate).toDate();
                // this.eventCur.start = moment(dateStartTime).toDate();
                // this.fillFormEvent();
                this.detailEventForm.controls['eventStartDate'].setValue(newStart);
                this.detailEventForm.controls['eventEndDate'].setValue(newEnd);
                this.detailEventForm.controls['eventEndTime'].setValue(moment(newEndDate).format('HH:mm'));
                this.ctrlEventTime(true);
            }
            else {
                // this.eventCur.end =  moment(dateInput).toDate();
                this.detailEventForm.controls[fieldName].setValue(moment(event.value).toDate());
            }
        }
        else {
            this.detailEventForm.controls[fieldName].setValue('');
        }
    }
    validHour(hourOrig) {
        let hourRef = moment(hourOrig, 'HH:mm');
        if (hourRef.isBefore(moment(this.dayHours[0], 'HH:mm'))) {
            // hourRef.set('hours', this.dayHours[0]);
            hourRef = moment(this.dayHours[0], 'HH:mm');
        }
        if (hourRef.isAfter(moment(this.dayHours[1], 'HH:mm'))) {
            // hourRef.set('hours', this.dayHours[1]);
            hourRef = moment(this.dayHours[1], 'HH:mm');
        }
        return hourRef;
    }
    validHourEnd(hourEnd, hourStart, intervalMini) {
        const hourRef = moment(hourStart);
        if (intervalMini !== undefined) {
            hourRef.add(intervalMini, 'hours');
        }
        if (hourEnd.before(hourRef)) {
            hourEnd.set('hours', hourRef);
        }
        return hourEnd;
    }
    getDateTimeToString(_value) {
        return GridTools.date_json1(_value, this.dateTimeFormat);
    }
    getEventItem(_itemCur) {
        let currentHTML;
        let curTitle = _itemCur.title;
        if ((this.applicationCall === 'rhPlanning') || (_itemCur.calType === 'rhPlanning')) {
            curTitle = this._translateService.getTranslate(_itemCur.title);
        }
        if (curTitle === undefined) {
            curTitle = _itemCur.title;
        }
        if (_itemCur.ref !== undefined) {
            curTitle = curTitle = _itemCur.ref + ' ' + curTitle;
        }
        const startDate = moment(_itemCur.start).format('YYYY-MM-DD HH:mm');
        const endDate = moment(_itemCur.end).format('YYYY-MM-DD HH:mm');
        currentHTML = this.getDateTimeToString(startDate) + ' ---> '
            + this.getDateTimeToString(endDate) + ' ---> ';
        if (_itemCur.outside) {
            currentHTML += _itemCur.outside + ' ---> ';
        }
        currentHTML += curTitle;
        return currentHTML;
    }
    ctrlEventTime(displayError) {
        let startDate = moment(this.detailEventForm.controls.eventStartDate.value).format('YYYY-MM-DD');
        startDate += ' ' + this.detailEventForm.controls.eventStartTime.value;
        let endDate = moment(this.detailEventForm.controls.eventEndDate.value).format('YYYY-MM-DD');
        endDate += ' ' + this.detailEventForm.controls.eventEndTime.value;
        let dateInput;
        dateInput = moment(this.detailEventForm.controls.eventStartDate.value).format('YYYY-MM-DD');
        if (this.validTimeInput('start', new Date(startDate), displayError)) {
            dateInput = moment(this.detailEventForm.controls.eventEndDate.value).format('YYYY-MM-DD');
            if (!this.validTimeInput('end', new Date(endDate), displayError)) {
                // this.errorMessage('endTime' );
                return false;
            }
        }
        else {
            // this.errorMessage('startTime' );
            return false;
        }
        return true;
    }
    validEvent() {
        if (!this.ctrlEventTime(true)) {
            return;
        }
        this.fillModelEvent();
        this.closeModal('validate');
        const eventCur = {
            eventCur: this.eventCur,
            action: 'TimeChanged',
        };
        this.eventClicked.emit(eventCur);
    }
    errorMessage(option) {
        const titleBox = this._translateService.getTranslate('appointmentTimeError');
        let messageBox;
        if (option === 'startTime') {
            messageBox = this._translateService.getTranslate('appointmentStartTimeError');
        }
        if (option === 'endTime') {
            messageBox = this._translateService.getTranslate('appointmentEndTimeError');
        }
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'alertWks', undefined, undefined, undefined, undefined);
    }
    displayMessageBox(titleBoxArg, messageBoxArg, messageTypeArg, typeDialogArg, actionCurArg, event, data1Arg, data2Arg) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            id: 1,
            title: titleBoxArg,
            typeDialog: typeDialogArg,
            panelClass: 'stdTheme',
            contentMessage: messageBoxArg,
            data1: data1Arg,
            data2: data2Arg,
            messageType: messageTypeArg,
            actionCur: actionCurArg
        };
        const dialogRef = this.dialog.open(ModalCommonComponent, dialogConfig);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.onDestroy))
            .subscribe(data => {
            if ((data === 'okAction') && actionCurArg === 'TimeChanged') {
                event.calStatut = 'FIXED';
                const eventCur = {
                    eventCur: event,
                    action: 'TimeChanged',
                };
                this.eventClicked.emit(eventCur);
            }
            if ((data === 'okAction') && actionCurArg === 'deleted') {
                event.calStatut = 'WAITING';
                const eventCur = {
                    eventCur: event,
                    action: 'deleted',
                };
                this.events.splice(parseInt(data1Arg, 10), 1);
                this.refresh.next();
                this.eventClicked.emit(eventCur);
            }
        });
    }
}
export { ɵ0 };
