import {throwError as observableThrowError,  BehaviorSubject ,  Observable, of } from 'rxjs';

import { Injectable, EventEmitter } from '@angular/core';

import { WksBusinessModel, AccountModel } from '../models/wks-business.model';
import { WksEntityModel } from '../models/wks-entity.model';
import { WksEquipmentModel } from '../models/wks-equipment.model';

import { WksWorksInterventionModel, WksWorksModel, WorkQuotation, WksWorksTracking, WorkEquipments} from '../models/wks-works.model';
import { TaskrefJsonModel, TasksActionCompletedModel, WksTasksModel } from '../models/wks-param.model';
import { WksCentralService } from './wks-central.service';
import { LabelValue } from '../../../models/data.model';


@Injectable({
    providedIn: 'root'
})


export class WksWorksService {

    ownerCur: WksBusinessModel;
    entityCur: WksEntityModel;
    mainEquipmentCur: WksEquipmentModel;
    wksOthersWorks: LabelValue[];
    wksWorksIntervention: WksWorksInterventionModel;

    setOwner(_ownerCur: WksBusinessModel) {
        this.ownerCur = _ownerCur;
    }
    getOwner(): WksBusinessModel {
        return this.ownerCur;
    }
    setEntity(_entityCur: WksEntityModel) {
        this.entityCur = _entityCur;
    }
    getEntity(): WksEntityModel {
        return this.entityCur;
    }
    getMainEquipment(): WksEquipmentModel {
        return this.mainEquipmentCur;
    }
    setMainEquipment(_mainEquipmentCur: WksEquipmentModel) {
        this.mainEquipmentCur = _mainEquipmentCur;
    }
    getOthersWorks(): LabelValue[] {
        return this.wksOthersWorks;
    }
    setOthersWorks(_wksOthersWorks: LabelValue[]) {
        this.wksOthersWorks = _wksOthersWorks;
    }
    setWksIntervention( wksWorks: WksWorksModel, owner: WksBusinessModel, account: AccountModel) {

        this.wksWorksIntervention = {
            workIdPrevious: wksWorks.workIdPrevious,
            workObject: wksWorks.workObject,
            workTicket:  wksWorks.workTicket,
            workCriticality: wksWorks.workCriticality,
            workPlanningId: wksWorks.workPlanningId,
            workComment: wksWorks.workComment,
            workLocation: wksWorks.workLocation,
            workLocationData: wksWorks.workLocationData,
            workPosition: wksWorks.workPosition,
            workStatut: wksWorks.workStatut,
            workStatutDate: wksWorks.workStatutDate,
            calendarEventBdd: (wksWorks !== undefined && wksWorks.wksCalendar ? wksWorks.wksCalendar : undefined),
        };
        if ((wksWorks.workJsonQuotation !== null) && (wksWorks.workJsonQuotation !== undefined)) {
            const workQuotation = JSON.parse(wksWorks.workJsonQuotation) as WorkQuotation;
            this.wksWorksIntervention.delayValidity = workQuotation.delayValidity;
            this.wksWorksIntervention.customerConditions = workQuotation.customerConditions;
            if ((workQuotation.specificsFinancials !== null) && (workQuotation.specificsFinancials !== undefined)) {
                this.wksWorksIntervention.specificsFinancials = workQuotation.specificsFinancials;
            } 
            this.wksWorksIntervention.customerFinancials = workQuotation.customerFinancials;
            if ((workQuotation.specificsConditions !== null) && (workQuotation.specificsConditions !== undefined)) {
                this.wksWorksIntervention.specificsConditions = workQuotation.specificsConditions;
            }
        }
    }
    getWksIntervention(): WksWorksInterventionModel {
        return this.wksWorksIntervention;
    }
    fillTracking(workArg: WksWorksModel, usernameArg: string, workStatutArg: string, workCommentArg: string ): WksWorksModel  {
        const wksWorks = JSON.parse(JSON.stringify(workArg));
        let wksWorksTracking: WksWorksTracking[] = [];
        wksWorks.statutChange = true;
        if (wksWorks.workJsonData !== undefined) {
          const workJsonData = JSON.parse(wksWorks.workJsonData);
          if (workJsonData !== null && workJsonData.wksWorksTracking) {
            wksWorksTracking = JSON.parse(JSON.stringify(workJsonData.wksWorksTracking));
          }
        }
    
        wksWorksTracking.push({
          numberTrack: (wksWorksTracking.length === 0) ? 0 : wksWorksTracking.length,
          userLogged: usernameArg,
          recordedTrack: undefined,
          workStatut: workStatutArg,
          workComment: workCommentArg,
          alarmsMes: undefined
          });
        
        wksWorks.wksWorksTracking =  wksWorksTracking;
        return wksWorks;
    }

    /*=========================================================================== valid checkList */

    fillModelTasksCompleted(workCur: WksWorksModel, tasksActionsCompleted: TasksActionCompletedModel[], workEquipments: WorkEquipments , entity: string ): void {
        let workTasksContent: WksTasksModel[];
        workTasksContent = workCur.workTasksContent;

        let tasksActionCompletedTmp: TasksActionCompletedModel[];
        for (const taskCur of workTasksContent) {
        tasksActionCompletedTmp = [];
        let isFound = false;
        for (const taskCompletedCur of tasksActionsCompleted) {
            if (taskCompletedCur.taskrefId === taskCur.taskrefId) {
            tasksActionCompletedTmp.push(taskCompletedCur);
            isFound = true;
            // break;
            }
        }
        if (isFound) {
            const taskrefJsonTmp = JSON.parse( taskCur.taskrefJson) as TaskrefJsonModel;
            taskrefJsonTmp.tasksActionCompleted = tasksActionCompletedTmp;
            taskCur.taskrefJson = JSON.stringify(taskrefJsonTmp);
            // console.log(taskCur.taskrefJson);
        }
        } // end existants tasks on works
        // New task
        for (const taskCompletedCur of tasksActionsCompleted) {
            let isFound = false;
            for (const taskCur of workTasksContent) {
                if (taskCompletedCur.taskrefId === taskCur.taskrefId) {
                isFound = true;
                break;
                }
            }
            if (isFound) {
                continue;
            }
            const newTask: WksTasksModel = {
                id: undefined,
                workId: workCur.id,
                equipType: taskCompletedCur.equipType,
                equipBrand: taskCompletedCur.equipBrand,
                equipModel: taskCompletedCur.equipModel,
                stdEntity: entity,
                taskrefLabel: taskCompletedCur.taskrefLabel,
                taskrefId: taskCompletedCur.taskrefId,
                taskrefJson: undefined,
                taskrefRow: 'work',
                taskrefSN: undefined
            };
            let taskrefJsonTmp: TaskrefJsonModel;
            tasksActionCompletedTmp = [];
            tasksActionCompletedTmp.push(taskCompletedCur);
            taskrefJsonTmp = {
                tasksActionsContent: undefined,
                tasksActionsParentContent: undefined,
                tasksActionCompleted: tasksActionCompletedTmp
            };
            newTask.taskrefJson = JSON.stringify(taskrefJsonTmp);
            workCur.workTasksContent.push(newTask);
        }
        for (const taskCompletedCur of tasksActionsCompleted) {
            // SN and hours equipment
            // Main equipment
            if (taskCompletedCur.equipId === workEquipments.mainEquipment.id) {
                if (taskCompletedCur.equipSN !== undefined
                        && taskCompletedCur.equipSN !== ''
                        && taskCompletedCur.equipSN !== null  ) {
                    workEquipments.mainEquipment.equipSN = taskCompletedCur.equipSN;
                }
                if (taskCompletedCur.equipHours !== undefined
                && taskCompletedCur.equipHours !== 0
                && taskCompletedCur.equipHours !== null  ) {
                    workEquipments.mainEquipment.equipHours = taskCompletedCur.equipHours;
                }
            }
            // sub equipments
            if (workEquipments.subEquipments) {
                for (const equipCur of workEquipments.subEquipments) {
                    if (taskCompletedCur.equipId === equipCur.id) {
                        if (taskCompletedCur.equipSN !== undefined
                                && taskCompletedCur.equipSN !== ''
                                && taskCompletedCur.equipSN !== null  ) {
                                equipCur.equipSN = taskCompletedCur.equipSN;
                        }
                        if (taskCompletedCur.equipHours !== undefined
                        && taskCompletedCur.equipHours !== 0
                        && taskCompletedCur.equipHours !== null  ) {
                            equipCur.equipHours = taskCompletedCur.equipHours;
                        }
                    }
                }
            }
        }
    }
    saveWksWorksCheckList(workCur: WksWorksModel, workEquipments: WorkEquipments,  wksCentralService: WksCentralService): any {
        return new Promise((resolve, reject) => {
            wksCentralService.saveWksWorks(workCur, 'checklist').subscribe(result => {
              const reponseCur = result.body;
              workCur = reponseCur;
              if (workEquipments.subEquipments) {
                for (const equipCur of workEquipments.subEquipments) {
                  if ((equipCur.equipSN !== undefined && equipCur.equipSN !== '' && equipCur.equipSN !== null  ) 
                    || (equipCur.equipHours !== undefined && equipCur.equipHours !== 0  && equipCur.equipHours !== null )) {
                      wksCentralService.saveWksEquipment(equipCur, 'update');
                    }
                }
              } else {
                if ((workEquipments.mainEquipment.equipSN !== undefined && workEquipments.mainEquipment.equipSN !== '' && workEquipments.mainEquipment.equipSN !== null  ) 
                  || (workEquipments.mainEquipment.equipHours !== undefined && workEquipments.mainEquipment.equipHours !== 0 && workEquipments.mainEquipment.equipHours !== null )) {
                    wksCentralService.saveWksEquipment(workEquipments.mainEquipment, 'update');
                }
                
              }

              resolve(reponseCur);
            },
            err => {
              console.log(err);

              reject(err.status);
            });
          });
    }
}
