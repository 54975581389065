// TODO : pour le tri des valeurs engagées https://www.ag-grid.com/angular-data-grid/row-sorting/


import { Component, OnInit, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatDialog, MatDialogConfig } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '../../../../../../services/translate.service';
import { UserService } from '../../../../../../services/user.service';

import { FormBuilder } from '@angular/forms';
import { CommonMethods } from '../../../../../../job/common/tools/commonMethods';
import { GridTools } from '../../../../../../job/common/tools/gridTools';
import { ModalCommonComponent } from '../../../../../../job/common/components/modal-common/modal-common.component';
import { GlobalEventsManagerService } from '../../../../../../job/common/services/global-events-manager.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { WksBusinessModel, wksbusiness_sch } from '../../../../models/wks-business.model';

import { IhmOptions } from '../../../../models/wks-param.model';
import { RessourcesService } from '../../../../../../job/common/services/ressources.service';
import { AccountingRendererComponent } from './renderers/accounting-renderer/accounting-renderer.component';
import { WorkInputEvent } from '../../../../models/wks-works.model';

@Component({
  selector: 'mdi-bns-table',
  templateUrl: './bns-table.component.html',
  styleUrls: ['./bns-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BnsTableComponent implements OnInit, OnDestroy {

  sidenavCollapsed = true;
  @ViewChild('sidenav', {static: false}) m_sidenav: any;
  dataSource = new MatTableDataSource();
  private readonly onDestroy = new Subject<void>();

  displayedColumns: any;
  dataColumnsName: any;
  dataColumnsDef = wksbusiness_sch;
  dataColumns: any;
  timeFormat: string;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  loadingIndicator = true;
  cacheDatatemp = [];

  isRegisterBsn: boolean;
  doCreateBsn: boolean;
  doUpdateBsn = [];
  wksBnsToUpdate: WksBusinessModel;
  statutRegister: string;
  userLangCur: string;
  reorderable = true;
  selected = [];
  pageSize = 12;
  editing = {};
  rows = [];

  gridOptions: any;
  gridApi: any;
  gridColumnApi: any;
  defaultColDef: any;
  autoGroupColumnDef: any;
  rowGroupPanelShow: any;
  optionItem: string;
  localeText: any;
  hasBackdrop: boolean;
  ihmOptions: IhmOptions;
  
  numberFormat: {
    locale: string;
    minDecimal: string;
    maxDecimal: string;
    currency: string;
    justifCurrency: string;
  };

  frameworkComponents: any;
  iBsnRow: number;

  constructor(private _userService: UserService,
    private _currentRoute: ActivatedRoute,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private _ressources: RessourcesService,
    private _dialog: MatDialog,
    private fb: FormBuilder) {
      this._translate.onLangChanged.subscribe(() => {
        this.translateLabels();
      }); 
    }

  ngOnInit() {
    if (!this.initParams()) {
      return;
    }
    this.initGrid();
    this.initSubscribes();
   
    this.loadingSubject.next(true);
    this.getWksBusinessList();
  }
  initSubscribes()  {
    this._wksCentralService.onRegisterValid
    .pipe(takeUntil(this.onDestroy))
    .subscribe((event: any) => {
      if (event === 'cancelRegister') {
        this.toggleSidenav('');

      }
      if (event === 'closeRegister') {
        this.toggleSidenav('');
        this.getWksBusinessList();
      }
      if (event === 'deleteBsn') {
        this.getWksBusinessList();
      }
    });
  }
  initGrid()  {
    this.frameworkComponents = { 
      accountingRenderer: AccountingRendererComponent
    };
    this.displayedColumns = [
      { field: 'bsnCountry', headerName: this._translate.instant('bsnCountry'), sortable: true, filter: true, resizable: true, enableRowGroup: false },
      { field: 'bsnName', headerName: this._translate.instant('bsnName'), sortable: true, filter: true, resizable: true, enableRowGroup: false },
      { field: 'bsnZipcode', headerName: this._translate.instant('bsnZipcode'), sortable: true, filter: true, resizable: true },
      { field: 'bsnCity', headerName: this._translate.instant('bsnCity'), sortable: true, filter: true, resizable: true },
      { field: 'bsnState', headerName: this._translate.instant('bsnState'), sortable: true, filter: true, resizable: true, enableRowGroup: false },
      { field: 'stdCreation', headerName: this._translate.instant('stdCreation'), type: 'DateFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, resizable: true },
          /*
      { field: 'stdLastupdate', headerName: this._translate.instant('stdLastupdate'), type: 'DateFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, resizable: true },
      { field: 'stdDisable', headerName: this._translate.instant('stdDisable'), type: 'DateFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, resizable: true },
          */
      { field: 'customerOutstanding', headerName: this._translate.instant('customerOutstanding'), 
          type: (this.numberFormat.justifCurrency === 'right' ? 'rightAligned' : 'leftAligned'), cellRenderer: 'accountingRenderer',
          sortable: true, filter: 'agNumberColumnFilter', resizable: true },
      { field: 'balance', headerName: this._translate.instant('balance'), 
          type: (this.numberFormat.justifCurrency === 'right' ? 'rightAligned' : 'leftAligned'), cellRenderer: 'accountingRenderer',
          sortable: true, filter: 'agNumberColumnFilter', resizable: true },
      { field: '', headerName: this._translate.instant('Action'),
          // template: this.getActions(),
          cellRenderer: (params: any) => {
            const data = params.data;
            return this.getActions(data);
          },
      },
    ];
    this.gridOptions = {
      columnDefs: this.getColDef(),
      enableFilter: true,
      rowDeselection: true,
      enableColResize: true,
      rowSelection: 'single',
      wrapHeaderText: true,
      autoHeaderHeight: true,
      // navigateToNextCell: this.arrowNavigation, : non operationnel
      columnTypes: {
        'DateFormat': {cellRenderer: GridTools.dateRenderer_unixM}
        },
    };
    this.gridOptions.getRowStyle = function(params: any) {
      const data = params.data;
      if ((!data) || (data === 'undefined')) {
        return;
      }
      if (data.requestDefault) {
        // https://www.rapidtables.com/web/color/green-color.html
        return { background: '#9ACD32' };
      }
      /*
      if (params.node.rowIndex % 2 === 0) {
          return { background: 'red' }
      }*/
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true,
      wrapText: true,  
      autoHeight: true,
    };
    // this.rowGroupPanelShow = 'always';
    this.autoGroupColumnDef = {
      filterValueGetter: function(params: any) {
        const colGettingGrouped = params.colDef.showRowGroup;
        const valueForOtherCol = params.api.getValue(colGettingGrouped, params.node);
        return valueForOtherCol;
      }
    };
    this.localeText = this._translate.getAgGridLabels();
  }
  initParams(): boolean  {
    if (this._userService.getUserLogged() !== undefined) {
      this.userLangCur = this._userService.getUserLogged().userlang;
    } else {
      return false;
    }
    this.optionItem = 'noItem';
    this.optionItem = this._currentRoute.snapshot.data[0]['itemOption'];
    this.timeFormat = this._translate.getLocalFmt('timeFormat');

    const wksEntity = this._wksCentralService.getWksEntityCur();
    if (wksEntity === undefined) {
      return false;
    }

    const otherData = JSON.parse(wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.numberFormat = {
      locale: this._ressources.getFormat(internationnalFormat, 'numbers', 'locale'),
      minDecimal: this._ressources.getFormat(internationnalFormat, 'numbers', 'minimumFractionDigits'),
      maxDecimal: this._ressources.getFormat(internationnalFormat, 'numbers', 'maximumFractionDigits'),
      currency:  this._ressources.getFormat(internationnalFormat, 'numbers', 'currency'),
      justifCurrency:  this._ressources.getFormat(internationnalFormat, 'justifs', 'currency'),
    };

    this.ihmOptions = {
      optionName: 'bsn',
      readOnly: false,
      display: true
    };
    this.hasBackdrop = true;
    return true;
  }
  onFilterTextBoxChanged(event: any) {
    const valueFilter = document.getElementById('filter-text-box')['value'];
    this.gridOptions.api.setQuickFilter(valueFilter);
  }
  refresh() {
    this.loadingIndicator = true;
    this.getWksBusinessList();
  }
  getColDef() {
    return this.displayedColumns;
  }

  getActions(data: any) {
    let actions: string;
    if (this.optionItem === 'wks-customers') {
      actions = '<button type="button" class="btn btn-default mat-icon-button " data-action-type="edit" data-toggle="tooltip" data-placement="left" title="' +
                this._translate.instant('edit tooltip') + '" style=" margin-left: 5px;margin-right: 5px;" >' +
                '<span data-action-type="edit" class="fa fa-edit" style="font-size: 20px"></span></button>';
                if (data.nbWorks === 0 && data.nbEquipments === 0 ) {
                  actions = actions + 
                    '<button type="button" class="btn btn-default mat-icon-button" data-action-type="trash"  data-toggle="tooltip" data-placement="left" title="' +
                    this._translate.instant('trash tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                    '<span data-action-type="trash" class="fa fa-trash" style="font-size: 20px"></span></button>' ;
               }

    } else if (this.optionItem === 'wks-subcontractors')  {
      actions = '<button type="button" class="btn btn-default mat-icon-button " data-action-type="edit" data-toggle="tooltip" data-placement="left" title="' +
                this._translate.instant('edit tooltip') + '" style=" margin-left: 5px;margin-right: 5px;" >' +
                '<span data-action-type="edit" class="fa fa-edit" style="font-size: 20px"></span></button>' +
                '<button type="button" class="btn btn-default mat-icon-button" data-action-type="trash"  data-toggle="tooltip" data-placement="left" title="' +
                this._translate.instant('trash tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                '<span data-action-type="trash" class="fa fa-trash" style="font-size: 20px"></span></button>' ;
    } else if (this.optionItem === 'wks-suppliers')  {
      actions = '<button type="button" class="btn btn-default mat-icon-button " data-action-type="edit" data-toggle="tooltip" data-placement="left" title="' +
                this._translate.instant('edit tooltip') + '" style=" margin-left: 5px;margin-right: 5px;" >' +
                '<span data-action-type="edit" class="fa fa-edit" style="font-size: 20px"></span></button>' +
                '<button type="button" class="btn btn-default mat-icon-button" data-action-type="trash"  data-toggle="tooltip" data-placement="left" title="' +
                this._translate.instant('trash tooltip') + '" style="margin-left: 5px;margin-right: 5px;" >' +
                '<span data-action-type="trash" class="fa fa-trash" style="font-size: 20px"></span></button>' ;
    }
    
    

    return actions;
  }
  getWksBusinessList() {
    this.selected = [];

    if (this._userService.getUserLogged() === undefined ) { return; }
    this._wksCentralService.getWksBusinessList(this._userService.getUserLogged().entity, this.optionItem)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        this.dataSource.data = data.body;
        this.cacheDatatemp = [...data.body];
        this.extractColumnsName(this.dataSource.data);
        this.extractData(data.body);
        this.loadingSubject.next(false);
        this.loadingIndicator = false;
      },
      err => {
        console.log(err);
        this.loadingIndicator = false;
        if (err.status === 404  || err.status === 500) {
          let titleBox: string;
          let messageBox: string;
          if (this.optionItem === 'wks-subcontractors') {
            titleBox = 'searching subcontractors';
            messageBox = 'no subcontractor';
          } else if (this.optionItem === 'wks-suppliers') {
            titleBox = 'searching suppliers';
            messageBox = 'no supplier';
          } else {
            titleBox = 'searching customers';
            messageBox = 'no customer';
          }
          titleBox = this._translate.getTranslate(titleBox);
          messageBox = this._translate.getTranslate(messageBox);
          this.displayMessageBox(titleBox, messageBox, 'ERROR', 'alertWks', '', null );
        }
      }
    );
  }
  extractData(listData: WksBusinessModel[]): void {
    for (const itemCur of listData) {
      const dataAddress = JSON.parse(itemCur.bsnAddress);
      // console.log(JSON.stringify(dataAddress));
      itemCur.bsnCity = dataAddress.address.city;
      itemCur.bsnZipcode = dataAddress.address.zipcode;
      // correctif pour erreur de dates en le serveur rest et le client angular : 1 heure de moins cote client par rapport au serveur.
      if (itemCur.stringDates) {
        const stringDates = itemCur.stringDates.split(';');
        itemCur.stdCreation = stringDates[0];
        itemCur.stdLastupdate = stringDates[1];
      }
      /*
      itemCur.balance = this._translate.instantNumber(itemCur.balance, ['currency', this.numberFormat.locale, this.numberFormat.currency, 'symbol']);
      itemCur.customerOutstanding = this._translate.instantNumber(itemCur.customerOutstanding, ['currency', this.numberFormat.locale, this.numberFormat.currency, 'symbol']);
      */
    }
    listData.sort((obj1, obj2) => {
      return obj1.bsnName > obj2.bsnName ? 1 : -1;
    });
    this.dataSource.data = listData;
  }
  displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string, dataCur: any): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        console.log('Dialog output:', data);
        if (( data === 'okAction') && (_actionCur === 'removeBsn')) {
          this.deleteBsnOK(dataCur);
        }
      });

  }
  ngOnDestroy() {

  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // params.api.sizeColumnsToFit();
  }
  public onRowClicked(e: any) {
    if (e.event.target !== undefined) {
      const selectedRow = this.gridApi.getSelectedRows();
        const data = e.data;
        if ((!data) || (data === 'undefined')) { return; }
        const actionType = e.event.target.getAttribute('data-action-type');
        this.iBsnRow = e.rowIndex;
        switch (actionType) {
            case 'edit': {
                this.editBsn(data);
                break;
            }
            case 'trash': {
                this.deleteBsn(data);
                break;
            }

        }
    }
}
  onSelectionChanged($event: any) {
    const selectedRow = this.gridApi.getSelectedRows();
    // console.log('Row selected : ' + selectedRow );

  }
  extractColumnsName(_dataArray: Array<any>) {
    if (!_dataArray[0]) {
      return null;
    }
    // init update and save mark
    this.doUpdateBsn = [...Array(_dataArray.length)].map(x => false);
    this.dataColumnsName = Object.keys(_dataArray[0]);
    this.dataColumns = CommonMethods.optionsSelected(this._translate, this.dataColumnsDef,
    this.dataColumnsName, this.displayedColumns, false);
    // console.log('Columns current : ', this.dataColumns);
  }
  toggleSidenav(_arg: string) {

    this.isRegisterBsn = false;

    if (_arg === 'registerBsn') {
      this.isRegisterBsn = true;
    }
    this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      this.m_sidenav.close();
    } else {
      this.m_sidenav.open();
    }
  }
  editBsnAgGrid() {

    const selectedRow = this.gridApi.getSelectedRows();
    this.wksBnsToUpdate = selectedRow;
    if ((selectedRow.bsnAccounting === undefined) || (selectedRow.bsnAccounting === null)) {
      this.wksBnsToUpdate.bsnAccounting  = {
        bsnCashpay: false,
        bsnAccount: '',
        scaleName: '',
        bsnDiscount: 0.0 };
    } else {
      
      try {
        this.wksBnsToUpdate.bsnAccounting = JSON.parse(selectedRow.bsnAccounting);
      } catch (e) {
        this.wksBnsToUpdate.bsnAccounting = selectedRow.bsnAccounting;
      }
    }

    this.statutRegister = 'updateBsn';
    this.doCreateBsn = false;
    this.toggleSidenav('registerBsn');
  }
  editBsn(row: WksBusinessModel) {
    this.wksBnsToUpdate = row;
    
    if ((row.bsnAccounting === undefined) || (row.bsnAccounting === null)) {
      this.wksBnsToUpdate.bsnAccounting  = {
        bsnCashpay: false,
        bsnAccount: '',
        scaleName: '',
        bsnDiscount: 0.0 };
    } else {
      try {
        this.wksBnsToUpdate.bsnAccounting = row.bsnAccounting;
      } catch (e) {
        this.wksBnsToUpdate.bsnAccounting = row.bsnAccounting;
      }

    }
    this.statutRegister = 'updateBsn';
    this.doCreateBsn = false;
    this.toggleSidenav('registerBsn');
  }
  dataOutBsnCardSheets($event: WorkInputEvent) {
    const wksBnsToUpdate = $event.customer;
    this.iBsnRow = $event.iRow;
    if ($event.iRow === -1) { // New row
      this.dataSource.data.push(wksBnsToUpdate);
      const listData: WksBusinessModel[] = this.dataSource.data;
      listData.sort((obj1, obj2) => {
        return obj1.bsnName > obj2.bsnName ? 1 : -1;
      });
      this.dataSource.data = listData;
    } else {
      const rowNode = this.gridApi.getRowNode($event.iRow);
      rowNode.data = wksBnsToUpdate;
      rowNode.setDataValue('bsnCountry', wksBnsToUpdate.bsnCountry);
      rowNode.setDataValue('bsnName', wksBnsToUpdate.bsnName);
      rowNode.setDataValue('bsnZipcode', wksBnsToUpdate.bsnZipcode);
      rowNode.setDataValue('bsnCity', wksBnsToUpdate.bsnCity);
      rowNode.setDataValue('bsnState', wksBnsToUpdate.bsnState);
      rowNode.setDataValue('stdCreation', wksBnsToUpdate.stdCreation);
      rowNode.setDataValue('customerOutstanding', wksBnsToUpdate.customerOutstanding);
      rowNode.setDataValue('balance', wksBnsToUpdate.balance);

      this.dataSource.data[this.iBsnRow] = wksBnsToUpdate;
      const listData: WksBusinessModel[] = this.dataSource.data;
      listData.sort((obj1, obj2) => {
        return obj1.bsnName > obj2.bsnName ? 1 : -1;
      });
      this.dataSource.data = listData;
    }
  }
  addBsn() {
    this.wksBnsToUpdate = {
      stdEntity: this._userService.getUserLogged().entity,
      bsnNature: this.optionItem,
    };
    this.iBsnRow = -1;
    this.statutRegister = 'createBsn';
    this.doCreateBsn = true;
    this.toggleSidenav('registerBsn');
  }
  translateLabels() {
    if (this.displayedColumns === undefined) {
      return;
    }
    const nbCol = this.displayedColumns.length;
    const localColumns = this.displayedColumns;
    this.displayedColumns = [];
    for (let i = 0; i < nbCol; i++) {
      const lTmp = localColumns[i];

      this.displayedColumns.push({
        'prop': lTmp.prop,
        'name': this._translate.getTranslate(lTmp.prop),
        'isDate': false
      });
    }
  }
  deleteBsn(row: WksBusinessModel) {
    // console.log('deleteEntity Row clicked: ', row);
    let titleBox: string;
    let messageBox: string;
    if (this.optionItem === 'wks-subcontractors') {
      titleBox = 'deleting subcontractors';
    } else if (this.optionItem === 'wks-suppliers') {
      titleBox = 'deleting suppliers';
    } else {
      titleBox = 'deleting customers';
    }
    titleBox = this._translate.getTranslate(titleBox);
    messageBox = row.bsnName;

    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'removeBsn', row );

  }
  deleteBsnOK(row: WksBusinessModel) {

      this._wksCentralService.saveWksBusiness(row, 'deleteBsn')
      .subscribe(
        () => {
            this._wksCentralService.onRegisterValid.emit('deleteBsn');
            if (this.iBsnRow > -1) {
              this.dataSource.data.splice(this.iBsnRow, 1);
              const listData: WksBusinessModel[] = this.dataSource.data;
              listData.sort((obj1, obj2) => {
                return obj1.bsnName > obj2.bsnName ? 1 : -1;
              });
              this.dataSource.data = listData;
            }
        },
        () => {

        }
      );
    
  }

}
