import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Output, EventEmitter, Input, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { CoordinatesService, TransformationType, Direction} from 'angular-coordinates';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { environment } from '../../../../../../../environments/environment';
import { GlobalEventsManagerService } from '../../../../../common/services/global-events-manager.service';

import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';

import { WksCentralService } from '../../../../services/wks-central.service';
import { WksWorksService } from '../../../../services/wks-works.service';
import { RessourcesService } from '../../../../../common/services/ressources.service';

import { PaiementsTerm, AccountModel, WksBusinessModel } from '../../../../models/wks-business.model';
import { WksEquipmentModel, WksAnchoragesModel } from '../../../../models/wks-equipment.model';
import { WksWorksInterventionEvent, WksWorksInterventionModel, 
          WorkQuotation, Payment, WksWorksModel, WorkEquipments, DataLocationWorkModel } from '../../../../models/wks-works.model';
import { WorkStatutModel } from '../../../../models/wks-param.model';

import { IhmOptions } from '../../../../models/wks-param.model';
import { GoogleMaps, LabelValue, LatLng } from '../../../../../../models/data.model';

import { CommonMethods } from '../../../../../common/tools/commonMethods';

@Component({
  selector: 'mdi-works-intervention',
  templateUrl: './works-intervention.component.html',
  styleUrls: ['./works-intervention.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    // A voir : https://stackblitz.com/edit/mat-datepicker-for-period?file=src%2Fapp%2Fviews%2Fadd-period%2Fadd-period.component.ts

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class WorksInterventionComponent implements OnInit {

  @Input() statutCall: any;
  @Input() actionType: string;
  @Input() wksWorks: WksWorksModel;
  @Input() accountCur: AccountModel;
  @Input() ihmOptions: IhmOptions;
  @Input() planningOptions: IhmOptions;
  @Input() dataLocationEquip: DataLocationWorkModel[];
  @Output() dataOut = new EventEmitter<any>();
  private readonly onDestroy = new Subject<void>();

  @ViewChild('listConditions', {static: false}) tableConditions: ElementRef;
  @ViewChild('modalMap', {read: TemplateRef, static: false}) modalMap: TemplateRef<any>;


  ownerCur: WksBusinessModel;
  equipBoatCur: WksEquipmentModel;

  workForm: FormGroup;
  isReadOnly: boolean;
  isReadOnlyPlanning: boolean;
  disabledMooring: boolean;


  anchoragesList: LabelValue[];
  anchoragesCacheList: LabelValue[];
  anchorageFiltredList: LabelValue[];
  anchoragesIsLoaded: boolean;
  entityCur: string; 

  googleMaps: GoogleMaps;
  isGeoLocation: boolean;

  wksWorksInterventionCache: WksWorksInterventionModel;
  dataLocationWork: DataLocationWorkModel;

  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDay();
  minStartDate: any;
  maxStartDate: any;
  minEndDate: any;
  maxEndDate: any;
  startDateValue: any;
  timeFormat: string;

  paiementsTerm: PaiementsTerm;
  delayList: LabelValue[];
  conditionsList: LabelValue[];
  scalesList: string[];
  isCustomerFinancials: boolean;
  isCustomerConditions: boolean;
  wksOthersWorksLoaded: boolean;
  statutChange: boolean;
  isGeoLocActived: boolean;
  workQuotation: WorkQuotation;
  shedulerPayments: Payment[];
  paymentPercentOK = false;
  totPercent = 0;


  criticalityList: LabelValue[];
  workStatuts: WorkStatutModel [];
  workStatutsList: LabelValue[];
  localWorkstatut: WorkStatutModel;
  workObject: string;

  modalRef: BsModalRef;
  wksOthersWorks: LabelValue[];

  constructor(private fb: FormBuilder,
      private _userService: UserService, 
      private _ressources: RessourcesService,
      private _translate: TranslateService,
      private _wksCentralService: WksCentralService,
      private _wksWorksService: WksWorksService,
      private _globalEventsManagerService: GlobalEventsManagerService,
      private modalService: BsModalService,
      private adapterDate: DateAdapter<any>,
      private _coordinatesService: CoordinatesService) { }

  ngOnInit() {
    this.initDp();
    this.initAllBooleans();
    this.initGlobalsParameters();
    this.loadListsParameters();
    this.buildWorkForm();
    this.fillForm();
    this.initAllSubscribes(); 
    this.ownerCur = this._wksWorksService.getOwner();
    this.equipBoatCur = this._wksWorksService.getMainEquipment();
    this.wksOthersWorks = this._wksWorksService.getOthersWorks();
    if (this.wksOthersWorks !== undefined && this.wksOthersWorks.length > 0 ) {
      this.wksOthersWorksLoaded = true;
    }
  }
  initAllBooleans() {

    this.disabledMooring = true;
    this.isReadOnly = false;
    this.isGeoLocActived = false;
    this.statutChange = false;

    this.wksOthersWorksLoaded = false;
    this.isCustomerFinancials = false;
    this.isCustomerConditions = false;
    this.paymentPercentOK = true;
    this.anchoragesIsLoaded = false;

  }
  initGlobalsParameters() {

    this.adapterDate.setLocale(this._translate.currentLang);
    // this.dateFormat = this._translate.getLocalFmt('dateFormat');
    this.timeFormat = this._translate.getLocalFmt('timeFormat');
    this.paiementsTerm = this._wksCentralService.getPaiementsTerm();
    const delaysItem = this.paiementsTerm.delaysDays;
    this.delayList =  [];
    for (const item of delaysItem) {
      const label = item.toString() + ' (' + this._translate.instant('days') + ')';
      const opt = {
        value: item,
        label: label,
      };
      this.delayList.push(opt);
    }
    /* this.delayList.splice(0, 0, {
      value: 0,
      label: '',
    }); */
    const conditionsItems = this.paiementsTerm.conditionsWorks;
    this.conditionsList =  [];
    for (const item of conditionsItems) {
      const label = this._translate.instant(item);
      const opt = {
        value: item,
        label: label,
      };
      this.conditionsList.push(opt);
    }
    this.conditionsList.sort((obj1, obj2) => {
      return obj1.label > obj2.label ? 1 : -1;
    });
    this.conditionsList.splice(0, 0, {
      value: '',
      label: '',
    });

    const wksEntity = this._wksCentralService.getWksEntityCur();
    this.entityCur = wksEntity.stdEntity;
    const scalesTmp = wksEntity.entityScales.split(',');
    this.scalesList = [];
    for (const scaleCur of scalesTmp) {
      this.scalesList.push(scaleCur);
    }

    const listTmp = this._wksCentralService.getCriticality();
    this.criticalityList = [];
    for (const stateCur of listTmp) {
      const curLabel: LabelValue = {
        label: this._translate.getTranslate(stateCur),
        value: stateCur
      };
      this.criticalityList.push(curLabel);
    }
    const workListTmp = this._wksCentralService.getWorkStatuts();
    this.workStatutsList = [];
    for (const statutCur of workListTmp) {
      if (statutCur.label === 'undefined') {
        continue;
      }
      const curLabel: LabelValue = {
        label: this._translate.getTranslate(statutCur.label),
        value: statutCur.label,
        otherData: statutCur.number
      };
      this.workStatutsList.push(curLabel);
    }

  }
  initAllSubscribes () {
    
    this._globalEventsManagerService.synchroEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {
        let eventString: string;
        const typeEvent = typeof event;
        if (typeEvent === 'string')  {
          eventString = String(event);
          if (eventString !== undefined) {
            if (eventString.startsWith('closeMapModalLatLng__')) {
              this.closeModal();
              const latLng = JSON.parse(eventString.split('__')[1]) as LatLng;
    
              this.displayLatLng(latLng);
              // this.loadDocs();
              // console.log(typeEvent);
            } 
          }
        }
        // console.log(typeEvent);
    });
    this.workForm.valueChanges.subscribe(x => {
      // console.log('form value changed');
      // console.log(x);
      // if (this.paymentPercentOK) {
        if (JSON.stringify(this.wksWorksInterventionCache) !== JSON.stringify(this.fillModel())) {
          this.onFormValid(this.workForm.status);
          
        }
      // }
    });
  }
  loadListsParameters()  {
    this.initAnchorages();

  }
  initDp() {

    this.minStartDate = moment({year: this.year, month: this.month, day: this.day});
    this.maxStartDate = moment({year: this.year + 2, month: this.month, day: this.day});
    this.minEndDate = moment({year: this.year, month: this.month, day: this.day});
    this.maxEndDate = moment({year: this.year + 2, month: this.month, day: this.day});

  }
  translateLabel(_key: string) {
    return this._translate.getTranslate(_key);
  }
  
  initAnchorages() {

    this.anchoragesCacheList =  this._wksCentralService.getWksAnchorages();
    this.anchoragesList =  this._wksCentralService.getWksAnchorages();
    this.anchorageFiltredList =  this._wksCentralService.getWksAnchorages();
   
  }
 
  onFormValid(validity: string) {
    switch (validity) {
      case 'VALID':
        this.wksWorksInterventionCache = JSON.parse(JSON.stringify(this.fillModel()));
        const wksWorksInterventionEvent: WksWorksInterventionEvent =  {
          eventAction: 'okToValidWork',
          wksWorksIntervention: this.fillModel()
        };
        this.dataOut.emit(wksWorksInterventionEvent);
        break;
      case 'INVALID':
        break;
    }
  }
  onKeyChangeObject(_value: string) {
    this.workObject = _value;
  }
  onChangeCriticalityState(_value: any) {
    // console.log('onChangeCriticalityState : ' + _value);
  }
  onChangeworkPrevious(_value: any) {
    // console.log('onChangeworkPrevious : ' + _value);

  }
  onChangeWorkStatut(_value: any)  {
    // console.log('onChangeWorkStatut : ' + _value);
    
    this.localWorkstatut = this._wksCentralService.getWorkStatut(_value);
    this.localWorkstatut.date = new Date();
    this.statutChange = true;
  }
  dataOutLocation(dataReturn: DataLocationWorkModel) {
    this.dataLocationWork = dataReturn;
    if (this.paymentPercentOK) {
      if (JSON.stringify(this.wksWorksInterventionCache) !== JSON.stringify(this.fillModel())) {
        this.onFormValid(this.workForm.status);
        
      }
    }
  }
  onChangeField(_field: string, value: any) {
    switch (_field) {
      case 'customerFinancials': {
        this.isCustomerFinancials = value;
        break;
      }
      case 'customerConditions': {
        this.isCustomerConditions = value;
        this.paymentPercentOK = true;
        this.workForm.controls.numberPayments.setValue(1);
        this.shedulerPayments = [];
        if (!this.isCustomerConditions)  {
          this.shedulerPayments.push( {orderNumber: 0, term: '', delay: 0, percent: 0, balance: false, observations: ''});
          this.paymentPercentOK = false;
        } 
        break;
      }
      case 'numberPayments': {
        if (value < 1)  {
          this.workForm.controls.numberPayments.setValue(1);
        }
        if (value > 12)  {
          this.workForm.controls.numberPayments.setValue(12);
        }
        break;
      }
      default: {
        break;
      }
    }


  }
  getPercentValue(_iRow: number): number {
    return this.shedulerPayments[_iRow].percent;
  }
  onChangeConditions(_field: string, _value: any, _iRow: number) {

    switch (_field) {
      case 'delayScheduler': {
        this.shedulerPayments[_iRow].delay = Number.parseInt(_value, 10);
        this.controlPercent(-1, 0, false);
        break;
      }
      // termPayment
      case 'termPayment': {
        this.shedulerPayments[_iRow].term = _value;
        this.shedulerPayments[_iRow].orderNumber = (_iRow + 1);
        if (_value === '') {
          this.shedulerPayments[_iRow].percent = 0;
        }
        this.controlPercent(-1, 0, false);
        break;
       }
       case 'percentPayment': {
        this.controlPercent(_iRow, _value, true);
        break;
      }
      default: {
        break;
      }
    }
  }
  controlPercent(_iRow: number, _value: any, _setValue: boolean) {
    let valueCur = 0;
    if (_value === '') {
      valueCur = 0;
    } else {
      valueCur = Number.parseInt(_value, 10);
    }
    if (valueCur < 0 ) {
      valueCur = 0;
    }
    if (valueCur > 100 ) {
      valueCur = 100;
    }
    // this.shedulerPayments[_iRow].percent = valueCur;
    this.paymentPercentOK = false;
    this.totPercent = 0;
    let beforeValue = 0;
    if (_iRow !== -1) {
      if (this.shedulerPayments[_iRow].percent > 0) {
        beforeValue = this.shedulerPayments[_iRow].percent;
      }
    }
    this.totPercent = valueCur - beforeValue;
    for (const item of this.shedulerPayments) {
      this.totPercent = this.totPercent + item.percent;
    }
   
    if (_setValue)  {
      if (this.totPercent > 100)  {
        valueCur = valueCur - ( this.totPercent - 100 );
        if (valueCur < 0 ) {
          valueCur = 0;
        }
        this.totPercent = 100;
      }
      this.shedulerPayments[_iRow].percent = valueCur;
     
    }
    
    if (this.totPercent !== 100)  {
      this.paymentPercentOK = false;
    } else  {
      this.paymentPercentOK = true;
    }
  }
  counter(_field: string, _increment: number, _value: any )  {
    switch (_field) {

      case 'numberPayments': {
        let valueCur = 0;
        if (_value === '') {
          valueCur = 0;
        } else {
          valueCur = Number.parseInt(_value, 10);
        } 
        valueCur = valueCur + _increment;
        if (valueCur < 1)  {
          valueCur = 1;
        }
        if (valueCur > 12)  {
          valueCur = 12;
        }
        if ((this.shedulerPayments.length === 0) &&  (_increment === 1 )) {
          this.shedulerPayments.push( {orderNumber: 0, term: '', delay: 0, percent: 0, balance: false, observations: ''});
        } else if (_increment === 1) {
          this.shedulerPayments.push( {orderNumber: 0, term: '', delay: 0, percent: 0, balance: false, observations: ''});
        }
        if ((this.shedulerPayments.length > 1) &&  (_increment === -1)) {
          this.shedulerPayments.pop();
        }
        this.workForm.controls.numberPayments.setValue(valueCur);
        
        break;
      }
      default: {
        break;
      }
    }
  }
  /*
  validDpStartDate(event: MatDatepickerInputEvent<Date>) {
    // console.log('validDpStartDate :' + event.value);
    this.startDateValue = event.target.value;
    // console.log('validDpStartDate :' + event.value + ' : ' + this.startDateValue);
    if (this.startDateValue < new Date() ) {
      this.workForm.controls.workStartDate.setValue(new Date());
      this.startDateValue = new Date();
    }
    this.workForm.controls.workEndDate.setValue(this.startDateValue);
  }
  validDpEndDate(event: MatDatepickerInputEvent<Date>) {
   // console.log('validDpEndtDate :' + event.value);
    if (this.startDateValue > event.value ) {
      this.workForm.controls.workEndDate.setValue(this.startDateValue);
    }
  }
  */
  onWorkLocationChange(_value: string) {

    switch (_value) {
      case 'locationWorkshop': {
        this.disabledMooring = true;
        this.mooringInputManage(this.disabledMooring);
        this.mooringInitData( '', '', '', '', '');
        break;
      }
      case 'locationMooring': {
        this.disabledMooring = true;
        this.mooringInputManage(this.disabledMooring);
        this.mooringExistingData(); 
        break;
      }
      case 'locationHomePort': {
        this.disabledMooring = true;
        this.mooringInputManage(this.disabledMooring);
        this.mooringInitData( '', '', '', '', '');
        break;
      }
      case 'locationOther': {
        this.disabledMooring = false;
        this.mooringInputManage(this.disabledMooring);
        this.mooringInitData( '', '', '', '', '');
        break;
      }
      case 'locationHomeOwner': {
        this.disabledMooring = true; 
        this.mooringInputManage(this.disabledMooring);
        this.mooringInitData( '', '', '', '', '');
        break;
      }
      default: {
        this.disabledMooring = true;
        this.mooringInputManage(this.disabledMooring);
        this.mooringInitData( '', '', '', '', '');
        break;
      }
    }
  }
  mooringExistingData() {
    if ((this.equipBoatCur.equipBoatAnchorage !== undefined) && (this.equipBoatCur.equipBoatAnchorage !== null)) {
      let anchoragePosition = '';
      for (const anchorageCur of this.anchoragesCacheList)  {
        if (anchorageCur.value === this.equipBoatCur.equipBoatAnchorage) {
          anchoragePosition = anchorageCur.otherData;
          this.changePosition(anchoragePosition);
          break;
        }
      }
      const boatMooring = JSON.parse(this.equipBoatCur.equipBoatAnchorage);
      this.mooringInitData((this.equipBoatCur.equipBoatAnchorage ? this.equipBoatCur.equipBoatAnchorage : ''),
                              (boatMooring.portPool ? boatMooring.portPool : ''), 
                              (boatMooring.portPontoon ? boatMooring.portPontoon : ''), 
                              (boatMooring.portRing ? boatMooring.portRing  : ''),
                              anchoragePosition);
    }
  }
  mooringInitData(_mooringPort: string, _portPool: string, _portPontoon: string, _portRing: string, _anchoragePosition: string ) {
    this.workForm.patchValue({
      'mooringPort': _mooringPort,
      'portPool': _portPool,
      'portPontoon': _portPontoon,
      'portRing': _portRing,
      'workPosition': _anchoragePosition,
    });
    this.changePosition(_anchoragePosition);
  }
  mooringInputManage(_disableMooring: boolean) {
    if (_disableMooring) {
      this.workForm.controls['mooringPort'].disable();
      this.workForm.controls['portPool'].disable();
      this.workForm.controls['portPontoon'].disable();
      this.workForm.controls['portRing'].disable();
    } else {
      this.workForm.controls['mooringPort'].enable();
      this.workForm.controls['portPool'].enable();
      this.workForm.controls['portPontoon'].enable();
      this.workForm.controls['portRing'].enable();
    }
 
  }
  onChangeAnchorage($event: any) {
  // console.log($event);
  }
  onKeyAnchorage(value: any) {
    // console.log(value);
    this.anchorageFiltredList = this.searchAnchorage(value);

  }
  searchAnchorage(value: string) { 
    const filter = value.toLowerCase();
    return this.anchoragesCacheList.filter(anchorageCur => anchorageCur.label.toLowerCase().includes(filter));
  }
  changePosition(_value: string) {
    this.isGeoLocation = false;
    if (_value === '') {
      this.workForm.controls.workPositionCoord.setValue('');
      return;
    }
    const tmp = _value.split(',');
    const wrongPosition = this._translate.getTranslate('anchoragePositionCoordError');
    if ((tmp === null) || (tmp.length < 2)) {
      this.workForm.controls.workPositionCoord.setValue(wrongPosition);
      return;
    }
    const latLng = this.getLatLng(tmp);
    if ((latLng !== undefined) && (latLng !== null)) {
      this.workForm.controls.workPositionCoord.setValue(latLng[0] + ' / ' + latLng[1]);
    } else {
      this.workForm.controls.workPositionCoord.setValue(wrongPosition);
    }
    const latCur = Number.parseFloat(tmp[0]);
    const lngCur = Number.parseFloat(tmp[1]);
    const myLatLng1 = { lat: latCur, lng: lngCur };
    // const myLatLng2 = { lat: 51.24132136413212, lng: 4.4073388696813875 };

    this.googleMaps = {
      isModal: false,
      displayLocal: true,
      // displayLatLng , getLatLng
      action: 'displayLatLng',
      style: undefined,
      position: myLatLng1,
      mapOptions:  {
        center: myLatLng1,
        zoom : 14
     },
     markers:  [],
    };
   
    // this.googleMaps.markers = [];
    this.googleMaps.markers.push({ 
        position: myLatLng1,
        title: ''}
    );
   
    this.isGeoLocation = true;
  }
  displayMap() {

    let positionCur ;
    let zoom = 1;
    let latLng: LatLng;
    if ((this.workForm.controls.workPosition !== undefined) 
      && (this.workForm.controls.workPosition.value !== null)
      && (this.workForm.controls.workPosition.value !== '')) {
      zoom = 14;
      positionCur = this.workForm.controls.workPosition.value;
      const values = positionCur.split(',');
      latLng = {
          lat: Number.parseFloat(values[0]),
          lng: Number.parseFloat(values[1])
      };
    }
    this.googleMaps = {
      isModal: true,
      style: {
        width: '1200px',
        height: '700px',
      },
      displayLocal: true,
      // displayLatLng , getLatLng
      action: 'getLatLng',
      position: latLng,
      mapOptions:  {
        center: latLng,
        zoom : 1
     },
     markers:  [],
    };
    if (positionCur !== undefined) {
      this.googleMaps.markers.push({ 
        position: latLng,
        title: ''}
      );
    }
    this.openModalMap();
  }
   /* geolocation */
  // Maps
  openModalMap() {

    const configModalMap = {
      class: 'modal-dialog-centered modalQuote handleUpdate',
      backdrop: true,
      ignoreBackdropClick: true,
      animated: true,
    };
    this.modalRef = this.modalService.show(this.modalMap, configModalMap);
  }
  displayLatLng(_coord: LatLng) {
    const wrongPosition = this._translate.getTranslate('anchoragePositionCoordError');
    const values: string[] = [];
    values[0] = _coord.lat.toString();
    values[1] = _coord.lng.toString();
    const latLng = this.getLatLng(values);
    if ((latLng !== undefined) && (latLng !== null)) {
      this.workForm.controls.workPosition.setValue(values[0] + ',' + values[1]);
      this.workForm.controls.workPositionCoord.setValue(latLng[0] + ' / ' + latLng[1]);
    } else {
      this.workForm.controls.workPositionCoord.setValue(wrongPosition);
    }
  }
  getLatLng(_values: string[]): number[] {
    const latLng = [];
    const lat = Number.parseFloat(_values[0]);
    const lng = Number.parseFloat(_values[1]);

    // console.log('Lat : ' + lat + ' Lng : ' + lng );
    latLng[0] = this._coordinatesService.transform(lat, TransformationType.ToDegrees, Direction.Latitude);
    latLng[1] = this._coordinatesService.transform(lng, TransformationType.ToDegrees, Direction.Longitude);
    return latLng;
  }
  buildWorkForm() {

    if (this.ihmOptions !== undefined) {
      this.isReadOnly = this.ihmOptions.readOnly;
    }
    this.isReadOnlyPlanning = this.isReadOnly;
    if (this.planningOptions !== undefined) {
      this.isReadOnlyPlanning = this.planningOptions.readOnly;
    }
    this.workForm = this.fb.group({
      workStatut: this.fb.control({value: '', disabled: this.isReadOnly}),
      workTicket: this.fb.control({value: '', disabled: this.isReadOnly}),
      workObject: this.fb.control({value: '', disabled: this.isReadOnly}),
      workPrevious: this.fb.control({value: '', disabled: this.isReadOnly}),
      workLocation: this.fb.control({value: '', disabled: this.isReadOnly}),
      criticalityState: this.fb.control({value: '', disabled: this.isReadOnly}),
      workPlanningId: this.fb.control({value: '', disabled: this.isReadOnlyPlanning}),
      workComment: this.fb.control({value: '', disabled: this.isReadOnly}),
      mooringPort: this.fb.control({value: '', disabled: this.disabledMooring }),
      portPool: this.fb.control({value: '', disabled: this.disabledMooring }),
      portPontoon: this.fb.control({value: '', disabled: this.disabledMooring }),
      portRing: this.fb.control({value: '', disabled: this.disabledMooring }),
      workPosition:  this.fb.control({value: '', disabled: true }),
      workPositionCoord:  this.fb.control({value: '', disabled: true }),
      delayValidity: this.fb.control({value: '', disabled: this.isReadOnly }),
      customerConditions: this.fb.control({value: '', disabled: this.isReadOnly }),
      customerFinancials: this.fb.control({value: '', disabled: this.isReadOnly }),
      bsnCoef: this.fb.control({value: '', disabled: this.isReadOnly}),
      scaleName: this.fb.control({value: '', disabled: this.isReadOnly}),
      numberPayments: this.fb.control({value: '', disabled: this.isReadOnly}, [Validators.max(12), Validators.min(1)]),
      calStart: this.fb.control({value: '', disabled: true}),
      calEnd: this.fb.control({value: '', disabled: true}),
      calActor: this.fb.control({value: '', disabled: true}),

    });
  }
  formDefaultsValues() {
    this.dataLocationWork = undefined;
    this.workQuotation = undefined;
    this.isCustomerConditions = true;
    this.workForm.controls.bsnCoef.setValue(this.accountCur.bsnCoef);
    this.workForm.controls.scaleName.setValue(this.accountCur.scaleName);
    this.paymentPercentOK = true;
    this.workForm.controls.customerConditions.setValue(true);
    this.workForm.controls.workStatut.setValue('elaboration');
    this.workForm.controls.delayValidity.setValue(this.delayList[0].value);
    this.isCustomerFinancials = true;
    this.workForm.controls.customerFinancials.setValue(true);
    this.workForm.controls.numberPayments.setValue(1);
    this.shedulerPayments = [];
    this.shedulerPayments.push( {orderNumber: 0, term: '', delay: 0, percent: 0, balance: false, observations: ''});
  }
  fillForm() {
    if (this.wksWorks.workStatut === undefined ) {
      this.formDefaultsValues();
      return;
    }
    this.workObject = this.wksWorks.workObject;
    
    this.workForm.controls.workStatut.setValue(this.wksWorks.workStatut);
    this.workForm.controls.workObject.setValue(this.wksWorks.workObject);
    this.workForm.controls.criticalityState.setValue(this.wksWorks.workCriticality);
    /*this.workForm.controls.workStartDate.setValue(this.wksWorks.workStartDate);
    this.workForm.controls.workEndDate.setValue(this.wksWorks.workEndDate);*/
    this.workForm.controls.workComment.setValue(this.wksWorks.workComment);
    this.workForm.controls.workLocation.setValue(this.wksWorks.workLocation);
    /*this.workForm.controls.mooringPort.setValue(this.wksWorks.workMooringPort);
    this.workForm.controls.portPool.setValue(this.wksWorks.workMooringPool);
    this.workForm.controls.portPontoon.setValue(this.wksWorks.workMooringPontoon);
    this.workForm.controls.portRing.setValue(this.wksWorks.workMooringRing);
    */
    this.workForm.controls.workTicket.setValue(this.wksWorks.workTicket);
    this.workForm.controls.workPosition.setValue(this.wksWorks.workPosition);
    if ((this.wksWorks.workPosition !== undefined) 
          && (this.wksWorks.workPosition !== null)) { 
        this.workForm.controls.workPosition.setValue(this.wksWorks.workPosition);
        this.changePosition(this.wksWorks.workPosition);
    }

    this.dataLocationWork =  { 
      typeLocation: (this.wksWorks.workLocation ? this.wksWorks.workLocation : undefined),
      dataLocation: (this.wksWorks.workLocationData ? JSON.parse(this.wksWorks.workLocationData) : undefined)
    };
    if (this.wksWorks.workStatut !== undefined) {
      this.localWorkstatut = this._wksCentralService.getWorkStatut(this.wksWorks.workStatut);
      this.localWorkstatut.date = this.wksWorks.workStatutDate;
    }
    this.workForm.controls.workStatut.setValue(this.wksWorks.workStatut);
    if ((this.wksWorks.wksCalendar !== null) && (this.wksWorks.wksCalendar !== undefined)) {
      // this.workForm.controls.calStart.setValue(this.wksWorks.wksCalendar.calStart);
      this.workForm.controls.calStart.setValue(CommonMethods.dateToString(moment(this.wksWorks.wksCalendar.calStart).format(environment.fmtDateTimeTZ), 'json1', this.timeFormat));
      // this.workForm.controls.calEnd.setValue(this.wksWorks.wksCalendar.calEnd);
      this.workForm.controls.calEnd.setValue(CommonMethods.dateToString(moment(this.wksWorks.wksCalendar.calEnd).format(environment.fmtDateTimeTZ), 'json1', this.timeFormat));
      this.workForm.controls.calActor.setValue(this.wksWorks.wksCalendar.calActor);
    }
    if ((this.wksWorks.workJsonQuotation !== null) && (this.wksWorks.workJsonQuotation !== undefined)) {
      this.workQuotation = JSON.parse(this.wksWorks.workJsonQuotation) as WorkQuotation;
      this.workForm.controls.delayValidity.setValue(this.workQuotation.delayValidity);
      if ((this.workQuotation.specificsFinancials !== null) && (this.workQuotation.specificsFinancials !== undefined)) {
        this.workForm.controls.bsnCoef.setValue(this.workQuotation.specificsFinancials.bsnCoef);
        this.workForm.controls.scaleName.setValue(this.workQuotation.specificsFinancials.scaleName);
        this.workForm.controls.customerFinancials.setValue(this.workQuotation.customerFinancials);
      } else {
        this.workForm.controls.customerFinancials.setValue(true);
        this.workForm.controls.bsnCoef.setValue(this.accountCur.bsnCoef);
        this.workForm.controls.scaleName.setValue(this.accountCur.scaleName);
      }
      if ((this.workQuotation.specificsConditions !== null) && (this.workQuotation.specificsConditions !== undefined)) {
        this.workForm.controls.numberPayments.setValue(this.workQuotation.specificsConditions.numberPayments);
        this.workForm.controls.customerConditions.setValue(this.workQuotation.customerConditions);
        this.shedulerPayments = this.workQuotation.specificsConditions.payments;
        this.totPercent = 0;
        this.paymentPercentOK = false;
        if (!this.workQuotation.customerConditions) {
          for (const paymentItem of this.shedulerPayments) {
            this.totPercent += paymentItem.percent;
          }
          if (this.totPercent === 100 ) {
            this.paymentPercentOK = true;
          }
        } else  {
          this.paymentPercentOK = true;
        }

      } else {
        this.workForm.controls.numberPayments.setValue(1);
        this.shedulerPayments = [];
        this.shedulerPayments.push( {orderNumber: 0, term: '', delay: 0, percent: 0, balance: false, observations: ''});
        this.paymentPercentOK = true;
        this.workForm.controls.customerConditions.setValue(true);
        this.isCustomerConditions = true;
      }

      this.isCustomerConditions = this.workQuotation.customerConditions;
      this.isCustomerFinancials = this.workQuotation.customerFinancials;

    } else {
      this.workQuotation = undefined;
      this.isCustomerConditions = true;
      this.workForm.controls.bsnCoef.setValue(this.accountCur.bsnCoef);
      this.workForm.controls.scaleName.setValue(this.accountCur.scaleName);
      this.paymentPercentOK = true;
      this.workForm.controls.customerConditions.setValue(true);
      this.isCustomerFinancials = true;
      this.workForm.controls.customerFinancials.setValue(true);
      this.workForm.controls.numberPayments.setValue(1);
      this.shedulerPayments = [];
      this.shedulerPayments.push( {orderNumber: 0, term: '', delay: 0, percent: 0, balance: false, observations: ''});
      this.workForm.controls.delayValidity.setValue('');
    }

  }
  fillModel(): WksWorksInterventionModel  {

    const wksWorkInterventionCard: WksWorksInterventionModel = {
      workIdPrevious: this.workForm.controls.workPrevious.value,
      workObject: this.workObject,
      workStatut:  this.workForm.controls.workStatut.value,
      workTicket:  this.workForm.controls.workTicket.value,
      workCriticality: this.workForm.controls.criticalityState.value,
      workPlanningId: this.workForm.controls.workPlanningId.value,
      workComment: this.workForm.controls.workComment.value,
      workLocation: this.dataLocationWork.typeLocation,
      workLocationData: JSON.stringify(this.dataLocationWork.dataLocation),
      workPosition: this.workForm.controls.workPosition.value,
      delayValidity: this.workForm.controls.delayValidity.value,
      customerConditions: this.workForm.controls.customerConditions.value,
      customerAccount: this.ownerCur.bsnAccounting,
      specificsConditions: {
          numberPayments: this.workForm.controls.numberPayments.value,
          payments: this.shedulerPayments,
      },
      customerFinancials: this.workForm.controls.customerFinancials.value,
      specificsFinancials: {
          scaleName: this.workForm.controls.scaleName.value,
          bsnCoef: this.workForm.controls.bsnCoef.value,
      },
      calendarEventBdd: (this.wksWorks !== undefined && this.wksWorks.wksCalendar ? this.wksWorks.wksCalendar : undefined),
    };
    return wksWorkInterventionCard;
  }
  /*
  registerWork(): WksWorksModel {
    let worksCur = JSON.parse(JSON.stringify(this.wksWorks));
    if (this.statutChange) {
      worksCur = this._wksWorksService.fillTracking(worksCur, this._userService.getUserLogged().username, 
                this.localWorkstatut.label, 'Intervention');
    }
    return worksCur;
  } 
  */
  closeModal() {
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    } 
  }
}
