<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="mat-elevation-z8 container-fluid" style="margin-top: 75px; width: 100%;">
  <div *ngIf="loadingIndicator" class="stdTheme" >
    <div class="flex-row row stdTheme " >
      <div class="col-12 d-flex justify-content-center align-items-center  " >
        <div class="spinner-container  stdTheme" fxLayout="row" fxLayoutAlign="space-around center">
          <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
        </div>
      </div>
    </div>
    <div class="flex-row row stdTheme" >
      <div class="col-12 d-flex justify-content-center align-items-center  " >
        <span *ngIf="optionItem === 'wks-customers'" style="font-size:large">{{ 'loading Customers' | translate }}</span>
        <span *ngIf="optionItem === 'wks-subcontractors'" style="font-size:large">{{ 'loading Subcontractors' | translate }}</span>
        <span *ngIf="optionItem === 'wks-suppliers'" style="font-size:large">{{ 'loading Suppliers' | translate }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="!loadingIndicator">
    <div class="flex-row row" style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;">
      <div class="col top-sidenav">
        <span class="horizontal-spacer5"></span>
        <span *ngIf="optionItem === 'wks-customers'" style="margin-left: 45%; font-size:large">{{ 'Customers' | translate }}</span>
        <span *ngIf="optionItem === 'wks-subcontractors'" style="margin-left: 45%; font-size:large">{{ 'Subcontractors' | translate }}</span>
        <span *ngIf="optionItem === 'wks-suppliers'" style="margin-left: 45%; font-size:large">{{ 'Suppliers' | translate }}</span>
      </div>
    </div>
    <div class="mat-elevation-z8 container-sidenav row w-100" style="margin-top: 125px;">
      <mat-sidenav-container fxFlexFill class="container-sidenav w-100" autosize [hasBackdrop]="hasBackdrop">
        <mat-sidenav #sidenav fxLayout="column" mode="push" opened="false" position="end" class="lateral-sidenav-70" disableClose>
            <div fxLayout="column" *ngIf="isRegisterBsn">
              <a (click)="toggleSidenav('')" *ngIf="doCreateBsn" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Creation' | translate}}
                <a *ngIf="optionItem === 'wks-customers'"> ({{ 'Customers' | translate }})</a>
                <a *ngIf="optionItem === 'wks-subcontractors'">({{ 'Subcontractors' | translate }})</a>
                <a *ngIf="optionItem === 'wks-suppliers'">({{ 'Suppliers' | translate }})</a>
              </a>
              <a (click)="toggleSidenav('')" *ngIf="!doCreateBsn" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Update' | translate}}
                <a *ngIf="optionItem === 'wks-customers'"> ({{ 'Customers' | translate }})</a>
                <a *ngIf="optionItem === 'wks-subcontractors'">({{ 'Subcontractors' | translate }})</a>
                <a *ngIf="optionItem === 'wks-suppliers'">({{ 'Suppliers' | translate }})</a>
              </a>
              <a style="padding-left: 5px; padding-right: 5px">
                <mdi-bns-card-sheets 
                    [statutCall]="statutRegister"  
                    [originCall]="'bsnTable'" 
                    [wksBnsToUpdate]="wksBnsToUpdate" 
                    [optionItem]="optionItem"
                    [ihmOptions]="ihmOptions"  
                    [iBsnRow]="iBsnRow" 
                    (dataOut)="dataOutBsnCardSheets($event)"
                    (onload)="ngOnInit()">
                </mdi-bns-card-sheets>
              </a>
            </div>
        </mat-sidenav>
        <mat-sidenav-content style="padding-left: 15px;">

          <div class="container-fluid" style="height: 100%" >
            <div class="flex-row row stdTheme w-100" >
              <div class="col-4 d-flex justify-content-start align-items-center  " >
                <input type="text"  id="filter-text-box" matInput
                      style="height: 50px;"
                      placeholder="{{ 'global filter' | translate }}" 
                      (keyup)="onFilterTextBoxChanged(inputSearch.value)" #inputSearch />
              </div>
              <div class="col-5 d-flex justify-content-start align-items-center  "  >
              
              </div>
              <div class="col-3 d-flex justify-content-end align-items-center">
                <button *ngIf="(optionItem === 'wks-customers') || (optionItem === 'wks-subcontractors') || (optionItem === 'wks-suppliers') " 
                        mat-icon-button class="buttonTheme" 
                      style="margin-left: 10px;margin-right: 10px;" (click)="addBsn()">
                  <span class="fa fa-plus-square" style="font-size: 20px" ></span>
                </button>
                <button mat-icon-button class="buttonTheme"  (click)="refresh()" 
                        style="margin-left: 10px;margin-right: 10px;">
                  <span class="fas fa-redo" style="font-size: 20px" ></span>
                </button>
              </div>
            </div>
            <div style="height: 90%">
              <ag-grid-angular 
                style="width: 100%; height: 100%;" 
                class="ag-theme-material"
                [gridOptions]="gridOptions"
                [groupMultiAutoColumn] = "true"
                [groupHideOpenParents]="true"
                [rowData]="dataSource.data"
                [localeText]="localeText"
                [pagination]="true"
                [paginationAutoPageSize]="true"
                [autoGroupColumnDef]="autoGroupColumnDef"
                [suppressDragLeaveHidesColumns]="true"
                [suppressMakeColumnVisibleAfterUnGroup]="true"
                [frameworkComponents]="frameworkComponents"
                (selectionChanged)="onSelectionChanged($event)"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClicked($event)"
                [defaultColDef]="defaultColDef"
                >
              </ag-grid-angular>
            </div>
  
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
  </div>
</div>


